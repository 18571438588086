import {Alert, AlertTitle, Snackbar} from "@mui/material";
import React from "react";
import {AlertColor} from "@mui/material/Alert/Alert";
import {NotificationResponse, NotificationResponseSeverityEnum} from "../generated/models/NotificationResponse";

export interface Props {
    open: boolean
    notification: NotificationResponse | null
    closedHook: () => void
}

export default function PushMessages(props: Props) {
    const currentSeverity = (notification: NotificationResponse | null): AlertColor | undefined => {
        if (!notification) return undefined
        switch (notification.severity) {
            case NotificationResponseSeverityEnum.Success:
                return "success"
            case NotificationResponseSeverityEnum.Warn:
                return "warning"
            case NotificationResponseSeverityEnum.Error:
                return "error"
            case NotificationResponseSeverityEnum.Info:
            default:
                return "info"
        }
    }

    return (<Snackbar id="push-messages" open={props.open && props.notification != null} autoHideDuration={5000}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'right',}} onClose={() => props.closedHook()}
                      transitionDuration={0}>
        <Alert id="push-message" onClose={() => props.closedHook()} variant="filled"
               elevation={1} sx={{fontWeight: 'bold'}} severity={currentSeverity(props.notification)}>
            <AlertTitle sx={{fontWeight: 'bold'}}>{props.notification?.title}</AlertTitle>
            {props.notification?.message}
        </Alert>
    </Snackbar>)
}
