import React, {useContext, useEffect, useState} from "react";
import AuthenticatedLayout from "../../../components/layout/AuthenticatedLayout";
import {FlatPaper} from "../../Misc";
import ServiceCreateDialog from "./ServiceCreateDialog";
import {
    apiKeyAdapter,
    authAdapter,
    Page,
    pageable,
    PageableRequest,
    serviceAdapter,
    tagAdapter,
    tenantSettingAdapter,
    userAdapter
} from "../../../adapters/interfaces";
import {LoginContext} from "../../provider/LoginProvider";
import {TenantContext} from "../../provider/TenantProvider";
import {BooleanParam, NumberParam, StringParam, useQueryParam} from "use-query-params";
import {CsvArrayParam} from "../../../misc/CsvArrayParam";
import {DataGrid, GridFilterModel, GridRowId, GridSortModel} from "@mui/x-data-grid";
import {ServiceResponse} from "../../../generated/models/ServiceResponse";
import {useHotkeys} from "react-hotkeys-hook";
import {Alert, AlertTitle, IconButton, Tooltip} from "@mui/material";
import {
    AddSharp,
    CompareArrowsSharp,
    ContentCopySharp,
    DeleteSharp,
    EditSharp,
    SecuritySharp,
    UpdateSharp
} from "@mui/icons-material";
import {assembleFilter, assembleSort} from "../../../misc/misc";
import {columns, emptyService} from "./ServiceModel";
import ServiceEditDialog from "./ServiceEditDialog";
import ServiceDiffDialog from "./ServiceDiffDialog";
import {navigate} from "gatsby";
import {RouteComponentProps} from "@reach/router";
import {TagResponse} from "../../../generated/models/TagResponse";
import {UserResponse} from "../../../generated/models/UserResponse";
import {ApiKeyResponse} from "../../../generated/models/ApiKeyResponse";
import {TagServiceResponse} from "../../../generated/models/TagServiceResponse";
import ServiceDeleteDialog from "./ServiceDeleteDialog";
import {TenantSettingDefinition} from "../../../adapters/TenantSettingAdapter";

export interface Props extends RouteComponentProps {
    title: string
    topic: string
    sort?: string
}

export default function (props: Props) {
    const SORT_DEFAULT: string = props.sort ? encodeURIComponent(props.sort) : encodeURIComponent("-createdAt")

    const {login} = useContext(LoginContext)
    const {tenant} = useContext(TenantContext)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [pageNumber = 0, setPageNumber] = useQueryParam("page", NumberParam)
    const [pageSize = 10, setPageSize] = useQueryParam("size", NumberParam)
    const [sort = SORT_DEFAULT, setSort] = useQueryParam("sort", new CsvArrayParam())
    const [filter, setFilter] = useQueryParam("filter", StringParam)
    const [id, setId] = useQueryParam("id", StringParam)
    const [initialSelectionSetFromId, setInitialSelectionSetFromId] = useState<boolean>(false)
    const [cloneDialogOpen, setCloneDialogOpen] = useState<boolean>(false)
    const [compareDialogOpen, setCompareDialogOpen] = useState<boolean>(false)
    const [createDialogOpen, setCreateDialogOpen] = useQueryParam("create", BooleanParam)
    const [editDialogOpen, setEditDialogOpen] = useQueryParam("edit", BooleanParam)
    const [deleteDialogOpen, setDeleteDialogOpen] = useQueryParam("delete", BooleanParam)
    const [elementsPage, setElementsPage] = useState<Page<ServiceResponse> | null>(null)
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [filterModel, setFilterModel] = useState<GridFilterModel | null>(null)
    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
    const [selectedElement, setSelectedElement] = useState<ServiceResponse | null>(null)
    const [selectedElements, setSelectedElements] = useState<ServiceResponse[]>([])
    const [selectedElementTags, setSelectedElementTags] = useState<Page<TagServiceResponse> | null>(null)
    const [tags, setTags] = useState<Page<TagResponse> | null>(null)
    const [users, setUsers] = useState<Page<UserResponse> | null>(null)
    const [apiKeys, setApiKeys] = useState<Page<ApiKeyResponse> | null>(null)
    const [currency, setCurrency] = useState<string | null>(null)

    const load = (): void => {
        if (!tenant || !login) return
        setLoading(true)
        const filterDef = filter ? `tenantId=="${tenant?.tenant?.id}";${filter}` : `tenantId=="${tenant?.tenant?.id}"`
        serviceAdapter.find(login, pageable(pageNumber, pageSize, filterDef, sort ? decodeURIComponent(sort.toString()) : null))
                .then(response => setElementsPage(response))
                .catch(error => setError(error.message))
                .finally(() => {
                    if (!initialSelectionSetFromId) {
                        console.debug("Setting selected row from id url param")
                        setSelectionModel(id ? [id] : [])
                        setInitialSelectionSetFromId(true)
                    }
                    setLoading(false)
                })
    }

    const openCreateDialog = () => {
        if (!tenant || !login) return
        tagAdapter.find(login, new PageableRequest(0, 1000, `tenantId=="${tenant?.tenant?.id}"`, decodeURIComponent("+name"))).then(response => setTags(response))
        userAdapter.find(login, new PageableRequest(0, 1000)).then(response => setUsers(response))
        apiKeyAdapter.find(login, new PageableRequest(0, 1000)).then(response => setApiKeys(response))
        setCreateDialogOpen(true)
    }

    const openCloneDialog = () => {
        if (!tenant || !login) return
        tagAdapter.find(login, new PageableRequest(0, 1000, `tenantId=="${tenant?.tenant?.id}"`, decodeURIComponent("+name"))).then(response => setTags(response))
        userAdapter.find(login, new PageableRequest(0, 1000)).then(response => setUsers(response))
        apiKeyAdapter.find(login, new PageableRequest(0, 1000)).then(response => setApiKeys(response))
        setCloneDialogOpen(true)
    }

    const openEditDialog = () => {
        if (!tenant || !login) return
        tagAdapter.find(login, new PageableRequest(0, 1000, `tenantId=="${tenant?.tenant?.id}"`, decodeURIComponent("+name"))).then(response => setTags(response))
        serviceAdapter.findTags(login, new PageableRequest(null, 1000, `serviceId==${selectedElement?.id}`)).then(response => setSelectedElementTags(response))
        setEditDialogOpen(true)
    }

    useHotkeys('Shift + c', () => {
        document.getElementById('compare')!.click()
    })
    useHotkeys('c', () => {
        document.getElementById('clone')!.click()
    })
    useHotkeys('a', () => {
        document.getElementById('add')!.click()
    })
    useHotkeys('e', () => {
        document.getElementById('edit')!.click()
    })
    useHotkeys('p', () => {
        document.getElementById('permissions')!.click()
    })
    useHotkeys('v', () => {
        document.getElementById('versions')!.click()
    })
    useHotkeys('d,del', () => {
        document.getElementById('delete')!.click()
    })
    useEffect(() => {
        if (tenant && login) {
            tenantSettingAdapter.getValue(login, {
                tenantId: tenant.tenant.id,
                name: TenantSettingDefinition[TenantSettingDefinition.CURRENCY],
            }).then(response => setCurrency(response))
        }
    }, [tenant, login])
    useEffect(() => { // load elements depending on the page params
        console.debug("Loading elements")
        load()
    }, [login, tenant, pageNumber, pageSize, filter, sort])
    useEffect(() => { // update state of selected element
        const element = selectionModel.length == 1 ? elementsPage?.elements.find(it => it.id === selectionModel[0]) || null : null
        console.debug("Setting selected element and id", element)
        setSelectedElement(element)
        const elements = elementsPage?.elements.filter(it => selectionModel.includes(it.id)) || []
        console.debug("Setting selected elements", elements)
        setSelectedElements(elements)
        setId(element?.id)
    }, [selectionModel, elementsPage])

    const buttonBar = (<>
                <Tooltip title="Add (a)" placement="bottom">
                    <span><IconButton id="add" disabled={error != null}
                                      onClick={openCreateDialog}><AddSharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Clone (c)" placement="bottom">
                    <span><IconButton id="clone" disabled={error != null || selectionModel.length != 1}
                                      onClick={openCloneDialog}><ContentCopySharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Edit (e)" placement="bottom">
                    <span><IconButton id="edit" disabled={error != null || selectionModel.length != 1}
                                      onClick={openEditDialog}><EditSharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Compare (shift + c)" placement="bottom">
                    <span><IconButton id="compare" disabled={error != null || selectionModel.length != 2}
                                      onClick={() => setCompareDialogOpen(true)}><CompareArrowsSharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Permissions (p)" placement="bottom">
                    <span><IconButton id="permissions" disabled={error != null || selectionModel.length != 1}
                                      onClick={e => {
                                          e.preventDefault()
                                          navigate(`/app/services/${selectedElement?.id}/permissions`)
                                      }}
                                      href={`${process.env.GATSBY_PATH_PREFIX}/app/services/${selectedElement?.id}/permissions`}><SecuritySharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Versions (v)" placement="bottom">
                    <span><IconButton id="versions" disabled={error != null || selectionModel.length != 1}
                                      onClick={e => {
                                          e.preventDefault()
                                          navigate(`/app/services/${selectedElement?.id}/versions`)
                                      }}
                                      href={`${process.env.GATSBY_PATH_PREFIX}/app/services/${selectedElement?.id}/versions`}><UpdateSharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Delete (d,del)" placement="bottom">
                    <span><IconButton id="delete" disabled={error != null || selectionModel.length < 1}
                                      onClick={() => setDeleteDialogOpen(true)}><DeleteSharp/></IconButton></span>
                </Tooltip>
                <ServiceCreateDialog id="create-dialog"
                                     title={`Create ${props.topic}`}
                                     open={createDialogOpen === undefined || createDialogOpen === null ? false : createDialogOpen}
                                     login={login}
                                     tenant={tenant?.tenant || null}
                                     onSubmitted={() => {
                                         setCreateDialogOpen(false)
                                         load()
                                     }}
                                     onClose={() => setCreateDialogOpen(false)}
                                     serviceAdapter={serviceAdapter}
                                     tagAdapter={tagAdapter}
                                     tenantSettingsAdapter={tenantSettingAdapter}
                                     authAdapter={authAdapter}
                                     tags={tags?.elements || []}
                                     users={users?.elements || []}
                                     apiKeys={apiKeys?.elements || []}
                                     currency={currency || ""}
                />
                <ServiceCreateDialog id="clone-dialog"
                                     title={`Clone service ${selectedElement?.name || ""}`}
                                     open={cloneDialogOpen}
                                     login={login}
                                     tenant={tenant?.tenant || null}
                                     service={selectedElement || emptyService}
                                     onSubmitted={() => {
                                         setCloneDialogOpen(false)
                                         load()
                                     }}
                                     onClose={() => setCloneDialogOpen(false)}
                                     serviceAdapter={serviceAdapter}
                                     tagAdapter={tagAdapter}
                                     tenantSettingsAdapter={tenantSettingAdapter}
                                     authAdapter={authAdapter}
                                     tags={tags?.elements || []}
                                     users={users?.elements || []}
                                     apiKeys={apiKeys?.elements || []}
                                     currency={currency || ""}
                />
                <ServiceEditDialog id="edit-dialog"
                                   title={`Edit service ${selectedElement?.name || ""}`}
                                   open={editDialogOpen === undefined || editDialogOpen === null ? false : editDialogOpen}
                                   login={login}
                                   service={selectedElement || emptyService}
                                   onSubmitted={() => {
                                       setEditDialogOpen(false)
                                       load()
                                   }}
                                   onClose={() => setEditDialogOpen(false)}
                                   serviceAdapter={serviceAdapter}
                                   tagAdapter={tagAdapter}
                                   tenantSettingsAdapter={tenantSettingAdapter}
                                   tags={tags?.elements || []}
                                   tagsOnService={selectedElementTags?.elements || []}
                                   currency={currency || ""}
                />
                <ServiceDiffDialog id="compare-dialog"
                                   title={`Service diff - ${selectedElements.length == 2 ? selectedElements[1].name : ""} : ${selectedElements.length == 2 ? selectedElements[0].name : ""}`}
                                   open={compareDialogOpen}
                                   onClose={() => setCompareDialogOpen(false)}
                                   original={selectedElements.length == 2 ? selectedElements[1] : null}
                                   modified={selectedElements.length == 2 ? selectedElements[0] : null}
                />
                <ServiceDeleteDialog id="delete-dialog"
                                     title={`Delete ${props.topic}`}
                                     open={deleteDialogOpen === undefined || deleteDialogOpen === null ? false : deleteDialogOpen}
                                     idsToDelete={selectedElements.map(e => e.id)}
                                     onClose={() => setDeleteDialogOpen(false)}
                                     onSubmitted={() => {
                                         setDeleteDialogOpen(false)
                                         load()
                                     }}
                                     login={login}
                                     adapter={serviceAdapter}
                />
            </>
    )

    return (
            <AuthenticatedLayout title={props.title}
                                 breadcrumbs={[{name: "Overview", link: "/app"}, {
                                     name: props.title,
                                     link: "/app/services",
                                 }]}
                                 topRightSection={buttonBar}>
                {error != null ? (
                        <FlatPaper>
                            <Alert severity="error">
                                <AlertTitle>Failed to load elements</AlertTitle>
                                {error}
                            </Alert>
                        </FlatPaper>
                ) : (
                        <FlatPaper id="content" style={{height: '100%', minHeight: '650px'}}>
                            <DataGrid initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        tenantId: false,
                                        version: false,
                                        alias: false
                                    }
                                }
                            }}
                                      rows={elementsPage?.elements || []}
                                      columns={columns(login, userAdapter, serviceAdapter, tagAdapter)}
                                      checkboxSelection disableRowSelectionOnClick
                                      className="elements" getRowClassName={(() => "element")}
                                      pagination paginationMode="server"
                                      paginationModel={{page: pageNumber || 0, pageSize: pageSize || 10}}
                                      onPaginationModelChange={(model => {
                                          setPageNumber(model.page)
                                          setPageSize(model.pageSize)
                                      })}
                                      pageSizeOptions={[10, 25, 50, 100]}
                                      rowCount={elementsPage?.totalElements || 0}
                                      filterMode="server" filterModel={filterModel || undefined}
                                      onFilterModelChange={(model => {
                                          setFilterModel(model)
                                          setFilter(assembleFilter(model))
                                      })}
                                      sortingMode="server" sortModel={sortModel}
                                      onSortModelChange={(model => {
                                          setSortModel(model)
                                          setSort(assembleSort(model))
                                      })}
                                      rowSelectionModel={selectionModel}
                                      onRowSelectionModelChange={model => setSelectionModel(model)}
                                      loading={isLoading}/>
                        </FlatPaper>
                )}
            </AuthenticatedLayout>
    )
}
