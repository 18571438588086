import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Tooltip
} from "@mui/material";
import {HelpOutlineSharp} from "@mui/icons-material";
import React, {ChangeEvent, useEffect, useState} from "react";

export interface Props {
    id: string
    label?: string
    name?: string
    value?: string | null
    error?: string | null
    start?: React.ReactNode
    /**
     * Overrides help message.
     */
    end?: React.ReactNode
    helpMessage?: string | null
    pattern?: string
    invalidMessage?: string
    maxLength?: number
    autoFocus?: boolean
    required?: boolean
    readonly?: boolean
    disabled?: boolean
}

export default function (props: Props) {
    const DEFAULT_LABEL: string = "Password"
    const DEFAULT_NAME: string = "password"
    const DEFAULT_INVALID_MESSAGE: string = `Password must match pattern ${props.pattern}`
    const DEFAULT_HELP_MESSAGE: string = "Select a tenant to connect a user with."

    const name: string = props.name || DEFAULT_NAME
    const label: string = props.label || DEFAULT_LABEL
    const helpMessage: string | null = props.helpMessage === undefined ? DEFAULT_HELP_MESSAGE : props.helpMessage
    const required: boolean = props.required === undefined ? true : props.required

    const [value, setValue] = useState<string | null>(props.value === undefined ? null : props.value)
    const [error, setError] = useState<string | null>(props.error === undefined ? null : props.error)

    useEffect(() => setValue(props.value === undefined ? null : props.value), [props.value])
    useEffect(() => setError(props.error === undefined ? null : props.error), [props.error])

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setValue(event.target.value)
        if (error) setError(null)
    }
    const handleValidationEvent = (): void => {
        if (!props.pattern) return
        setError(props.invalidMessage || DEFAULT_INVALID_MESSAGE)
    }

    return (<FormControl id={`${props.id}-form-${name}`} variant="outlined" size="small"
                         error={error != null} fullWidth required={required} disabled={props.disabled}>
        <InputLabel htmlFor={props.id}>{label}</InputLabel>
        <OutlinedInput id={props.id}
                       name={name}
                       label={label}
                       type="password"
                       autoFocus={props.autoFocus}
                       startAdornment={props.start ? (
                               <InputAdornment position="start">
                                   {props.start}
                               </InputAdornment>
                       ) : null}
                       endAdornment={props.end || helpMessage ? (
                               <InputAdornment position="end">
                                   {props.end ? props.end : helpMessage ? (
                                           <Tooltip title={helpMessage} placement="left">
                                               <IconButton tabIndex={-1} edge="end"><HelpOutlineSharp/></IconButton>
                                           </Tooltip>
                                   ) : null}
                               </InputAdornment>
                       ) : null}
                       inputProps={{
                           readOnly: props.readonly,
                           maxLength: props.maxLength,
                           pattern: props.pattern
                       }}
                       onChange={handleChange}
                       onInvalid={handleValidationEvent} value={value == null ? undefined : value}/>
        <Grid container>
            {props.maxLength ? (<>
                <Grid item xs={10}>
                    <FormHelperText>{error}</FormHelperText>
                </Grid>
                <Grid item xs={2}><Grid container justifyContent="flex-end">
                    <FormHelperText>{`${value == null ? 0 : value.length}/${props.maxLength}`}</FormHelperText>
                </Grid></Grid>
            </>) : (<Grid item xs={12}>
                <FormHelperText>{error || helpMessage}</FormHelperText>
            </Grid>)}
        </Grid>
    </FormControl>)
}
