import {Autocomplete, Checkbox, createFilterOptions, FilterOptionsState, TextField} from "@mui/material";
import {CheckBoxOutlineBlankSharp, CheckBoxSharp} from "@mui/icons-material";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {TagResponse} from "../../generated/models/TagResponse";
import {base64urlEncode} from "../../misc/misc";

export interface Props {
    id: string
    label?: string
    name?: string
    options: TagResponse[]
    value?: (string | TagResponse)[]
    onChange?: (tags: (string | TagResponse)[]) => void
    error?: string | null
    helpMessage?: string
    invalidMessage?: string
    multiple?: boolean
    readonly?: boolean
    disabled?: boolean
    creatable?: boolean
}

const TagInput = forwardRef<any, Props>((props, ref) => {
    const DEFAULT_LABEL: string = "Tags"
    const DEFAULT_NAME: string = "tags"
    const DEFAULT_HELP_MESSAGE: string = "Select tags to connect a service with."
    const TAG_NAME_REGEX: string = "^[a-zA-Z0-9]{0,1}[a-zA-Z0-9-_\\.]{0,61}[a-zA-Z0-9]{0,1}$"

    const name: string = props.name || DEFAULT_NAME
    const multiple: boolean = props.multiple === undefined ? true : props.multiple
    const filter = createFilterOptions<string | TagResponse>()

    const [value, setValue] = useState<(string | TagResponse)[]>(props.value === undefined ? [] : props.value)
    const [error, setError] = useState<string | null>(props.error === undefined ? null : props.error)

    useImperativeHandle(ref, () => ({
        getValue: (): TagResponse[] => value.map(t => typeof t === "string" ? {
            id: t,
            tenantId: "",
            name: `Add "${t}"`,
        } : t),
        getError: (): string | null => error,
    }))
    useEffect(() => setValue(props.value === undefined ? [] : props.value), [props.value])
    useEffect(() => setError(props.error === undefined ? null : props.error), [props.error])

    const filterOptions: undefined | ((options: (string | TagResponse)[], params: FilterOptionsState<string | TagResponse>) => any) = props.creatable ? (options, params) => {
        const filtered = filter(options, params)
        const {inputValue} = params
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === (typeof option === "string" ? option : option?.name))
        if (inputValue !== "" && !isExisting) {
            filtered.push({
                id: inputValue,
                tenantId: "",
                name: `Add "${inputValue}"`,
            })
        }
        return filtered
    } : undefined

    return (<>
        <input id={`${props.id}-form-${name}`} type="hidden" name={name}
               defaultValue={JSON.stringify(value.map(e => (typeof e === "string" ? e : e.id)))}/>
        <Autocomplete id={`${props.id}-form-${name}-input`} multiple={multiple} fullWidth disableCloseOnSelect
                      freeSolo={props.creatable}
                      options={props.options || []} size="small"
                      getOptionLabel={(option) => (typeof option === "string" ? option : option?.name) || "?"}
                      isOptionEqualToValue={(option, value) => (typeof option === "string" ? option : option.id) === (typeof value === "string" ? value : value.id)}
                      onChange={(event, values) => {
                          let tags: (string | TagResponse)[] = []
                          if (Array.isArray(values)) {
                              tags = values
                          } else if (values) {
                              tags = [values]
                          }
                          setValue(tags)
                          console.debug(`Set value of tags to`, value)
                          let tagNameError: string | null = null
                          tags.forEach(tag => {
                              const name = typeof tag === "string" ? tag : tag.name.startsWith("Add \"") ? tag.id : tag.name
                              if (!new RegExp(TAG_NAME_REGEX).test(name)) {
                                  tagNameError = ((tagNameError === null ? "" : `${tagNameError} `) + `Name '${name}' is not valid.`)
                              }
                          })
                          setError(tagNameError ? `${tagNameError} Names must match pattern ${TAG_NAME_REGEX}.` : null)
                          if (props.onChange) props.onChange(tags)
                      }}
                      value={value}
                      filterOptions={filterOptions}
                      renderOption={(renderProps, option, {selected}) => (
                              <li {...renderProps}>
                                  <Checkbox
                                          name={`${name}-${typeof option === "string" ? base64urlEncode(option) : option.id}`}
                                          checked={selected}
                                          icon={<CheckBoxOutlineBlankSharp fontSize="small"/>}
                                          checkedIcon={<CheckBoxSharp fontSize="small"/>}
                                          style={{marginRight: 8}}/>
                                  {typeof option === "string" ? option : option.name}
                              </li>
                      )}
                      renderInput={(params) => (
                              <TextField {...params}
                                         variant="outlined"
                                         error={props.error != null}
                                         label={props.label || DEFAULT_LABEL}
                                         helperText={error || props.helpMessage || DEFAULT_HELP_MESSAGE}/>
                      )}/>
    </>)
})
export default TagInput
