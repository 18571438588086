import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import TextInput from "../../form/TextInput";
import {LABEL_VALUE_REGEX} from "../service/ServiceModel";

export interface Props {
    id: string
    nameError: string | null
}

export default function (props: Props) {
    const [nameError, setNameError] = useState<string | null>(props.nameError)

    useEffect(() => setNameError(props.nameError), [props.nameError])

    return (<>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-name`} label="Name" name="name" error={nameError}
                       helpMessage="The name of the tag." maxLength={50} pattern={LABEL_VALUE_REGEX}/>
        </Grid>
    </>)
}
