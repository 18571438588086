import React, {SyntheticEvent, useState} from "react";
import FormDialog from "../../dialog/FormDialog";
import {LoginHolder} from "../../provider/LoginProvider";
import {AuthAdapter} from "../../../adapters/AuthAdapter";
import {ApiKeyPermissionResponseEntityEnum} from "../../../generated/models/ApiKeyPermissionResponse";
import ApiKeyPermissionCreateForm from "./ApiKeyPermissionCreateForm";
import {ApiKeyResponse} from "../../../generated/models/ApiKeyResponse";
import {PermissionAction} from "../../../generated/models/PermissionAction";
import {CreateApiKeyPermissionsRequest} from "../../../generated/models/CreateApiKeyPermissionsRequest";
import {ApiKeyPermissionsResponse} from "../../../generated/models/ApiKeyPermissionsResponse";
import {createApiKeyPermissionEntityEnumFromValue} from "../../../misc/enum";
import {UUID} from "../../../adapters/interfaces";
import {CreateApiKeyPermissionRequest} from "../../../generated/models/CreateApiKeyPermissionRequest";

export interface Props {
    id: string
    title: string
    open: boolean
    onClose?: () => void
    onSubmitted?: (response: ApiKeyPermissionsResponse) => void
    adapter: AuthAdapter
    login: LoginHolder | null
    apiKeys: ApiKeyResponse[]
    entity: ApiKeyPermissionResponseEntityEnum
    permissionOptions: PermissionAction[]
    resourceId: string
    tenantId: UUID | null
}

export default function (props: Props) {

    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        setApiKeyError(null)
        setPermissionError(null)
        if (props.onClose) props.onClose()
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [apiKeyError, setApiKeyError] = useState<string | null>(null)
    const [permissionError, setPermissionError] = useState<string | null>(null)

    const onSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setError(null)
        setApiKeyError(null)
        setPermissionError(null)

        if (!props.tenantId) {
            setError("Tenant id must not be empty!")
            return
        }
        const data = new FormData(event.currentTarget as HTMLFormElement)
        const apiKeyIds: string[] = (data.get("apikeys") as string).split(",").filter((it: string) => !!it)
        const permissionActions: PermissionAction[] = (data.get("permissions") as string).split(",").filter((it: string) => !!it).map(it => it as PermissionAction)
        const permissionsToCreate: CreateApiKeyPermissionRequest[] = apiKeyIds.map(apiKeyId => {
            return {
                tenantId: props.tenantId!!,
                apiKeyId,
                entity: createApiKeyPermissionEntityEnumFromValue(props.entity),
                actions: permissionActions,
                resourceId: props.resourceId,
            }
        })
        const permissions: CreateApiKeyPermissionsRequest = {elements: permissionsToCreate}
        props.adapter.createApiKeyPermissions(props.login, permissions)
                .then((response: ApiKeyPermissionsResponse) => {
                    if (props.onSubmitted) props.onSubmitted(response)
                })
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }

    return (<FormDialog id={props.id} title={props.title} submitButton="Add permissions"
                        errorTitle="Failed to add permission." error={error} open={props.open}
                        isLoading={isLoading}
                        onClose={onClose} onSubmit={onSubmit}>
        <ApiKeyPermissionCreateForm id={`${props.id}-form`}
                                    apiKeyError={apiKeyError}
                                    permissionError={permissionError}
                                    apiKeys={props.apiKeys}
                                    entity={props.entity}
                                    permissionOptions={props.permissionOptions}
        />
    </FormDialog>)
}
