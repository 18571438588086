import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Tooltip
} from "@mui/material";
import {HelpOutlineSharp} from "@mui/icons-material";
import React, {ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";

export interface Props {
    id: string
    label: string
    name: string
    value?: string | null
    font?: "inherit" | "monospace" | null
    inputType?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined // see https://html.spec.whatwg.org/multipage/interaction.html#input-modalities:-the-inputmode-attribute
    error?: string | null
    start?: React.ReactNode
    /**
     * Overrides help message.
     */
    end?: React.ReactNode
    helpMessage?: string
    pattern?: string
    invalidMessage?: string
    maxLength?: number | null
    autoFocus?: boolean
    required?: boolean
    readonly?: boolean
    disabled?: boolean
    multiline?: boolean
    onChange?: (value: string) => void
}

const TextInput = forwardRef<any, Props>((props, ref) => {
    const DEFAULT_INVALID_MESSAGE: string = `Value must match pattern ${props.pattern}`
    const DEFAULT_MAX_LENGTH: number = 100

    const maxLength: number | null = props.maxLength === undefined ? DEFAULT_MAX_LENGTH : props.maxLength
    const required: boolean = props.required === undefined ? true : props.required

    const inputRef = useRef<HTMLInputElement | null>(null)
    const [value, setValue] = useState<string | null>(props.value === undefined ? null : props.value)
    const [error, setError] = useState<string | null>(props.error === undefined ? null : props.error)

    useImperativeHandle(ref, () => ({
        getValue: (): string | null => value,
    }))
    useEffect(() => setValue(props.value === undefined ? null : props.value), [props.value])
    useEffect(() => setError(props.error === undefined ? null : props.error), [props.error])

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (props.onChange) props.onChange(event.target.value)
        setValue(event.target.value)
        if (error) setError(null)
    }
    const handleValidationEvent = (): void => {
        if (!props.pattern) return
        setError(props.invalidMessage || DEFAULT_INVALID_MESSAGE)
    }

    return (<FormControl id={`${props.id}-form-${props.name}`} variant="outlined" size="small"
                         error={error != null} fullWidth required={required} disabled={props.disabled}>
        <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
        <OutlinedInput id={props.id}
                       name={props.name}
                       label={props.label}
                       ref={inputRef}
                       type="text"
                       sx={{fontFamily: props.font ? props.font === "monospace" ? "Fira Code, monospace" : props.font : "inherit"}}
                       multiline={props.multiline}
                       autoFocus={props.autoFocus}
                       startAdornment={props.start ? (
                               <InputAdornment position="start">
                                   {props.start}
                               </InputAdornment>
                       ) : null}
                       endAdornment={props.end || props.helpMessage ? (
                               <InputAdornment position="end">
                                   {props.end ? props.end : props.helpMessage ? (
                                           <Tooltip title={props.helpMessage} placement="left">
                                               <IconButton tabIndex={-1} edge="end"><HelpOutlineSharp/></IconButton>
                                           </Tooltip>
                                   ) : null}
                               </InputAdornment>
                       ) : null}
                       inputProps={{
                           readOnly: props.readonly,
                           maxLength: props.maxLength,
                           pattern: props.pattern,
                           inputMode: props.inputType,
                       }}
                       onChange={handleChange}
                       onInvalid={handleValidationEvent}
                       value={value == null ? undefined : value}/>
        <Grid container>
            {maxLength ? (<>
                <Grid item xs={10}>
                    <FormHelperText>{error}</FormHelperText>
                </Grid>
                <Grid item xs={2}><Grid container justifyContent="flex-end">
                    <FormHelperText>{`${value == null ? 0 : value.length}/${maxLength}`}</FormHelperText>
                </Grid></Grid>
            </>) : (<Grid item xs={12}>
                <FormHelperText>{error}</FormHelperText>
            </Grid>)}
        </Grid>
    </FormControl>)
})
export default TextInput
