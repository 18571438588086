import {ZRColor} from "echarts/types/src/util/types";
import {blue, mint, pink, violet} from "../../../theme";
import {Theme} from "@mui/material";

export type ChartDataColor =
        "primary"
        | "secondary"
        | "ok"
        | "alert"
        | "error"
        | "mint"
        | "violet"
        | "pink"
        | "blue"
        | "grey100"
        | "grey200"
        | "grey300"
        | "grey400"
        | "grey500"
        | "grey600"
        | "grey700"
        | "grey800"
        | "grey900"
        | "textPrimary"
        | "textSecondary"

export const mapColorZr: (color: ChartDataColor | undefined, theme: Theme) => ZRColor | undefined = (color, theme) => {
    return mapColor(color, theme)
}

export const mapColor: (color: ChartDataColor | undefined, theme: Theme) => string | undefined = (color, theme) => {
    if (!color) return
    switch (color) {
        case "primary":
            return theme.palette.primary.main;
        case "secondary":
            return theme.palette.secondary.main;
        case "ok":
            return theme.palette.success.main;
        case "alert":
            return theme.palette.warning.main;
        case "error":
            return theme.palette.error.main;
        case "mint":
            return mint.main;
        case "violet":
            return violet.main;
        case "pink":
            return pink.main;
        case "blue":
            return blue.main;
        case "grey100":
            return theme.palette.grey[100];
        case "grey200":
            return theme.palette.grey[200];
        case "grey300":
            return theme.palette.grey[300];
        case "grey400":
            return theme.palette.grey[400];
        case "grey500":
            return theme.palette.grey[500];
        case "grey600":
            return theme.palette.grey[600];
        case "grey700":
            return theme.palette.grey[700];
        case "grey800":
            return theme.palette.grey[800];
        case "grey900":
            return theme.palette.grey[900];
        case "textPrimary":
            return theme.palette.text.primary;
        case "textSecondary":
            return theme.palette.text.secondary;
    }
}
