import React, {SyntheticEvent, useState} from "react";
import FormDialog from "../../dialog/FormDialog";
import {LoginHolder} from "../../provider/LoginProvider";
import {AuthAdapter} from "../../../adapters/AuthAdapter";
import {UserPermissionResponseEntityEnum} from "../../../generated/models/UserPermissionResponse";
import UserPermissionCreateForm from "./UserPermissionCreateForm";
import {UserResponse} from "../../../generated/models/UserResponse";
import {PermissionAction} from "../../../generated/models/PermissionAction";
import {CreateUserPermissionsRequest} from "../../../generated/models/CreateUserPermissionsRequest";
import {UserPermissionsResponse} from "../../../generated/models/UserPermissionsResponse";
import {UUID} from "../../../adapters/interfaces";
import {CreateUserPermissionRequest} from "../../../generated/models/CreateUserPermissionRequest";
import {createUserPermissionEntityEnumFromValue} from "../../../misc/enum";

export interface Props {
    id: string
    title: string
    open: boolean
    onClose?: () => void
    onSubmitted?: (response: UserPermissionsResponse) => void
    adapter: AuthAdapter
    login: LoginHolder | null
    users: UserResponse[]
    entity: UserPermissionResponseEntityEnum
    permissionOptions: PermissionAction[]
    resourceId: string
    tenantId: UUID | null
}

export default function (props: Props) {
    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        setUserError(null)
        setPermissionError(null)
        if (props.onClose) props.onClose()
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [userError, setUserError] = useState<string | null>(null)
    const [permissionError, setPermissionError] = useState<string | null>(null)

    const onSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setError(null)
        setUserError(null)
        setPermissionError(null)

        if (!props.tenantId) {
            setError("Tenant id must not be empty!")
            return
        }
        const data = new FormData(event.currentTarget as HTMLFormElement)
        const userIds: string[] = (data.get("users") as string).split(",").filter((it: string) => !!it)
        const permissionActions: PermissionAction[] = (data.get("permissions") as string).split(",").filter((it: string) => !!it).map(it => it as PermissionAction)
        const permissionsToCreate: CreateUserPermissionRequest[] = userIds.map(userId => {
            return {
                tenantId: props.tenantId!!,
                userId,
                entity: createUserPermissionEntityEnumFromValue(props.entity),
                actions: permissionActions,
                resourceId: props.resourceId,
            }
        })
        const permissions: CreateUserPermissionsRequest = {elements: permissionsToCreate}
        props.adapter.createUserPermissions(props.login, permissions)
                .then((response: UserPermissionsResponse) => {
                    if (props.onSubmitted) props.onSubmitted(response)
                })
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }

    return (<FormDialog id={props.id} title={props.title} submitButton="Add permissions"
                        errorTitle="Failed to add permission." error={error} open={props.open}
                        isLoading={isLoading}
                        onClose={onClose} onSubmit={onSubmit}>
        <UserPermissionCreateForm id={`${props.id}-form`}
                                  userError={userError}
                                  permissionError={permissionError}
                                  users={props.users}
                                  entity={props.entity}
                                  permissionOptions={props.permissionOptions}
        />
    </FormDialog>)
}
