export const styleRotate360 = {
    "@keyframes rotate": {"to": {transform: "rotate(-360deg)"}}
}
export const styleRotateAnimationFast = {
    animation: "rotate 1s linear infinite",
    ...styleRotate360,
}
export const styleRotateAnimationNormal = {
    animation: "rotate 2s linear infinite",
    ...styleRotate360,
}
export const styleRotateAnimationSlow = {
    animation: "rotate 3s linear infinite",
    ...styleRotate360,
}
