/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceSpecification,
    ServiceSpecificationFromJSON,
    ServiceSpecificationFromJSONTyped,
    ServiceSpecificationToJSON,
} from './ServiceSpecification';

/**
 * 
 * @export
 * @interface UpdateServiceRequest
 */
export interface UpdateServiceRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceRequest
     */
    tenantId: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateServiceRequest
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceRequest
     */
    alias: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceRequest
     */
    description?: string;
    /**
     * 
     * @type {ServiceSpecification}
     * @memberof UpdateServiceRequest
     */
    specification: ServiceSpecification;
    /**
     * 
     * @type {number}
     * @memberof UpdateServiceRequest
     */
    costsPerRequest?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateServiceRequest
     */
    referenceCostsPerRequest?: number;
}

export function UpdateServiceRequestFromJSON(json: any): UpdateServiceRequest {
    return UpdateServiceRequestFromJSONTyped(json, false);
}

export function UpdateServiceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateServiceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tenantId': json['tenantId'],
        'enabled': json['enabled'],
        'alias': json['alias'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'specification': ServiceSpecificationFromJSON(json['specification']),
        'costsPerRequest': !exists(json, 'costsPerRequest') ? undefined : json['costsPerRequest'],
        'referenceCostsPerRequest': !exists(json, 'referenceCostsPerRequest') ? undefined : json['referenceCostsPerRequest'],
    };
}

export function UpdateServiceRequestToJSON(value?: UpdateServiceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'enabled': value.enabled,
        'alias': value.alias,
        'name': value.name,
        'description': value.description,
        'specification': ServiceSpecificationToJSON(value.specification),
        'costsPerRequest': value.costsPerRequest,
        'referenceCostsPerRequest': value.referenceCostsPerRequest,
    };
}

