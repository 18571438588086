import {Box, BoxProps, styled} from "@mui/material";
import React from "react";
import LogoImage from "../images/logo.svg"

export interface Props {
    height?: number
    width?: number
    marginTop?: number
    marginRight?: number
    marginBottom?: number
    marginLeft?: number
}

export default function (props: Props) {
    const height = props.height || 50
    const width = props.width || height * 3
    const Logo = styled(Box)<BoxProps>(() => ({
        height: `${height}px`,
        width: `${width}px`,
        backgroundImage: `url(${LogoImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: 'contain',
    }))

    return (
            <Logo height={props.height}
                  width={props.width}
                  mt={props.marginTop}
                  mr={props.marginRight}
                  mb={props.marginBottom}
                  ml={props.marginBottom}/>
    )
}
