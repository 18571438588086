import {Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery} from "@mui/material";
import React from "react";
import {useTheme} from "@mui/material/styles";

export interface Props {
    id: string
    title: string
    children: NonNullable<React.ReactNode>
    open?: boolean | null
    onClose: () => void
    fullScreen?: boolean
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
}

export default function (props: Props) {

    const theme = useTheme()

    return (
            <Dialog id={props.id} open={props.open || false} onClose={props.onClose}
                    fullScreen={props.fullScreen === undefined ? useMediaQuery(theme.breakpoints.down("sm")) : props.fullScreen}
                    aria-labelledby={`${props.id}-title`} transitionDuration={0}>
                <DialogTitle id={`${props.id}-title`}>{props.title}</DialogTitle>
                <DialogContent>
                    {props.children}
                </DialogContent>
                <DialogActions>
                    <Button id={`${props.id}-close`} color={props.color || "primary"} variant="contained"
                            onClick={props.onClose}>Close</Button>
                </DialogActions>
            </Dialog>
    )
}
