import React, {ChangeEvent, SyntheticEvent, useState} from "react";
import FormDialog from "../../dialog/FormDialog";
import {LoginHolder} from "../../provider/LoginProvider";
import {TenantContractAdapter} from "../../../adapters/TenantContractAdapter";
import {UUID} from "../../../adapters/interfaces";
import {TenantContractDocumentResponse} from "../../../generated/models/TenantContractDocumentResponse";
import {Button, Grid, Typography} from "@mui/material";

export interface Props {
    id: string
    title: string
    tenantContractId: UUID
    open: boolean
    onClose?: () => void
    onSubmitted?: (response: TenantContractDocumentResponse) => void
    adapter: TenantContractAdapter
    login: LoginHolder | null
}

export default function (props: Props) {

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [file, setFile] = useState<File | null>(null)

    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        setFile(null)
        if (props.onClose) props.onClose()
    }

    const onSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setError(null)

        const data = new FormData(event.currentTarget as HTMLFormElement)
        const document = data.get("document") as File
        if (!document?.name) {
            setError("Please choose a file")
            setLoading(false)
            return
        }
        const name = document.name
        const mimeType = document.type

        new Promise<string>((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(document)
            reader.onload = () => {
                const fileDataAsUrl = reader.result as string;
                const fileDataAsBase64 = fileDataAsUrl.substring(fileDataAsUrl.indexOf("base64,") + 7)
                resolve(fileDataAsBase64)
            }
            reader.onerror = reject
        }).then(base64Data => {
            return props.adapter.createDocument(props.login, {
                tenantContractId: props.tenantContractId,
                name,
                mimeType,
                data: base64Data,
            })
        }).then(response => {
            if (props.onSubmitted) props.onSubmitted(response)
        }).catch(error => setError(error.message))
                .finally(() => {
                    setFile(null)
                    setLoading(false)
                })
    }

    return (<FormDialog id={props.id} title={props.title} submitButton="Upload"
                        errorTitle="Failed to upload element." error={error} open={props.open}
                        isLoading={isLoading}
                        onClose={onClose} onSubmit={onSubmit}>
        <Grid item xs={12} container justifyContent="center">
            <Button id={`${props.id}-choose-file`}
                    variant="outlined"
                    size="large"
                    color={error ? "error" : "primary"}
                    component="label">
                Choose file
                <input style={{display: "none"}}
                       type="file" hidden
                       name="document"
                       onChange={(event: ChangeEvent<HTMLInputElement>) => {
                           setError(null)
                           const files = event.target.files
                           if (files) {
                               setFile(files[0])
                           } else {
                               setFile(null)
                           }
                       }}/>
            </Button>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
            <Typography sx={{fontSize: "14px", textAlign: "center"}} color="textSecondary">
                {file?.name}<br/>{file?.size ? `${(file?.size / 1000000).toFixed(2)} MB` : null}
            </Typography>
        </Grid>
    </FormDialog>)
}
