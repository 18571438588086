import React from "react";
import {CardContent, Typography} from "@mui/material";
import {FlatCard} from "./Misc";

export interface Props {
    header?: string
    children: NonNullable<React.ReactNode>
}

export default function (props: Props) {
    return (<FlatCard variant="outlined">
        <CardContent>
            {props.header ? (<Typography sx={{fontSize: 14}} color="text.secondary"
                                         gutterBottom>{props.header}</Typography>) : null}
            {props.children}
        </CardContent>
    </FlatCard>)
}