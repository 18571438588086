import React, {createRef, SyntheticEvent, useState} from "react";
import FormDialog from "../../dialog/FormDialog";
import {CreditTransactionResponse} from "../../../generated/models/CreditTransactionResponse";
import {LoginHolder} from "../../provider/LoginProvider";
import {CreditTransactionAdapter} from "../../../adapters/CreditTransactionAdapter";
import CreditTransactionCreateForm from "./CreditTransactionCreateForm";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import {SelectOption} from "../../form/SelectInput";
import {DATE_FORMAT} from "../tenant/TenantContractCreateForm";
import moment from "moment";
import {CreateCreditTransactionRequest} from "../../../generated/models/CreateCreditTransactionRequest";
import {createCreditTransactionEnumFromValue} from "../../../misc/enum";

export interface Props {
    id: string
    title: string
    open: boolean
    onClose?: () => void
    onSubmitted?: (response: CreditTransactionResponse) => void
    adapter: CreditTransactionAdapter
    login: LoginHolder | null
    tenant: TenantResponse | null
}

export default function (props: Props) {

    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        setCreditsError(null)
        setAmountError(null)
        setTypeError(null)
        setDescriptionError(null)
        setValuedAtError(null)
        setExpiredAtError(null)
        if (props.onClose) props.onClose()
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [creditsError, setCreditsError] = useState<string | null>(null)
    const [amountError, setAmountError] = useState<string | null>(null)
    const [typeError, setTypeError] = useState<string | null>(null)
    const [descriptionError, setDescriptionError] = useState<string | null>(null)
    const [valuedAtError, setValuedAtError] = useState<string | null>(null)
    const [expiredAtError, setExpiredAtError] = useState<string | null>(null)

    const typeRef = createRef<any>()

    const onSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setError(null)
        setCreditsError(null)
        setAmountError(null)
        setTypeError(null)
        setDescriptionError(null)
        setValuedAtError(null)
        setExpiredAtError(null)

        const data = new FormData(event.currentTarget as HTMLFormElement)
        const credits = parseFloat(data.get("credits") as string)
        const amount = parseFloat(data.get("amount") as string)
        const valuedAt = moment(data.get("valuedAt") as string, DATE_FORMAT).toDate()
        const expiredAt = moment(data.get("expiredAt") as string, DATE_FORMAT).toDate()
        const type = (typeRef.current.getValue() as SelectOption[])[0]
        const description = (data.get("description") as string).trim()
        const request: CreateCreditTransactionRequest = {
            tenantId: props.tenant!.id,
            type: createCreditTransactionEnumFromValue(type.id),
            credits, amount, description,
            valuedAt, expiredAt,
        }
        props.adapter.createOne(props.login, request)
                .then((response: CreditTransactionResponse) => {
                    if (props.onSubmitted) props.onSubmitted(response)
                })
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }

    return (<FormDialog id={props.id} title={props.title} submitButton="Create"
                        errorTitle="Failed to create element." error={error} open={props.open}
                        isLoading={isLoading}
                        onClose={onClose} onSubmit={onSubmit}>
        <CreditTransactionCreateForm id={`${props.id}-form`}
                                     creditsError={creditsError}
                                     amountError={amountError}
                                     typeError={typeError}
                                     descriptionError={descriptionError}
                                     valuedAtError={valuedAtError}
                                     expiredAtError={expiredAtError}
                                     typeRef={typeRef}
        />
    </FormDialog>)
}
