import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    ListItemSecondaryAction,
    ListSubheader,
    Tooltip,
    Typography
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import React, {useContext} from "react";
import {
    CancelSharp,
    CheckCircleSharp,
    InfoSharp,
    VisibilityOffSharp,
    VisibilitySharp,
    WarningSharp
} from "@mui/icons-material";
import ListItem from "@mui/material/ListItem";
import {utc} from "moment";
import List from "@mui/material/List";
import {LoginContext} from "./provider/LoginProvider";
import {NotificationResponse, NotificationResponseSeverityEnum} from "../generated/models/NotificationResponse";
import {notificationAdapter, Page, PageableRequest} from "../adapters/interfaces";
import {NotificationGroup} from "../adapters/NotificationAdapter";

export interface NotificationSeverityProps {
    severity: NotificationResponseSeverityEnum
}

function NotificationSeverity(props: NotificationSeverityProps) {
    const theme = useTheme()

    switch (props.severity) {
        case NotificationResponseSeverityEnum.Success:
            return (<Box component="span">
                <CheckCircleSharp sx={{color: theme.palette.success.main}} fontSize="inherit"/> <Box component="span"
                                                                                                     sx={{
                                                                                                         color: theme.palette.success.dark,
                                                                                                         fontWeight: 'bold'
                                                                                                     }}>
                Success
            </Box>
            </Box>)
        case NotificationResponseSeverityEnum.Warn:
            return (<Box component="span">
                <WarningSharp sx={{color: theme.palette.warning.main}} fontSize="inherit"/> <Box component="span"
                                                                                                 sx={{
                                                                                                     color: theme.palette.warning.dark,
                                                                                                     fontWeight: 'bold'
                                                                                                 }}>
                Warning
            </Box>
            </Box>)
        case NotificationResponseSeverityEnum.Error:
            return (<Box component="span">
                <CancelSharp sx={{color: theme.palette.error.main}} fontSize="inherit"/> <Box component="span"
                                                                                              sx={{
                                                                                                  color: theme.palette.error.dark,
                                                                                                  fontWeight: 'bold'
                                                                                              }}>
                Error
            </Box>
            </Box>)
        case NotificationResponseSeverityEnum.Info:
            return (<Box component="span">
                <InfoSharp sx={{color: theme.palette.primary.main}} fontSize="inherit"/> <Box component="span"
                                                                                              sx={{
                                                                                                  color: theme.palette.primary.main,
                                                                                                  fontWeight: 'bold'
                                                                                              }}>
                Info
            </Box>
            </Box>)
        default:
            console.error(`Unknown severity ${props.severity}. Using severity Info as fallback`)
            return (<Box component="span">
                <InfoSharp sx={{color: theme.palette.primary.main}} fontSize="inherit"/> <Box component="span"
                                                                                              sx={{
                                                                                                  color: theme.palette.primary.main,
                                                                                                  fontWeight: 'bold'
                                                                                              }}>
                Info
            </Box>
            </Box>)
    }
}

export interface NotificationProps {
    notification: NotificationResponse
    markedAsSeenHook: (notification: NotificationResponse) => void
    markedAsUnseenHook: (notification: NotificationResponse) => void
}

function NotificationItem(props: NotificationProps) {
    const theme = useTheme()
    const {login} = useContext(LoginContext)

    const handleMarkAsSeen = (notificationId: string) => {
        notificationAdapter.updateOneSeen(login, notificationId)
                .then(() => notificationAdapter.get(login, notificationId))
                .then((response) => {
                    console.debug(`Marked notification ${response!.id} as seen`)
                    props.markedAsSeenHook(response!)
                })
    }
    const handleMarkAsUnseen = (notificationId: string) => {
        notificationAdapter.updateOneUnseen(login, notificationId)
                .then(() => notificationAdapter.get(login, notificationId))
                .then((response) => {
                    console.debug(`Marked notification ${response!.id} as unseen`)
                    props.markedAsUnseenHook(response!)
                })
    }

    return (
            <ListItem id={`notification-${props.notification.id}`}
                      style={{backgroundColor: props.notification.seen ? theme.palette.background.paper : theme.palette.background.default}}>
                <ListItemText primary={
                    <React.Fragment>
                        <Grid container direction="row">
                            <Grid item xs={11}>{props.notification.seen ? props.notification.title : (
                                    <b>{props.notification.title}</b>
                            )}</Grid>
                            <Grid item xs={1}>
                                <Tooltip title={props.notification.seen ? "Mark as unread" : "Mark as read"}
                                         placement="bottom">
                                    {props.notification.seen ? (
                                            <IconButton id={`notification-${props.notification.id}-mark-unseen`}
                                                        size="small"
                                                        onClick={() => handleMarkAsUnseen(props.notification.id)}><VisibilityOffSharp
                                                    fontSize="inherit"/></IconButton>) : (
                                            <IconButton id={`notification-${props.notification.id}-mark-seen`}
                                                        size="small"
                                                        onClick={() => handleMarkAsSeen(props.notification.id)}><VisibilitySharp
                                                    fontSize="inherit"/></IconButton>)}
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                } secondary={
                    <React.Fragment>
                        <NotificationSeverity severity={props.notification.severity}/> <Typography component="span"
                                                                                                   variant="body2"
                                                                                                   color="textSecondary"
                                                                                                   sx={{display: 'inline'}}>
                        <Tooltip title={utc(props.notification.createdAt).local().format()}>
                            <span> — {utc(props.notification.createdAt).fromNow()}</span>
                        </Tooltip>
                    </Typography>
                        <br/>
                        <Typography component="span" color="textSecondary">{props.notification.message}</Typography>
                    </React.Fragment>
                }/>
            </ListItem>
    )
}

export interface Props {
    notifications: NotificationGroup
    currentNotificationPage: number
    hasMore: boolean
    markedAsSeenHook: (notification: NotificationResponse) => void
    markedAsUnseenHook: (notification: NotificationResponse) => void
    loadedMoreHook: (notificationPage: Page<NotificationResponse>) => void
}

export default function NotificationGroupList(props: Props) {
    const theme = useTheme()
    const {login} = useContext(LoginContext)

    const handleLoadMore = () => {
        notificationAdapter.find(login, new PageableRequest(props.currentNotificationPage + 1))
                .then(response => {
                    props.loadedMoreHook(response)
                })
    }

    return (<List sx={{
        backgroundColor: theme.palette.background.paper,
        padding: 0
    }} subheader={<li/>}>
        <li style={{backgroundColor: 'inherit'}}>
            <ul style={{backgroundColor: 'inherit', padding: 0}}>
                {props.notifications.total == 0 ? (
                        <ListItem><ListItemText>No
                            notifications.</ListItemText></ListItem>
                ) : (<>
                            {props.notifications.today.length > 0 ? (<ListSubheader>Today</ListSubheader>) : null}
                            {props.notifications.today.map(notification => (
                                    <NotificationItem key={notification.id} notification={notification}
                                                      markedAsSeenHook={props.markedAsSeenHook}
                                                      markedAsUnseenHook={props.markedAsUnseenHook}/>))}
                            {props.notifications.yesterday.length > 0 ? (<>
                                <Divider/><ListSubheader>Yesterday</ListSubheader></>) : null}
                            {props.notifications.yesterday.map(notification => (
                                    <NotificationItem key={notification.id} notification={notification}
                                                      markedAsSeenHook={props.markedAsSeenHook}
                                                      markedAsUnseenHook={props.markedAsUnseenHook}/>))}
                            {props.notifications.thisWeek.length > 0 ? (<><Divider/><ListSubheader>This
                                week</ListSubheader></>) : null}
                            {props.notifications.thisWeek.map(notification => (
                                    <NotificationItem key={notification.id} notification={notification}
                                                      markedAsSeenHook={props.markedAsSeenHook}
                                                      markedAsUnseenHook={props.markedAsUnseenHook}/>))}
                            {props.notifications.lastWeek.length > 0 ? (<><Divider/><ListSubheader>Last
                                week</ListSubheader></>) : null}
                            {props.notifications.lastWeek.map(notification => (
                                    <NotificationItem key={notification.id} notification={notification}
                                                      markedAsSeenHook={props.markedAsSeenHook}
                                                      markedAsUnseenHook={props.markedAsUnseenHook}/>))}
                            {props.notifications.older.length > 0 ? (<>
                                <Divider/><ListSubheader>Older</ListSubheader></>) : null}
                            {props.notifications.older.map(notification => (
                                    <NotificationItem key={notification.id} notification={notification}
                                                      markedAsSeenHook={props.markedAsSeenHook}
                                                      markedAsUnseenHook={props.markedAsUnseenHook}/>))}
                        </>
                )}
                <Divider/>
                <ListItem>
                    <ListItemText primary={<Button id="notification-load-more-button" size="small"
                                                   onClick={handleLoadMore} disabled={!props.hasMore}>Load
                        more</Button>}/>
                    <ListItemSecondaryAction><Typography component="span"
                                                         color="textSecondary">{props.notifications.total} shown</Typography></ListItemSecondaryAction>
                </ListItem>
            </ul>
        </li>
    </List>)
}
