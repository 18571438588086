import {Grid} from "@mui/material";
import React from "react";
import {PermissionAction} from "../../../generated/models/PermissionAction";
import PermissionInput from "../../form/PermissionInput";
import {ApiKeyResponse} from "../../../generated/models/ApiKeyResponse";
import {ApiKeyPermissionResponseEntityEnum} from "../../../generated/models/ApiKeyPermissionResponse";
import ApiKeyInput from "../../form/ApiKeyInput";

export interface Props {
    id: string
    apiKeys: ApiKeyResponse[]
    entity: ApiKeyPermissionResponseEntityEnum
    permissionOptions: PermissionAction[]

    apiKeyError: string | null
    permissionError: string | null
}

export default function (props: Props) {
    return (<>
        <Grid item xs={12}>
            <ApiKeyInput id={`${props.id}-apikeys`}
                         label="Api keys"
                         name="apikeys"
                         options={props.apiKeys}
                         error={props.apiKeyError}/>
        </Grid>
        <Grid item xs={12}>
            <PermissionInput id={`${props.id}-permissions`}
                             label="Permissions"
                             name="permissions"
                             entity={props.entity}
                             options={props.permissionOptions}
                             error={props.permissionError}/>
        </Grid>
    </>)
}
