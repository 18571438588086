import React, {SyntheticEvent, useState} from "react";
import FormDialog from "../../dialog/FormDialog";
import {Page, PageableRequest} from "../../../adapters/interfaces";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import {UserRoleRequest} from "../../../generated/models/UserRoleRequest";
import {RoleResponse} from "../../../generated/models/RoleResponse";
import {LoginHolder} from "../../provider/LoginProvider";
import {UserRoleResponse} from "../../../generated/models/UserRoleResponse";
import UserEditForm from "./UserEditForm";
import {UserResponse} from "../../../generated/models/UserResponse";
import {UserAdapter} from "../../../adapters/UserAdapter";

export interface Props {
    id: string
    title: string
    open: boolean
    element: UserResponse | null
    elementTenants: TenantResponse[]
    elementRoles: UserRoleResponse[]
    tenants: TenantResponse[]
    tenant: TenantResponse
    roleDefs: RoleResponse[]
    onClose?: () => void
    onSubmitted?: () => void
    onTenantsChange?: (tenants: TenantResponse[]) => void
    adapter: UserAdapter
    login: LoginHolder | null
}

export default function (props: Props) {

    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        setEmailError(null)
        setFirstnameError(null)
        setLastnameError(null)
        setTenantsError(null)
        setRolesError(null)
        if (props.onClose) props.onClose()
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [emailError, setEmailError] = useState<string | null>(null)
    const [firstnameError, setFirstnameError] = useState<string | null>(null)
    const [lastnameError, setLastnameError] = useState<string | null>(null)
    const [tenantsError, setTenantsError] = useState<string | null>(null)
    const [rolesError, setRolesError] = useState<string | null>(null)

    const onSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setError(null)
        setEmailError(null)
        setFirstnameError(null)
        setLastnameError(null)
        setTenantsError(null)
        setRolesError(null)

        const data = new FormData(event.currentTarget as HTMLFormElement)
        const id = data.get("id") as string
        const email = (data.get("email") as string).trim()
        const firstname = data.get("firstname") as string
        const lastname = data.get("lastname") as string
        const tenantIds: string[] = (data.get("tenants") as string).split(",").filter((it: string) => !!it)
        if (tenantIds.length == 0) {
            setError("Editing user failed")
            setTenantsError("At least one tenant must be selected")
            setLoading(false)
            return
        }
        let roles: UserRoleRequest[] = []
        const tenantRoles = data.get("roles") as string | null
        if (!tenantRoles) return
        const tenantRoleIds: string[] = tenantRoles.split(",").filter((it: string) => !!it).map((it: string) => it)
        roles = roles.concat(tenantRoleIds.map(roleId => ({tenantId: props.tenant.id, roleId})))
        props.adapter.updateOne(props.login, {id, email, firstname, lastname})
                .then(() => {
                    const handleTenants = props.adapter.findTenants(props.login, new PageableRequest(null, 1000, `userId==${id}`))
                            .then((response) => {
                                const currentTenantIds: string[] = response.elements.map(it => it.tenantId)
                                const tenantsToAdd = tenantIds.filter(it => !currentTenantIds.includes(it))
                                const tenantsToRemove = currentTenantIds.filter(it => !tenantIds.includes(it))
                                const adding = tenantsToAdd.length == 0 ? Promise.resolve() : props.adapter.addTenants(props.login, {
                                    elements: tenantsToAdd.map(tenantId => Object.assign({tenantId, userId: id}))
                                }).catch(error => {
                                    setError('Failed to add tenant connections')
                                    setTenantsError(`Failed to add tenant connections: ${error.message}`)
                                })
                                const removing = tenantsToRemove.length == 0 ? Promise.resolve() : props.adapter.removeTenants(props.login, {
                                    elements: tenantsToRemove.map(tenantId => Object.assign({tenantId, userId: id}))
                                }).catch(error => {
                                    setError('Failed to remove tenant connections')
                                    setTenantsError(`Failed to remove tenant connections: ${error.message}`)
                                })
                                return adding.then(() => removing)
                            })
                    const handleRoles = props.adapter.findRoles(props.login, new PageableRequest(null, 1000, `userId==${id};tenantId==${props.tenant.id}`))
                            .then((response: Page<UserRoleResponse>) => {
                                const currentRoles: UserRoleResponse[] = response.elements
                                const updatedRoles: UserRoleRequest[] = roles
                                const rolesToAdd = updatedRoles.filter(u => !currentRoles.find(c => c.roleId === u.roleId))
                                const rolesToRemove = currentRoles.filter(c => !updatedRoles.find(u => u.roleId === c.roleId))
                                const adding = rolesToAdd.length == 0 ? Promise.resolve() : props.adapter.addRoles(props.login, {
                                    elements: rolesToAdd.map(r => Object.assign({
                                        tenantId: r.tenantId,
                                        userId: id,
                                        roleId: r.roleId
                                    }))
                                }).catch(error => {
                                    setError('Failed to add roles')
                                    setRolesError(`Failed to add roles: ${error.message}`)
                                })
                                const removing = rolesToRemove.length == 0 ? Promise.resolve() : props.adapter.removeRoles(props.login, {
                                    elements: rolesToRemove.map(r => Object.assign({
                                        tenantId: r.tenantId,
                                        userId: id,
                                        roleId: r.roleId
                                    }))
                                }).catch(error => {
                                    setError('Failed to remove roles')
                                    setRolesError(`Failed to remove roles: ${error.message}`)
                                })
                                return adding.then(() => removing)
                            })
                    return Promise.all([handleRoles, handleTenants]).then(() => {
                        if (error == null) {
                            if (props.onSubmitted) props.onSubmitted()
                        }
                    })
                }).catch(error => setError(error.message)).finally(() => setLoading(false))
    }

    return (<FormDialog id={props.id} title={props.title} submitButton="Edit"
                        errorTitle="Failed to edit element." error={error} open={props.open}
                        isLoading={isLoading}
                        onClose={onClose} onSubmit={onSubmit}>
        <UserEditForm id={`${props.id}-form`}
                      element={props.element}
                      elementTenants={props.elementTenants}
                      elementRoles={props.elementRoles}
                      tenants={props.tenants}
                      tenant={props.tenant}
                      roleDefs={props.roleDefs || {}}
                      onTenantsChange={tenants => {
                          if (props.onTenantsChange) props.onTenantsChange(tenants)
                      }}
                      emailError={emailError}
                      firstnameError={firstnameError}
                      lastnameError={lastnameError}
                      tenantsError={tenantsError}
                      rolesError={rolesError}
        />
    </FormDialog>)
}
