import React, {useEffect, useState} from "react";
import {Box, CardContent} from "@mui/material";
import {FlatCard} from "../Misc";
import {ChartDataColor, mapColorZr} from "./raw/Charts";
import {useTheme} from "@mui/material/styles";
import {useWindowWidth} from "@react-hook/window-size";
import * as echarts from "echarts/core";
import {EChartsType} from "echarts/core";
import {
    AriaComponent,
    AriaComponentOption,
    DatasetComponent,
    DatasetComponentOption,
    GraphicComponent,
    GraphicComponentOption,
    GridComponent,
    GridComponentOption,
    LegendComponent,
    LegendComponentOption,
    MarkLineComponent,
    MarkLineComponentOption,
    TitleComponent,
    TitleComponentOption,
    TooltipComponent,
    TooltipComponentOption,
    TransformComponent
} from "echarts/components";
import {dark} from "./raw/theme-dark";
import {light} from "./raw/theme-light";
import {LabelLayout, UniversalTransition} from "echarts/features";
import {CanvasRenderer} from "echarts/renderers";
import {ZRFontWeight} from "echarts/types/src/util/types";
import {LineChart, LineSeriesOption} from "echarts/charts";

type ECOption = echarts.ComposeOption<LineSeriesOption
        | AriaComponentOption
        | TooltipComponentOption
        | TitleComponentOption
        | LegendComponentOption
        | GridComponentOption
        | GraphicComponentOption
        | MarkLineComponentOption
        | DatasetComponentOption>

export interface Props {
    id: string
    header?: string
    subHeader?: string
    /**
     * Last value will be used to show the single value
     */
    data: number[]
    showDataLine?: boolean
    color?: ChartDataColor
    unit?: string
    width?: string
    height?: string
}

export default function (props: Props) {
    const theme = useTheme()
    const windowWidth = useWindowWidth()

    const [chart, setChart] = useState<EChartsType | null>(null)

    const option: ECOption = {
        textStyle: {fontFamily: theme.typography.fontFamily},
        animation: false,
        title: {
            show: !!props.header,
            text: props.header,
            textStyle: {
                color: theme.palette.text.secondary,
                textBorderColor: theme.palette.background.paper,
                textBorderWidth: 2,
                lineHeight: 14,
                fontSize: "14px",
                fontWeight: theme.typography.h5.fontWeight! as ZRFontWeight,
            },
            subtext: props.subHeader,
            subtextStyle: {
                textBorderColor: theme.palette.background.paper,
                textBorderWidth: 2,
            },
            padding: 0,
        },
        graphic: [
            {
                type: "text",
                z: 100,
                left: "center",
                bottom: 0,
                style: {
                    text: props.data.length > 0 ? `${props.data[props.data.length - 1]}${props.unit || ""}` : "-",
                    fill: theme.palette.text.primary,
                    stroke: theme.palette.background.paper,
                    lineWidth: 2,
                    fontSize: "48px",
                    fontFamily: theme.typography.h5.fontFamily,
                    fontWeight: theme.typography.h5.fontWeight! as ZRFontWeight,
                    overflow: "break",
                }
            },
        ],
        grid: {
            show: false,
            left: 0,
            top: 4,
            bottom: 4,
            right: 0,
            containLabel: false,
        },
        xAxis: {
            show: false,
            type: "category",
        },
        yAxis: {
            show: false,
            type: "value",
        },
        series: [
            {
                type: "line",
                lineStyle: {color: mapColorZr(props.color || "primary", theme)},
                data: props.showDataLine === undefined || props.showDataLine ? props.data.map(d => {
                    return {
                        symbol: "none",
                        value: d,
                    }
                }) : [],
            }
        ],
    }
    const initChart = (): EChartsType => {
        const chart = echarts.init(document.getElementById(props.id)!, theme.palette.mode)
        chart.setOption(option)
        return chart
    }

    echarts.use([
        TooltipComponent,
        TitleComponent,
        LegendComponent,
        GridComponent,
        DatasetComponent,
        TransformComponent,
        AriaComponent,
        GraphicComponent,
        MarkLineComponent,
        LineChart,
        LabelLayout,
        UniversalTransition,
        CanvasRenderer,
    ])
    echarts.registerTheme("light", light)
    echarts.registerTheme("dark", dark)

    useEffect(() => {
        setChart(initChart())
        return () => {
            chart?.dispose()
        }
    }, [])
    useEffect(() => {
        chart?.dispose()
        setChart(initChart())
    }, [theme.palette.mode, props.data])
    useEffect(() => {
        chart?.resize({silent: true})
    }, [windowWidth])

    return (<FlatCard variant="outlined" sx={{width: "100%", height: "100%"}}>
        <CardContent>
            <Box id={props.id} component="div" sx={{height: props.height, width: props.width}}/>
        </CardContent>
    </FlatCard>)
}