import React from "react";
import {Layout, Responsive, WidthProvider} from "react-grid-layout";
import {useTheme} from "@mui/material/styles";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./style.css"

const ResponsiveGridLayout = WidthProvider(Responsive)

export interface Props {
    id: string
    layout: Layout[]
    layoutSm?: Layout[]
    items: React.ReactNode[]
}

export default function (props: Props) {
    const theme = useTheme()

    return (<ResponsiveGridLayout
                    className="layout"
                    containerPadding={[0, 0]}
                    margin={[8, 8]}
                    layouts={{
                        xl: props.layout,
                        lg: props.layout,
                        md: props.layout,
                        sm: props.layoutSm || props.layout,
                        xs: props.layoutSm || props.layout,
                    }}
                    breakpoints={{
                        xl: theme.breakpoints.values.xl,
                        lg: theme.breakpoints.values.lg,
                        md: theme.breakpoints.values.md,
                        sm: theme.breakpoints.values.sm,
                        xs: theme.breakpoints.values.xs,
                    }}
                    cols={{xl: 12, lg: 12, md: 12, sm: 6, xs: 6}}
                    rowHeight={25}>
                {props.items.map(it => it) as any}
            </ResponsiveGridLayout>
    )
}