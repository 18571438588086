import {Box, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import React from "react";
import Info from "../../Info";

export interface Props {
    id: string
}

export default function (props: Props) {
    return (<Info id={`${props.id}-info`} title="Service config file hints" buttonText="OK">
        <Typography variant="h3">Variables</Typography>
        <Typography variant="h4">Deploy time variables</Typography>
        <Typography variant="body1">
            The following variables can be used in the
            specification and are substituted at deploy time:
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Variable</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        [
                            {name: "ID", description: "The service identifier."},
                            {name: "ALIAS", description: "The alias of the service."},
                            {name: "NAME", description: "The name of the service."},
                            {name: "REPLICAS", description: "The replicas specified for the service."},
                            {name: "DOMAIN", description: "The domain specified for the service."},
                            {name: "IMAGE_NAME", description: "The name of the image of the service."},
                            {name: "IMAGE_TAG", description: "The tag of the image of the service."},
                        ].map((e, i) => (<TableRow key={e.name}>
                            <TableCell sx={{fontFamily: "Fira Code, monospace"}}>{e.name}</TableCell>
                            <TableCell>{e.description}</TableCell>
                        </TableRow>))
                    }
                </TableBody>
            </Table>
            Variables can be used by wrapping them in <Box component="span"
                                                           fontFamily="Fira Code, monospace">{`{{MY_VARIABLE}}`}</Box>.
        </Typography>
        <Typography variant="h4">Run time variables</Typography>
        <Typography variant="body1">
            Additionally, the following variables are available during runtime and can only be used when the container
            is running:
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Variable</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        [
                            {name: "AIOS_IP", description: "The IP address of the container."},
                            {name: "AIOS_HOSTNAME", description: "The hostname of the container."},
                            {name: "AIOS_NAMESPACE", description: "The namespace of the container."},
                            {
                                name: "AIOS_NODE_NAME",
                                description: "The node name of the virtual or physical computer the container is running on."
                            },
                        ].map((e, i) => (<TableRow key={e.name}>
                            <TableCell sx={{fontFamily: "Fira Code, monospace"}}>{e.name}</TableCell>
                            <TableCell>{e.description}</TableCell>
                        </TableRow>))
                    }
                </TableBody>
            </Table>
        </Typography>
    </Info>)
}