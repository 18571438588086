import React from "react";
import {LoginHolder} from "../../provider/LoginProvider";
import {ServiceResponse} from "../../../generated/models/ServiceResponse";
import {ServiceAdapter} from "../../../adapters/ServiceAdapter";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import ServiceCreateForm from "./ServiceCreateForm";
import {Dialog, DialogContent, DialogTitle, Grid} from "@mui/material";
import {TagResponse} from "../../../generated/models/TagResponse";
import {TagAdapter} from "../../../adapters/TagAdapter";
import {AuthAdapter} from "../../../adapters/AuthAdapter";
import {UserResponse} from "../../../generated/models/UserResponse";
import {ApiKeyResponse} from "../../../generated/models/ApiKeyResponse";
import {TenantSettingAdapter} from "../../../adapters/TenantSettingAdapter";

export interface Props {
    id: string
    title: string
    open: boolean
    onClose?: () => void
    onSubmitted?: (response: ServiceResponse) => void
    serviceAdapter: ServiceAdapter
    tagAdapter: TagAdapter
    tenantSettingsAdapter: TenantSettingAdapter,
    authAdapter: AuthAdapter
    login: LoginHolder | null
    tenant: TenantResponse | null
    tags: TagResponse[]
    users: UserResponse[]
    apiKeys: ApiKeyResponse[]
    service?: ServiceResponse
    currency: string
}

export default function (props: Props) {
    const onClose: () => void = () => {
        if (props.onClose) props.onClose()
    }

    return (<Dialog id={props.id} open={props.open || false} onClose={props.onClose}
                    fullScreen aria-labelledby={`${props.id}-title`} transitionDuration={0}>
        <DialogTitle id={`${props.id}-title`}>{props.title}</DialogTitle>
        <DialogContent>
            <Grid container spacing={2} sx={{marginTop: '-8px'}}>
                <ServiceCreateForm id={`${props.id}-form-wrapper`}
                                   onClose={onClose}
                                   onSubmitted={props.onSubmitted}
                                   serviceAdapter={props.serviceAdapter}
                                   tagAdapter={props.tagAdapter}
                                   tenantSettingsAdapter={props.tenantSettingsAdapter}
                                   authAdapter={props.authAdapter}
                                   login={props.login}
                                   tenant={props.tenant}
                                   tags={props.tags}
                                   users={props.users}
                                   apiKeys={props.apiKeys}
                                   service={props.service}
                                   currency={props.currency}
                />
            </Grid>
        </DialogContent>
    </Dialog>)
}
