// Generated with https://echarts.apache.org/en/theme-builder.html
import {blue, darkTheme, mint, pink} from "../../../theme";

export const dark = {
    "color": [mint.main, blue.main, pink.main],
    "backgroundColor": darkTheme.palette.background.paper,
    "textStyle": {},
    "title": {
        "textStyle": {
            "color": darkTheme.palette.text.primary
        }, "subtextStyle": {
            "color": darkTheme.palette.text.secondary
        }
    },
    "line": {
        "itemStyle": {
            "borderWidth": "2"
        }, "lineStyle": {
            "width": "3"
        }, "symbolSize": "7", "symbol": "circle", "smooth": true
    },
    "radar": {
        "itemStyle": {
            "borderWidth": "2"
        }, "lineStyle": {
            "width": "3"
        }, "symbolSize": "7", "symbol": "circle", "smooth": true
    },
    "bar": {
        "itemStyle": {
            "barBorderWidth": 0, "barBorderColor": "#ccc"
        }
    },
    "pie": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        }
    },
    "scatter": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        }
    },
    "boxplot": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        }
    },
    "parallel": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        }
    },
    "sankey": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        }
    },
    "funnel": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        }
    },
    "gauge": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        }
    },
    "candlestick": {
        "itemStyle": {
            "color": mint.light,
            "color0": "transparent",
            "borderColor": mint.main,
            "borderColor0": blue.main,
            "borderWidth": "2"
        }
    },
    "graph": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        },
        "lineStyle": {
            "width": 1, "color": "#aaa"
        },
        "symbolSize": "7",
        "symbol": "circle",
        "smooth": true,
        "color": [mint.main, blue.main, pink.main],
        "label": {
            "color": darkTheme.palette.background.paper
        }
    },
    "map": {
        "itemStyle": {
            "areaColor": "#eee", "borderColor": "#444", "borderWidth": 0.5
        }, "label": {
            "color": "#000"
        }, "emphasis": {
            "itemStyle": {
                "areaColor": "#e098c7", "borderColor": "#444", "borderWidth": 1
            }, "label": {
                "color": "#ffffff"
            }
        }
    },
    "geo": {
        "itemStyle": {
            "areaColor": "#eee", "borderColor": "#444", "borderWidth": 0.5
        }, "label": {
            "color": "#000"
        }, "emphasis": {
            "itemStyle": {
                "areaColor": "#e098c7", "borderColor": "#444", "borderWidth": 1
            }, "label": {
                "color": "#ffffff"
            }
        }
    },
    "categoryAxis": {
        "axisLine": {
            "show": true, "lineStyle": {
                "color": "#cccccc"
            }
        }, "axisTick": {
            "show": false, "lineStyle": {
                "color": "#333"
            }
        }, "axisLabel": {
            "show": true, "color": "#cccccc"
        }, "splitLine": {
            "show": false, "lineStyle": {
                "color": ["#eeeeee", "#333333"]
            }
        }, "splitArea": {
            "show": true, "areaStyle": {
                "color": ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
            }
        }
    },
    "valueAxis": {
        "axisLine": {
            "show": true, "lineStyle": {
                "color": "#cccccc"
            }
        }, "axisTick": {
            "show": false, "lineStyle": {
                "color": "#333"
            }
        }, "axisLabel": {
            "show": true, "color": "#cccccc"
        }, "splitLine": {
            "show": false, "lineStyle": {
                "color": ["#eeeeee", "#333333"]
            }
        }, "splitArea": {
            "show": true, "areaStyle": {
                "color": ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
            }
        }
    },
    "logAxis": {
        "axisLine": {
            "show": true, "lineStyle": {
                "color": "#cccccc"
            }
        }, "axisTick": {
            "show": false, "lineStyle": {
                "color": "#333"
            }
        }, "axisLabel": {
            "show": true, "color": "#cccccc"
        }, "splitLine": {
            "show": false, "lineStyle": {
                "color": ["#eeeeee", "#333333"]
            }
        }, "splitArea": {
            "show": true, "areaStyle": {
                "color": ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
            }
        }
    },
    "timeAxis": {
        "axisLine": {
            "show": true, "lineStyle": {
                "color": "#cccccc"
            }
        }, "axisTick": {
            "show": false, "lineStyle": {
                "color": "#333"
            }
        }, "axisLabel": {
            "show": true, "color": "#cccccc"
        }, "splitLine": {
            "show": false, "lineStyle": {
                "color": ["#eeeeee", "#333333"]
            }
        }, "splitArea": {
            "show": true, "areaStyle": {
                "color": ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
            }
        }
    },
    "toolbox": {
        "iconStyle": {
            "borderColor": "#999"
        }, "emphasis": {
            "iconStyle": {
                "borderColor": "#666"
            }
        }
    },
    "legend": {
        "textStyle": {
            "color": "#cccccc"
        }
    },
    "tooltip": {
        "axisPointer": {
            "lineStyle": {
                "color": "#ccc", "width": 1
            }, "crossStyle": {
                "color": "#ccc", "width": 1
            }
        }
    },
    "timeline": {
        "lineStyle": {
            "color": "#ccc", "width": 1
        }, "itemStyle": {
            "color": "#ccc", "borderWidth": 1
        }, "controlStyle": {
            "color": "#ccc", "borderColor": "#ccc", "borderWidth": 0.5
        }, "checkpointStyle": {
            "color": "#ccc", "borderColor": "#999"
        }, "label": {
            "color": "#ccc"
        }, "emphasis": {
            "itemStyle": {
                "color": "#ccc"
            }, "controlStyle": {
                "color": "#ccc", "borderColor": "#ccc", "borderWidth": 0.5
            }, "label": {
                "color": "#ccc"
            }
        }
    },
    "visualMap": {
        "color": [blue.dark, blue.main, blue.light]
    },
    "dataZoom": {
        "backgroundColor": "rgba(0,0,0,0)",
        "dataBackgroundColor": "rgba(255,255,255,0.3)",
        "fillerColor": "rgba(167,183,204,0.4)",
        "handleColor": "#a7b7cc",
        "handleSize": "100%",
        "textStyle": {
            "color": "#333"
        }
    },
    "markPoint": {
        "label": {
            "color": darkTheme.palette.background.paper
        }, "emphasis": {
            "label": {
                "color": darkTheme.palette.background.paper
            }
        }
    }
}

