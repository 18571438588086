import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import {Box, Chip, Tooltip, Typography} from "@mui/material";
import moment from "moment";
import React from "react";
import {CreditTransactionResponseTypeEnum} from "../../../generated/models/CreditTransactionResponse";
import UserDisplayName from "../../UserDisplayName";
import {LoginHolder} from "../../provider/LoginProvider";
import {UserAdapter} from "../../../adapters/UserAdapter";


export const columns: (login: LoginHolder | null, userAdapter: UserAdapter) => GridColDef[] = (login, userAdapter) => {
    return [
        {
            field: "id", headerName: "ID", minWidth: 100, filterable: false, sortable: true,
            renderCell: (params: GridCellParams) => {
                if (!params.row.id) return null
                return (<Tooltip title={params.row.id}><Box overflow="hidden"
                                                            textOverflow="ellipsis">{params.value}</Box></Tooltip>)
            },
        },
        {field: "tenantId", headerName: "Tenant", minWidth: 100, filterable: false, sortable: true},
        {
            field: "credits", headerName: "Credits", minWidth: 50, flex: 1, filterable: true, sortable: true,
            type: "number", valueFormatter: ({value}) => Number(value).toFixed(2),
        },
        {
            field: "amount", headerName: "Amount", minWidth: 50, flex: 1, filterable: true, sortable: true,
            type: "number", valueFormatter: ({value}) => Number(value).toFixed(2),
        },
        {
            field: "pricePerCredit", headerName: "Price", minWidth: 50, flex: 1, filterable: false, sortable: false,
            type: "number", renderCell: (params: GridCellParams) => {
                const credits = params.row.credits;
                if (!credits) return null
                const amount = params.row.amount;
                if (!amount) return null
                return (Number(amount) / Number(credits)).toFixed(2)
            },
        },
        {
            field: "type", headerName: "Type", minWidth: 80, flex: 1, filterable: false, sortable: false,
            renderCell: (params: GridCellParams) => {
                const creditTransactionType = params.row.type;
                if (!creditTransactionType) return null
                return (<Chip label={creditTransactionType}
                              color={creditTransactionType === CreditTransactionResponseTypeEnum.Refund ? "warning" : creditTransactionType === CreditTransactionResponseTypeEnum.Usage ? "error" : "success"}/>)
            }
        },
        {
            field: "description", headerName: "Description", minWidth: 50, filterable: false, sortable: false,
            renderCell: (params: GridCellParams) => {
                if (!params.row.description) return null
                return (<Typography sx={{fontSize: 14}} color="text.secondary">{params.row.description}</Typography>)
            }
        },
        {
            field: "userId", headerName: "User", minWidth: 100, width: 120, filterable: false, sortable: false,
            renderCell: (params: GridCellParams) => {
                return (<UserDisplayName userId={params.row.userId} login={login} adapter={userAdapter}/>)
            }
        },
        {
            field: "valuedAt", headerName: "Valued", minWidth: 100, width: 120, filterable: true, sortable: true,
            renderCell: (params: GridCellParams) => {
                if (!params.row.valuedAt) return null
                const date = moment(params.row.valuedAt).format("YYYY-MM-DD")
                return (<Chip label={date}/>)
            },
        },
        {
            field: "expiredAt", headerName: "Expired", minWidth: 100, width: 120, filterable: true, sortable: true,
            renderCell: (params: GridCellParams) => {
                if (!params.row.expiredAt) return null
                const date = moment(params.row.expiredAt).format("YYYY-MM-DD")
                return (<Chip label={date}/>)
            },
        },
        {
            field: "createdAt", headerName: "Created", minWidth: 140, width: 220, filterable: false, sortable: true,
            renderCell: (params: GridCellParams) => {
                if (!params.row.createdAt) return null
                const timestamp = moment(params.row.createdAt)
                return (<Box component="span"><Tooltip title={timestamp.local().format()}><Chip
                        label={timestamp.fromNow()}/></Tooltip></Box>)
            },
        },
    ]
}
