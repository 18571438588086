import React from "react";
import {Box, Link} from "@mui/material";
import {ErrorSharp} from "@mui/icons-material";
import GeneralLayout from "../layout/GeneralLayout";
import {RouteComponentProps} from "@reach/router";

export interface Props extends RouteComponentProps {
    error: string
    backLink: string
    backLinkText: string
}

export default function (props: Props) {
    return (
            <GeneralLayout title={`Error`}>
                <Box m={2}>{props.error}</Box>
                <Box m={2}><ErrorSharp style={{fontSize: 100}}/></Box>
                <Box m={2}>
                    <Link href={props.backLink} variant="body2">{props.backLinkText}</Link>
                </Box>
            </GeneralLayout>
    );
}
