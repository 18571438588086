import React from "react";
import {LoginHolder} from "../../provider/LoginProvider";
import {ServiceResponse} from "../../../generated/models/ServiceResponse";
import {ServiceAdapter} from "../../../adapters/ServiceAdapter";
import {Dialog, DialogContent, DialogTitle, Grid} from "@mui/material";
import {TagAdapter} from "../../../adapters/TagAdapter";
import ServiceEditForm from "./ServiceEditForm";
import {TagResponse} from "../../../generated/models/TagResponse";
import {TagServiceResponse} from "../../../generated/models/TagServiceResponse";
import {TenantSettingAdapter} from "../../../adapters/TenantSettingAdapter";

export interface Props {
    id: string
    title: string
    open: boolean
    onClose?: () => void
    onSubmitted?: () => void
    serviceAdapter: ServiceAdapter
    tagAdapter: TagAdapter
    tenantSettingsAdapter: TenantSettingAdapter,
    login: LoginHolder | null
    service: ServiceResponse
    tagsOnService: TagServiceResponse[]
    tags: TagResponse[]
    currency: string
}

export default function (props: Props) {
    const onClose: () => void = () => {
        if (props.onClose) props.onClose()
    }

    return (<Dialog id={props.id} open={props.open || false} onClose={props.onClose}
                    fullScreen aria-labelledby={`${props.id}-title`} transitionDuration={0}>
        <DialogTitle id={`${props.id}-title`}>{props.title}</DialogTitle>
        <DialogContent>
            <Grid container spacing={2} sx={{marginTop: '-8px'}}>
                <ServiceEditForm id={`${props.id}-form-wrapper`}
                                 onClose={onClose}
                                 onSubmitted={props.onSubmitted}
                                 serviceAdapter={props.serviceAdapter}
                                 tagAdapter={props.tagAdapter}
                                 tenantSettingsAdapter={props.tenantSettingsAdapter}
                                 login={props.login}
                                 service={props.service}
                                 tagsOnService={props.tagsOnService}
                                 tags={props.tags}
                                 currency={props.currency}
                />
            </Grid>
        </DialogContent>
    </Dialog>)
}
