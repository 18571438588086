import {UserResponse, UserResponseStateEnum} from "../../../generated/models/UserResponse";
import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import {Box, Chip, Tooltip} from "@mui/material";
import {CheckCircleOutlineSharp, CheckSharp, MailOutlineSharp, RemoveSharp} from "@mui/icons-material";
import moment from "moment";
import React from "react";

export const emptyUser: UserResponse = {
    id: "",
    email: "",
    firstname: "",
    lastname: "",
    external: false,
    createdAt: new Date(0),
    updatedAt: new Date(0),
}

export const columns: GridColDef[] = [
    {
        field: "id", headerName: "ID", minWidth: 100, filterable: false, sortable: true,
        renderCell: (params: GridCellParams) => {
            if (!params.row.id) return null
            return (<Tooltip title={params.row.id}><Box overflow="hidden"
                                                       textOverflow="ellipsis">{params.value}</Box></Tooltip>)
        },
    },
    {field: "email", headerName: "Email", minWidth: 120, flex: 1, filterable: false, sortable: true},
    {field: "firstname", headerName: "Firstname", minWidth: 120, flex: 1, filterable: false, sortable: false},
    {field: "lastname", headerName: "Lastname", minWidth: 120, flex: 1, filterable: false, sortable: false},
    {
        field: "state", headerName: "State", width: 80, filterable: false, sortable: false,
        renderCell: (params: GridCellParams) => {
            if (!params.row.state) return null
            switch (params.row.state) {
                case UserResponseStateEnum.Active:
                    return (<Tooltip title="Active"><CheckCircleOutlineSharp/></Tooltip>)
                case UserResponseStateEnum.Invited:
                    return (<Tooltip title="Invited"><MailOutlineSharp/></Tooltip>)
                default:
                    return params.row.state
            }
        },
    },
    {
        field: "external", headerName: "External", width: 80, filterable: false, sortable: false,
        renderCell: (params: GridCellParams) => {
            return params.row.external ? (<CheckSharp/>) : (<RemoveSharp/>)
        },
    },
    {
        field: "createdAt", headerName: "Created", minWidth: 140, width: 220, filterable: false, sortable: true,
        renderCell: (params: GridCellParams) => {
            if (!params.row.createdAt) return null
            const timestamp = moment(params.row.createdAt)
            return (<Box component="span"><Tooltip title={timestamp.local().format()}><Chip
                    label={timestamp.fromNow()}/></Tooltip></Box>)
        },
    },
    {
        field: "updatedAt", headerName: "Updated", minWidth: 140, width: 220, filterable: false, sortable: true,
        renderCell: (params: GridCellParams) => {
            if (!params.row.updatedAt) return null
            const timestamp = moment(params.row.updatedAt)
            return (<Box component="span"><Tooltip title={timestamp.local().format()}><Chip
                    label={timestamp.fromNow()}/></Tooltip></Box>)
        },
    },
]
