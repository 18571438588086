import {Autocomplete, Checkbox, TextField} from "@mui/material";
import {CheckBoxOutlineBlankSharp, CheckBoxSharp} from "@mui/icons-material";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {UserPermissionResponseEntityEnum} from "../../generated/models/UserPermissionResponse";
import {ApiKeyPermissionResponseEntityEnum} from "../../generated/models/ApiKeyPermissionResponse";
import {PermissionAction} from "../../generated/models/PermissionAction";
import {nameOfEnumKey} from "../../misc/enum";

export interface Props {
    id: string
    label?: string
    name?: string
    entity: UserPermissionResponseEntityEnum | ApiKeyPermissionResponseEntityEnum
    options: PermissionAction[]
    value?: PermissionAction[]
    onChange?: (actions: PermissionAction[]) => void
    error?: string | null
    helpMessage?: string
    invalidMessage?: string
    multiple?: boolean
    readonly?: boolean
    disabled?: boolean
}

const PermissionInput = forwardRef<any, Props>((props, ref) => {
    const DEFAULT_LABEL: string = "Permissions"
    const DEFAULT_NAME: string = "permissions"
    const DEFAULT_HELP_MESSAGE: string = "Select a permission."

    const name: string = props.name || DEFAULT_NAME
    const multiple: boolean = props.multiple === undefined ? true : props.multiple

    const [value, setValue] = useState<PermissionAction[]>(props.value === undefined ? [] : props.value)
    const [error, setError] = useState<string | null>(props.error === undefined ? null : props.error)

    useImperativeHandle(ref, () => ({
        getValue: (): PermissionAction[] => value
    }))
    useEffect(() => setValue(props.value === undefined ? [] : props.value), [props.value])
    useEffect(() => setError(props.error === undefined ? null : props.error), [props.error])

    return (<>
        <input id={`${props.id}-form-${name}`} type="hidden" name={name} defaultValue={value.join()}/>
        <Autocomplete id={`${props.id}-form-${name}-input`} multiple={multiple} fullWidth disableCloseOnSelect
                      options={props.options || []} size="small"
                      getOptionLabel={(option: PermissionAction | undefined) => option ? nameOfEnumKey(option, PermissionAction) : "?"}
                      isOptionEqualToValue={(option, value) => option === value}
                      onChange={(event, values) => {
                          let permissions: PermissionAction[] = []
                          if (Array.isArray(values)) {
                              permissions = values
                          } else if (values) {
                              permissions = [values]
                          }
                          setValue(permissions)
                          console.debug(`Set value of permissions to`, value)
                          if (props.onChange) props.onChange(permissions)
                      }}
                      value={value}
                      renderOption={(renderProps, option, {selected}) => (
                              <li {...renderProps}>
                                  <Checkbox id={`${props.id}-option-${option}`}
                                            name={`${name}-${option}`} checked={selected}
                                            icon={<CheckBoxOutlineBlankSharp fontSize="small"/>}
                                            checkedIcon={<CheckBoxSharp fontSize="small"/>}
                                            style={{marginRight: 8}}/>
                                  {nameOfEnumKey(option, PermissionAction)}
                              </li>
                      )}
                      renderInput={(params) => (
                              <TextField {...params}
                                         variant="outlined"
                                         error={props.error != null}
                                         label={props.label || DEFAULT_LABEL}
                                         helperText={error || props.helpMessage || DEFAULT_HELP_MESSAGE}/>
                      )}/>
    </>)
})
export default PermissionInput
