import React, {useContext, useEffect, useState} from "react";
import {
    Alert,
    AlertTitle,
    Box,
    CircularProgress,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    Typography
} from "@mui/material";
import AuthenticatedLayout from "../../../components/layout/AuthenticatedLayout";
import {FlatPaper} from "../../Misc";
import {LoginContext} from "../../provider/LoginProvider";
import {TenantContext} from "../../provider/TenantProvider";
import {RouteComponentProps} from "@reach/router";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    timelineItemClasses,
    TimelineSeparator
} from "@mui/lab";
import {Page, pageable, tenantContractAdapter, UUID} from "../../../adapters/interfaces";
import {TenantContractResponse} from "../../../generated/models/TenantContractResponse";
import moment from "moment";
import {TenantContractDocumentResponse} from "../../../generated/models/TenantContractDocumentResponse";
import {StyledRawHtml} from "../../RawHtml";
import {
    AddSharp,
    ContentCopySharp,
    DeleteForeverSharp,
    DeleteSharp,
    DriveFileRenameOutlineSharp,
    EditSharp,
    FileDownloadSharp,
    FileUploadSharp
} from "@mui/icons-material";
import {BooleanParam, useQueryParam} from "use-query-params";
import {useHotkeys} from "react-hotkeys-hook";
import TenantContractCreateDialog from "./TenantContractCreateDialog";
import TenantContractDeleteDialog from "./TenantContractDeleteDialog";
import TenantContractDocumentCreateDialog from "./TenantContractDocumentCreateDialog";
import TenantContractDocumentDeleteDialog from "./TenantContractDocumentDeleteDialog";
import TenantContractDocumentRenameDialog from "./TenantContractDocumentRenameDialog";
import TenantContractEditDialog from "./TenantContractEditDialog";
import {PermissionAction, PermissionEntity, permits} from "../../../misc/authorization";

export interface Props extends RouteComponentProps {
    title: string
}

export default function (props: Props) {
    const {login} = useContext(LoginContext)
    const {tenant} = useContext(TenantContext)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [isDownloading, setIsDownloading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [elementsPage, setElementsPage] = useState<Page<TenantContractResponse> | null>(null)
    const [documentsPage, setDocumentsPage] = useState<Record<UUID, Page<TenantContractDocumentResponse>>>({})
    const [createDialogOpen, setCreateDialogOpen] = useQueryParam("create", BooleanParam)
    const [cloneDialogOpen, setCloneDialogOpen] = useState<boolean>(false)
    const [editDialogOpen, setEditDialogOpen] = useQueryParam("edit", BooleanParam)
    const [deleteDialogOpen, setDeleteDialogOpen] = useQueryParam("delete", BooleanParam)
    const [uploadDocumentDialogOpen, setUploadDocumentDialogOpen] = useState<boolean>(false)
    const [renameDocumentDialogOpen, setRenameDocumentDialogOpen] = useState<boolean>(false)
    const [deleteDocumentDialogOpen, setDeleteDocumentDialogOpen] = useState<boolean>(false)
    const [selectedElement, setSelectedElement] = useState<TenantContractResponse | null>(null)
    const [selectedElementDocument, setSelectedElementDocument] = useState<TenantContractDocumentResponse | null>(null)

    const load = (): void => {
        setLoading(true)
        tenantContractAdapter.find(login, pageable(0, 100, `tenantId=="${tenant?.tenant?.id}"`, "-validTo"))
                .then(response => setElementsPage(response))
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }
    const loadDocuments = (id: UUID): Promise<Page<TenantContractDocumentResponse>> => {
        return tenantContractAdapter.findDocuments(login, pageable(0, 100, `tenantContractId=="${id}"`, decodeURIComponent("+name")))
    }
    const downloadDocumentData = (contractDocument: TenantContractDocumentResponse) => {
        setIsDownloading(true)
        setError(null)
        tenantContractAdapter.getDocumentData(login, contractDocument)
                .then(blob => {
                    if (!blob) {
                        console.error("Downloaded data is null")
                        return
                    }
                    const downloadUrl = window.URL.createObjectURL(new Blob([blob]))
                    const link = document.createElement("a")
                    link.href = downloadUrl
                    link.setAttribute("download", contractDocument.name)
                    document.body.appendChild(link)
                    link.click()
                    link.parentNode?.removeChild(link)
                })
                .catch(error => setError(error.message))
                .finally(() => setIsDownloading(false))
    }

    useHotkeys('a', () => {
        document.getElementById('add')?.click()
    })
    useEffect(() => {
        console.debug("Loading elements")
        load()
    }, [])
    useEffect(() => {
        if (elementsPage && elementsPage.elements) {
            console.debug("Loading element documents")
            let documents: Record<UUID, Page<TenantContractDocumentResponse>> = {}
            Promise.all(elementsPage.elements.map(element => loadDocuments(element.id).then(response => {
                documents[element.id] = response
            }))).finally(() => {
                setDocumentsPage(documents)
            })
        }
    }, [elementsPage])

    const buttonBar = (<>
                {permits([
                    {
                        entities: [PermissionEntity.TENANTCONTRACT],
                        actions: [PermissionAction.CREATE],
                        ids: [],
                    }
                ], login, tenant?.tenant.id) ? (<Tooltip title="Add (a)" placement="bottom">
                    <span><IconButton id="add" disabled={error != null}
                                      onClick={() => setCreateDialogOpen(true)}><AddSharp/></IconButton></span>
                </Tooltip>) : null}
                <TenantContractCreateDialog id="create-dialog"
                                            title={`Create contract`}
                                            open={createDialogOpen === undefined || createDialogOpen === null ? false : createDialogOpen}
                                            login={login}
                                            tenantId={tenant?.tenant.id || ""}
                                            onSubmitted={() => {
                                                setCreateDialogOpen(false)
                                                load()
                                            }}
                                            onClose={() => setCreateDialogOpen(false)}
                                            adapter={tenantContractAdapter}
                />
                <TenantContractCreateDialog id="clone-dialog"
                                            title={`Clone contract`}
                                            open={cloneDialogOpen === undefined || cloneDialogOpen === null ? false : cloneDialogOpen}
                                            login={login}
                                            tenantId={tenant?.tenant.id || ""}
                                            element={selectedElement || undefined}
                                            onSubmitted={() => {
                                                setCloneDialogOpen(false)
                                                load()
                                            }}
                                            onClose={() => setCloneDialogOpen(false)}
                                            adapter={tenantContractAdapter}
                />
                <TenantContractEditDialog id="edit-dialog"
                                          title={`Edit contract`}
                                          open={editDialogOpen === undefined || editDialogOpen === null ? false : editDialogOpen}
                                          element={selectedElement}
                                          login={login}
                                          onSubmitted={() => {
                                              setEditDialogOpen(false)
                                              load()
                                          }}
                                          onClose={() => {
                                              setEditDialogOpen(false)
                                              setSelectedElement(null)
                                          }}
                                          adapter={tenantContractAdapter}
                />
                <TenantContractDeleteDialog id="delete-dialog"
                                            title={`Delete contract`}
                                            open={deleteDialogOpen === undefined || deleteDialogOpen === null ? false : deleteDialogOpen}
                                            idsToDelete={[selectedElement?.id || ""]}
                                            onClose={() => {
                                                setDeleteDialogOpen(false)
                                                setSelectedElement(null)
                                            }}
                                            onSubmitted={() => {
                                                setDeleteDialogOpen(false)
                                                load()
                                            }}
                                            login={login}
                                            adapter={tenantContractAdapter}
                />
                <TenantContractDocumentCreateDialog id="upload-document-dialog"
                                                    title={`Upload contract document`}
                                                    open={uploadDocumentDialogOpen === undefined || uploadDocumentDialogOpen === null ? false : uploadDocumentDialogOpen}
                                                    login={login}
                                                    tenantContractId={selectedElement?.id || ""}
                                                    onSubmitted={() => {
                                                        setUploadDocumentDialogOpen(false)
                                                        load()
                                                    }}
                                                    onClose={() => {
                                                        setUploadDocumentDialogOpen(false)
                                                        setSelectedElement(null)
                                                    }}
                                                    adapter={tenantContractAdapter}/>
                <TenantContractDocumentRenameDialog id="rename-document-dialog"
                                                    title={`Rename contract document`}
                                                    open={renameDocumentDialogOpen === undefined || renameDocumentDialogOpen === null ? false : renameDocumentDialogOpen}
                                                    login={login}
                                                    element={selectedElementDocument}
                                                    onSubmitted={() => {
                                                        setRenameDocumentDialogOpen(false)
                                                        load()
                                                    }}
                                                    onClose={() => {
                                                        setRenameDocumentDialogOpen(false)
                                                        setSelectedElementDocument(null)
                                                    }}
                                                    adapter={tenantContractAdapter}/>
                <TenantContractDocumentDeleteDialog id="delete-document-dialog"
                                                    title={`Delete contract document`}
                                                    open={deleteDocumentDialogOpen === undefined || deleteDocumentDialogOpen === null ? false : deleteDocumentDialogOpen}
                                                    idsToDelete={[selectedElementDocument?.id || ""]}
                                                    onClose={() => {
                                                        setDeleteDocumentDialogOpen(false)
                                                        setSelectedElementDocument(null)
                                                    }}
                                                    onSubmitted={() => {
                                                        setDeleteDocumentDialogOpen(false)
                                                        load()
                                                    }}
                                                    login={login}
                                                    adapter={tenantContractAdapter}
                />
            </>
    )

    return (<AuthenticatedLayout title={props.title}
                                 breadcrumbs={[{name: "Overview", link: "/app"}, {
                                     name: props.title,
                                     link: props.uri || ""
                                 }]}
                                 topRightSection={buttonBar}>
        {error != null ? (
                <FlatPaper>
                    <Alert severity="error">
                        <AlertTitle>Failed to load tenant contract data</AlertTitle>
                        {error}
                    </Alert>
                </FlatPaper>
        ) : elementsPage && elementsPage.elements.length > 0 ? elementsPage!.elements.map(element => {
            const from = moment(element.validFrom)
            const to = moment(element.validTo)
            const created = moment(element.createdAt)
            const updated = moment(element.updatedAt)
            const isActive = moment().isBetween(from, to)
            const documents: TenantContractDocumentResponse[] = documentsPage[element.id]?.elements || []
            return (
                    <FlatPaper key={element.id} sx={{marginBottom: "16px", padding: "12px"}}>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6" noWrap>{element.licenseType}</Typography>
                                <Typography sx={{fontSize: 14}}
                                            color="text.secondary">{element.licensePackage}</Typography>
                                <Timeline sx={{[`& .${timelineItemClasses.root}:before`]: {flex: 0, padding: 0}}}>
                                    <TimelineItem sx={{minHeight: "50px"}}>
                                        <TimelineSeparator>
                                            <TimelineDot color={isActive ? "primary" : undefined}/>
                                            <TimelineConnector/>
                                        </TimelineSeparator>
                                        <TimelineContent><Typography sx={{fontSize: 14}}
                                                                     color="text.secondary">From {from.format("YYYY-MM-DD")}</Typography></TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot color={isActive ? "primary" : undefined}/>
                                        </TimelineSeparator>
                                        <TimelineContent><Typography sx={{fontSize: 14}}
                                                                     color="text.secondary">To {to.format("YYYY-MM-DD")}</Typography></TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{marginBottom: "8px"}}>
                                    <Typography variant="body2">Credit price</Typography>
                                    <Typography
                                            color="text.secondary">{element.currency} {element.creditPrice.toFixed(2)}</Typography>
                                </Box>
                                <Box sx={{marginBottom: "8px"}}>
                                    <Typography variant="body2">Metrics retention</Typography>
                                    <Typography
                                            color="text.secondary">{(element.metricsRetentionInHours / 24).toFixed(2)} days</Typography>
                                </Box>
                                <Box sx={{marginBottom: "8px"}}>
                                    <Typography variant="body2">Logs retention</Typography>
                                    <Typography
                                            color="text.secondary">{(element.logsRetentionInHours / 24).toFixed(2)} days</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box sx={{marginBottom: "8px"}}>
                                    <Typography variant="body2">Support plan</Typography>
                                    <Typography color="text.secondary">{element.supportPlan}</Typography>
                                </Box>
                                <Box sx={{marginBottom: "8px"}}>
                                    <Typography variant="body2">Response agreement</Typography>
                                    <Typography sx={{fontSize: "12px"}}
                                                color="text.secondary">{element.supportResponseAgreement}</Typography>
                                </Box>
                                <Box sx={{marginBottom: "8px"}}>
                                    <Typography variant="body2">Resolution agreement</Typography>
                                    <Typography sx={{fontSize: "12px"}}
                                                color="text.secondary">{element.supportResolutionAgreement}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            {documents.length > 0 ? (<Grid item xs={12}>
                                <Typography variant="body2">Documents</Typography>
                                <List sx={{width: '100%'}} dense>
                                    {documents.map(document => (
                                            <ListItem key={document.id} secondaryAction={
                                                <>
                                                    {permits([
                                                        {
                                                            entities: [PermissionEntity.TENANTCONTRACTDOCUMENT],
                                                            actions: [PermissionAction.READ],
                                                            ids: [document.id],
                                                        }
                                                    ], login, tenant?.tenant.id) ? (
                                                            <Tooltip title={`Download ${document.name}`}
                                                                     placement="bottom">
                                                                <IconButton onClick={() => {
                                                                    setSelectedElementDocument(document)
                                                                    downloadDocumentData(document)
                                                                }}>
                                                                    {selectedElementDocument?.id === document.id && isDownloading ? (
                                                                                    <CircularProgress color="inherit"
                                                                                                      size="14px"/>) :
                                                                            (<FileDownloadSharp/>)}
                                                                </IconButton>
                                                            </Tooltip>) : null}
                                                    {permits([
                                                        {
                                                            entities: [PermissionEntity.TENANTCONTRACTDOCUMENT],
                                                            actions: [PermissionAction.UPDATE],
                                                            ids: [document.id],
                                                        }
                                                    ], login, tenant?.tenant.id) ? (
                                                            <Tooltip title={`Rename ${document.name}`}
                                                                     placement="bottom">
                                                                <IconButton onClick={() => {
                                                                    setSelectedElementDocument(document)
                                                                    setRenameDocumentDialogOpen(true)
                                                                }}><DriveFileRenameOutlineSharp/></IconButton>
                                                            </Tooltip>) : null}
                                                    {permits([
                                                        {
                                                            entities: [PermissionEntity.TENANTCONTRACTDOCUMENT],
                                                            actions: [PermissionAction.DELETE],
                                                            ids: [document.id],
                                                        }
                                                    ], login, tenant?.tenant.id) ? (
                                                            <Tooltip title={`Delete ${document.name}`}
                                                                     placement="bottom">
                                                                <IconButton onClick={() => {
                                                                    setSelectedElementDocument(document)
                                                                    setDeleteDocumentDialogOpen(true)
                                                                }}><DeleteForeverSharp/></IconButton>
                                                            </Tooltip>) : null}
                                                </>
                                            }>
                                                <ListItemText primary={document.name}
                                                              secondary={`${moment(document.createdAt).format("YYYY-MM-DD HH:mm:ss")} (created), ${moment(document.updatedAt).format("YYYY-MM-DD HH:mm:ss")} (updated)`}/>
                                            </ListItem>))}
                                </List>
                            </Grid>) : null}
                            {element.informationText?.trim() ? (
                                    <Grid item xs={12} sx={{paddingBottom: 0, paddingTop: 0}}>
                                        <Typography variant="body2">Information</Typography>
                                        <Box><StyledRawHtml
                                                html={element.informationText.replace(/\r/gi, "").replace(/\n/gi, "<br/>")}/></Box>
                                    </Grid>) : null}
                            <Grid item xs={8}>
                                <Typography component="span" sx={{fontSize: 12}} color="text.secondary">
                                    <Tooltip
                                            title={created.toISOString()}><span>Created {created.fromNow()}</span></Tooltip>
                                </Typography>
                                <Typography component="span" sx={{fontSize: 12}} color="text.secondary">
                                    <Tooltip
                                            title={updated.toISOString()}><span> and updated {updated.fromNow()}</span></Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item xs={4} container justifyContent="flex-end">
                                {permits([
                                    {
                                        entities: [PermissionEntity.TENANTCONTRACTDOCUMENT],
                                        actions: [PermissionAction.CREATE],
                                        ids: [],
                                    }
                                ], login, tenant?.tenant.id) ? (<Tooltip title="Upload document" placement="bottom">
                                            <span><IconButton disabled={error != null}
                                                              onClick={() => {
                                                                  setSelectedElement(element)
                                                                  setUploadDocumentDialogOpen(true)
                                                              }}><FileUploadSharp/></IconButton></span>
                                </Tooltip>) : null}
                                {permits([
                                    {
                                        entities: [PermissionEntity.TENANTCONTRACT],
                                        actions: [PermissionAction.CREATE],
                                        ids: [],
                                    }
                                ], login, tenant?.tenant.id) ? (<Tooltip title="Clone" placement="bottom">
                                            <span><IconButton disabled={error != null}
                                                              onClick={() => {
                                                                  setSelectedElement(element)
                                                                  setCloneDialogOpen(true)
                                                              }}><ContentCopySharp/></IconButton></span>
                                </Tooltip>) : null}
                                {permits([
                                    {
                                        entities: [PermissionEntity.TENANTCONTRACT],
                                        actions: [PermissionAction.UPDATE],
                                        ids: [element.id],
                                    }
                                ], login, tenant?.tenant.id) ? (<Tooltip title="Edit" placement="bottom">
                                            <span><IconButton disabled={error != null}
                                                              onClick={() => {
                                                                  setSelectedElement(element)
                                                                  setEditDialogOpen(true)
                                                              }}><EditSharp/></IconButton></span>
                                </Tooltip>) : null}
                                {permits([
                                    {
                                        entities: [PermissionEntity.TENANTCONTRACT],
                                        actions: [PermissionAction.DELETE],
                                        ids: [element.id],
                                    }
                                ], login, tenant?.tenant.id) ? (<Tooltip title="Delete" placement="bottom">
                                            <span><IconButton disabled={error != null}
                                                              onClick={() => {
                                                                  setSelectedElement(element)
                                                                  setDeleteDialogOpen(true)
                                                              }}><DeleteSharp/></IconButton></span>
                                </Tooltip>) : null}
                            </Grid>
                        </Grid>
                    </FlatPaper>
            )
        }) : (<FlatPaper>
            <Box component="div"
                 display="flex"
                 justifyContent="center"
                 alignItems="center"
                 sx={{width: "100%", height: "200px"}}>
                <Typography color="textSecondary">No contracts defined.</Typography>
            </Box>
        </FlatPaper>)
        }
    </AuthenticatedLayout>)
}
