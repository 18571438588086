import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import {Box, Chip, Tooltip} from "@mui/material";
import moment from "moment";
import React from "react";
import {TenantResponse} from "../../../generated/models/TenantResponse";

export const ALIAS_REGEX: string = "^[a-z][a-z0-9-]{1,14}[a-z0-9]$"

export const emptyTenant: TenantResponse = {
    id: "",
    name: "",
    alias: "",
    createdAt: new Date(0),
    updatedAt: new Date(0),
}

export const columns: GridColDef[] = [
    {
        field: "id", headerName: "ID", minWidth: 100, filterable: false, sortable: true,
        renderCell: (params: GridCellParams) => {
            if (!params.value) return null
            return (<Tooltip title={params.value}><Box overflow="hidden"
                                                       textOverflow="ellipsis">{params.value}</Box></Tooltip>)
        },
    },
    {field: "alias", headerName: "Alias", minWidth: 100, width: 200, filterable: false, sortable: true},
    {field: "name", headerName: "Name", minWidth: 120, flex: 1, filterable: false, sortable: false},
    {
        field: "createdAt", headerName: "Created", minWidth: 140, width: 220, filterable: false, sortable: true,
        renderCell: (params: GridCellParams) => {
            if (!params.value) return null
            const timestamp = moment(params.value)
            return (<Box component="span"><Tooltip title={timestamp.local().format()}><Chip
                    label={timestamp.fromNow()}/></Tooltip></Box>)
        },
    },
    {
        field: "updatedAt", headerName: "Updated", minWidth: 140, width: 220, filterable: false, sortable: true,
        renderCell: (params: GridCellParams) => {
            if (!params.value) return null
            const timestamp = moment(params.value)
            return (<Box component="span"><Tooltip title={timestamp.local().format()}><Chip
                    label={timestamp.fromNow()}/></Tooltip></Box>)
        },
    },
]
