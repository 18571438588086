import React, {useContext, useEffect, useState} from "react";
import {LoginContext} from "../../provider/LoginProvider";
import "swagger-ui-react/swagger-ui.css";
import {TenantContext} from "../../provider/TenantProvider";
import {UiConfigContext} from "../../provider/UiConfigProvider";
import {RouteComponentProps} from "@reach/router";
import {TITLE_SUFFIX} from "../../layout/GeneralLayout";
import {ServiceEndpoint} from "../../../generated/models/ServiceEndpoint";
import {ServiceResponse} from "../../../generated/models/ServiceResponse";
import {serviceAdapter} from "../../../adapters/interfaces";
import {ServiceEndpointType} from "../../../generated/models/ServiceEndpointType";
import {marked} from "marked";
import Asciidoctor from "asciidoctor";
import {StyledRawHtml} from "../../RawHtml";
import ErrorView from "../ErrorView";
import {Backdrop, Box, CircularProgress} from "@mui/material";

const ASCIIDOC_TEST_REGEX = /^=+ .*$/gm;
const MARKDOWN_TEST_REGEX = /^#+ .*$/gm;
type DocsType = "html" | "markdown" | "asciidoc" | null;

interface Headers {
    [key: string]: any
}

export interface Props extends RouteComponentProps {
    serviceId?: string
    serviceDocsEndpointIndex?: number
}

export default function (props: Props) {
    const {uiConfig} = useContext(UiConfigContext)
    const {login} = useContext(LoginContext)
    const {tenant} = useContext(TenantContext)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [element, setElement] = useState<ServiceResponse | null>(null)
    const [docsEndpoint, setDocsEndpoint] = useState<ServiceEndpoint | null>(null)
    const [docsUrl, setDocsUrl] = useState<string | null>(null)
    const [docsData, setDocsData] = useState<string | null>(null)
    const [docsDataType, setDocsDataType] = useState<DocsType>(null)

    const load = (): void => {
        if (!props.serviceId) return
        setLoading(true)
        serviceAdapter.get(login, props.serviceId)
                .then(response => {
                    setElement(response)
                })
                .catch(error => setError(error.message))
                .finally(() => {
                    setLoading(false)
                })
    }

    const loadDocumentation = (): void => {
        if (!docsUrl) return
        setLoading(true)
        let headers: Headers = {}
        if (!docsEndpoint?.path?.includes("://") && docsEndpoint?.isSecured && login?.token) {
            headers["Authorization"] = `Bearer ${login.token}`
        }
        fetch(docsUrl, {method: "GET", headers, redirect: "follow"})
                .then(response => {
                    console.debug("Response", response)
                    const contentType = response.headers.get("Content-Type")
                    return response.text().then(content => {
                        setDocsData(content)
                        return extractDocsDataTypeFromContent(content, contentType || "text/plain")
                    })
                })
                .then(type => {
                    console.debug("Docs type", type)
                    setDocsDataType(type)
                })
                .catch(error => {
                    const errorMessage = error.hasOwnProperty("message") ? error.message : error.toString();
                    console.error(errorMessage, error)
                    setError(`${errorMessage}: please check if your endpoint allows this origin for the request (see CORS headers)`)
                })
                .finally(() => {
                    setLoading(false)
                })
    }

    const extractDocsDataTypeFromContent = (content: string, contentType: string): DocsType => {
        // First check obvious indicators
        if (contentType.includes("markdown") || docsUrl?.endsWith(".md") || docsUrl?.endsWith(".markdown")) {
            return "markdown"
        }
        if (contentType.includes("asciidoc") || contentType.includes("adoc") || docsUrl?.endsWith(".adoc") || docsUrl?.endsWith(".asciidoc")) {
            return "asciidoc"
        }
        if (contentType.includes("text/html")) {
            return "html"
        }
        // Now check content
        if (MARKDOWN_TEST_REGEX.test(content)) {
            return "markdown"
        }
        if (ASCIIDOC_TEST_REGEX.test(content)) {
            return "asciidoc"
        }
        return null
    }

    useEffect(() => {
        document.title = `Documentation${TITLE_SUFFIX}`
        console.debug("Loading element")
        load()
    }, [])
    useEffect(() => {
        loadDocumentation()
    }, [docsUrl])
    useEffect(() => {
        if (element && tenant && uiConfig) {
            document.title = `Documentation - ${element.name}${TITLE_SUFFIX}`

            const endpoint = element.specification.endpoints[props.serviceDocsEndpointIndex!!]
            if (endpoint.type !== ServiceEndpointType.Docs) {
                setError(`Wrong documentation endpoint of type ${endpoint.type}`)
            } else if (endpoint) {
                setDocsEndpoint(endpoint)
                if (endpoint.path?.includes("://")) {
                    setDocsUrl(endpoint.path!!)
                } else {
                    const globalPrefix = endpoint.isExposed ? `/${tenant?.tenant.alias}/${element.alias}` : ``
                    const internalPath = `${endpoint.pathPrefix || ""}${endpoint.path || ""}`
                    setDocsUrl(`${uiConfig?.rootUrl}${globalPrefix}${internalPath}`)
                }
            }
        }
    }, [element, tenant, uiConfig])

    if (isLoading) {
        return (<Backdrop open={true} sx={{
            backgroundColor: theme => theme.palette.background.default
        }}><CircularProgress/></Backdrop>)
    }
    if (error) {
        return <ErrorView error={error} backLink={`${process.env.GATSBY_PATH_PREFIX}/app/services/${props.serviceId}`}
                          backLinkText="Back to service"/>
    }
    if (!docsData) {
        return <ErrorView error="No documentation data found."
                          backLink={`${process.env.GATSBY_PATH_PREFIX}/app/services/${props.serviceId}`}
                          backLinkText="Back to service"/>
    }

    document.title = `Documentation${TITLE_SUFFIX}`
    if (docsDataType === "markdown") {
        const parsedMarkdown = marked.parse(docsData);
        return (<Box m={3}><StyledRawHtml html={parsedMarkdown}/></Box>)
    } else if (docsDataType === "asciidoc") {
        const asciidoctor = Asciidoctor()
        const parsedAsciidoc = asciidoctor.convert(docsData, {safe: "safe"});
        return (<Box m={3}><StyledRawHtml html={parsedAsciidoc.toString()}/></Box>)
    } else if (docsDataType === "html") {
        return (<Box m={3}><StyledRawHtml html={docsData}/></Box>)
    }
    return <Box m={3}><StyledRawHtml html={(docsData || "").replace(/\n/g, "<br/>")}/></Box>
}