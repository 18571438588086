import {Autocomplete, Checkbox, TextField} from "@mui/material";
import {CheckBoxOutlineBlankSharp, CheckBoxSharp} from "@mui/icons-material";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {UserResponse} from "../../generated/models/UserResponse";

export interface Props {
    id: string
    label?: string
    name?: string
    options: UserResponse[]
    value?: UserResponse[]
    onChange?: (tenants: UserResponse[]) => void
    error?: string | null
    helpMessage?: string
    invalidMessage?: string
    multiple?: boolean
    readonly?: boolean
    disabled?: boolean
}

const UserInput = forwardRef<any, Props>((props, ref) => {
    const DEFAULT_LABEL: string = "Users"
    const DEFAULT_NAME: string = "users"
    const DEFAULT_HELP_MESSAGE: string = "Select a user."

    const name: string = props.name || DEFAULT_NAME
    const multiple: boolean = props.multiple === undefined ? true : props.multiple

    const [value, setValue] = useState<UserResponse[]>(props.value === undefined ? [] : props.value)
    const [error, setError] = useState<string | null>(props.error === undefined ? null : props.error)

    useImperativeHandle(ref, () => ({
        getValue: (): UserResponse[] => value
    }))
    useEffect(() => setValue(props.value === undefined ? [] : props.value), [props.value])
    useEffect(() => setError(props.error === undefined ? null : props.error), [props.error])

    return (<>
        <input id={`${props.id}-form-${name}`} type="hidden" name={name} defaultValue={value.map(e => e.id).join()}/>
        <Autocomplete id={`${props.id}-form-${name}-input`} multiple={multiple} fullWidth disableCloseOnSelect
                      options={props.options || []} size="small"
                      getOptionLabel={(option: UserResponse | undefined) => option?.email || "?"}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, values) => {
                          let users: UserResponse[] = []
                          if (Array.isArray(values)) {
                              users = values
                          } else if (values) {
                              users = [values]
                          }
                          setValue(users)
                          console.debug(`Set value of users to`, value)
                          if (props.onChange) props.onChange(users)
                      }}
                      value={value}
                      renderOption={(renderProps, option, {selected}) => (
                              <li {...renderProps}>
                                  <Checkbox id={`${props.id}-option-${option.id}`}
                                            name={`${name}-${option.id}`} checked={selected}
                                            icon={<CheckBoxOutlineBlankSharp fontSize="small"/>}
                                            checkedIcon={<CheckBoxSharp fontSize="small"/>}
                                            style={{marginRight: 8}}/>
                                  {option.email}
                              </li>
                      )}
                      renderInput={(params) => (
                              <TextField {...params}
                                         variant="outlined"
                                         error={props.error != null}
                                         label={props.label || DEFAULT_LABEL}
                                         helperText={error || props.helpMessage || DEFAULT_HELP_MESSAGE}/>
                      )}/>
    </>)
})
export default UserInput
