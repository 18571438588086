import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import TextInput from "../../form/TextInput";
import SelectInput from "../../form/SelectInput";
import {CreditTransactionResponseTypeEnum} from "../../../generated/models/CreditTransactionResponse";
import {DATE_FORMAT} from "../tenant/TenantContractCreateForm";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import {EventAvailableSharp, EventBusySharp} from "@mui/icons-material";

export interface Props {
    id: string
    typeRef: React.RefObject<any>
    creditsError: string | null
    amountError: string | null
    typeError: string | null
    descriptionError: string | null
    valuedAtError: string | null
    expiredAtError: string | null
}

export default function (props: Props) {
    const [creditsError, setCreditsError] = useState<string | null>(props.creditsError)
    const [amountError, setAmountError] = useState<string | null>(props.amountError)
    const [typeError, setTypeError] = useState<string | null>(props.typeError)
    const [descriptionError, setDescriptionError] = useState<string | null>(props.descriptionError)
    const [valuedAtError, setValuedAtError] = useState<string | null>(props.valuedAtError)
    const [expiredAtError, setExpiredAtError] = useState<string | null>(props.expiredAtError)

    useEffect(() => setCreditsError(props.creditsError), [props.creditsError])
    useEffect(() => setAmountError(props.amountError), [props.amountError])
    useEffect(() => setTypeError(props.typeError), [props.typeError])
    useEffect(() => setDescriptionError(props.descriptionError), [props.descriptionError])
    useEffect(() => setValuedAtError(props.valuedAtError), [props.valuedAtError])
    useEffect(() => setExpiredAtError(props.expiredAtError), [props.expiredAtError])
    useEffect(() => setDescriptionError(props.descriptionError), [props.descriptionError])

    return (<>
        <Grid item xs={6}>
            <TextInput id={`${props.id}-credits`}
                       label="Credits"
                       name="credits"
                       value="0"
                       error={creditsError} maxLength={null} pattern="^[0-9]{1,9}[.]{0,1}[0-9]{0,2}$"
                       helpMessage="The credit count affected by this transaction."/>
        </Grid>
        <Grid item xs={6}>
            <TextInput id={`${props.id}-amount`}
                       label="Amount"
                       name="amount"
                       value="0.00"
                       error={amountError} maxLength={null} pattern="^[0-9]{1,6}[.]{0,1}[0-9]{0,2}$"
                       helpMessage="The amount in EUR of this transaction."/>
        </Grid>
        <Grid item xs={6} sm={4}>
            <DatePicker slotProps={{
                textField: {
                    id: "form-valued",
                    name: "valuedAt",
                    fullWidth: true,
                    size: "small",
                    helperText: valuedAtError
                }
            }}
                        format={DATE_FORMAT}
                        label="Valued at"
                        value={moment(moment().toDate())}
                        onError={() => setValuedAtError("Invalid value")}
                        components={{
                            OpenPickerIcon: EventAvailableSharp
                        }}
            />
        </Grid>
        <Grid item xs={6} sm={4}>
            <DatePicker slotProps={{
                textField: {
                    id: "form-expired",
                    name: "expiredAt",
                    fullWidth: true,
                    size: "small",
                    helperText: expiredAtError
                }
            }}
                        format={DATE_FORMAT}
                        label="Expired at"
                        value={moment(moment().add(1, "years").subtract(1, "days").toDate())}
                        onError={() => setExpiredAtError("Invalid value")}
                        components={{
                            OpenPickerIcon: EventBusySharp
                        }}
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <SelectInput ref={props.typeRef}
                         id={`${props.id}-type`}
                         label="Type"
                         name="type"
                         options={[
                             {id: CreditTransactionResponseTypeEnum.Deposit, name: "Deposit"},
                             {id: CreditTransactionResponseTypeEnum.Refund, name: "Refund"},
                         ]}
                         error={typeError}
                         value={[{id: CreditTransactionResponseTypeEnum.Deposit, name: "Deposit"}]}/>
        </Grid>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-description`} label="Description" name="description" error={descriptionError}
                       helpMessage="The description of the transaction." maxLength={500} multiline={true}/>
        </Grid>
    </>)
}
