import React, {SyntheticEvent, useContext, useState} from "react";
import {
    Alert,
    Box,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Tooltip,
    Typography
} from "@mui/material";
import GeneralLayout from "../../components/layout/GeneralLayout";
import {HelpOutlineSharp} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {LoadingButton} from "@mui/lab";
import {LoginContext} from "../provider/LoginProvider";
import {RouteComponentProps} from "@reach/router";
import {apiPut} from "../../misc/api";

export interface Props extends RouteComponentProps {
    title: string
}

export default function (props: Props) {
    const theme = useTheme()
    const {login} = useContext(LoginContext)
    const [success, setSuccess] = useState<string | null>(null)
    const [emailError, setEmailError] = useState<string | null>(null)
    const [isLoading, setLoading] = useState<boolean>(false)

    const handleSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setSuccess(null)
        setEmailError(null)
        const target = event.target as typeof event.target & {
            email: { value: string }
        }
        const email = target.email.value.trim()
        apiPut(login, "/password-reset", JSON.stringify({email}), {}, false)
                .then(() => setSuccess("Please check your inbox to find the recovery link"))
                .catch(error => setEmailError(error.message))
                .finally(() => setLoading(false))
    }

    return (<GeneralLayout title={props.title}>
        <Box m={2}>Forgot your password?</Box>
        {success != null ? (
                <Alert severity="info" sx={{marginBottom: '20px'}}>
                    {success}
                </Alert>
        ) : null}
        {emailError != null ? (
                <Alert severity="error" sx={{marginBottom: '20px'}}>
                    Recovery failed.
                </Alert>
        ) : null}
        <form id="password-forgotten-form" noValidate method="post" onSubmit={handleSubmit}>
            <FormControl variant="outlined" error={emailError != null} fullWidth required>
                <InputLabel htmlFor="email">Email</InputLabel>
                <OutlinedInput id="email" name="email" label="Email" type="email" autoFocus endAdornment={
                    <InputAdornment position="end">
                        <Tooltip title="The email address to sent the recovery link to." placement="left">
                            <IconButton tabIndex={-1} edge="end"><HelpOutlineSharp/></IconButton>
                        </Tooltip>
                    </InputAdornment>
                }/>
                <Grid container>
                    <Grid item xs={10}>
                        <FormHelperText>{emailError ? emailError : null}</FormHelperText>
                    </Grid>
                </Grid>
            </FormControl>
            <Typography>A recovery link will be sent to your email address. You can then reset you
                password.</Typography>
            <LoadingButton id="password-forgotten"
                           type="submit"
                           fullWidth
                           variant="contained"
                           color="primary"
                           loading={isLoading}
                           sx={{margin: theme.spacing(3, 0, 2)}}>Send recovery link</LoadingButton>
            <Grid container>
                <Grid item xs>
                    <Link href={`${process.env.GATSBY_PATH_PREFIX}/app/login`} variant="body2">
                        Back to login
                    </Link>
                </Grid>
            </Grid>
        </form>
    </GeneralLayout>)
}
