import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Box,
    Button,
    Chip,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    Step,
    StepLabel,
    Stepper,
    Typography,
    useTheme
} from "@mui/material";
import React, {ChangeEvent, createRef, RefObject, SyntheticEvent, useState} from "react";
import {ALIAS_REGEX, DECIMAL_REGEX, emptyService, FILE_PATH_REGEX, serviceSpecificationSchema} from "./ServiceModel";
import CodeEditor, {CodeEditorMarker, CodeEditorMarkerSeverity} from "../../CodeEditor";
import {LoadingButton} from "@mui/lab";
import {CreateServiceRequest} from "../../../generated/models/CreateServiceRequest";
import {ServiceResponse} from "../../../generated/models/ServiceResponse";
import {ServiceAdapter} from "../../../adapters/ServiceAdapter";
import {LoginHolder} from "../../provider/LoginProvider";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import {TagAdapter} from "../../../adapters/TagAdapter";
import {AuthAdapter} from "../../../adapters/AuthAdapter";
import TextInput from "../../form/TextInput";
import {TagResponse} from "../../../generated/models/TagResponse";
import TagInput from "../../form/TagInput";
import {ServiceSpecification} from "../../../generated/models/ServiceSpecification";
import {CreateTagRequest} from "../../../generated/models/CreateTagRequest";
import {CreateTagServicesRequestFromJSON} from "../../../generated/models/CreateTagServicesRequest";
import UserInput from "../../form/UserInput";
import PermissionInput from "../../form/PermissionInput";
import {UserPermissionResponseEntityEnum} from "../../../generated/models/UserPermissionResponse";
import {PermissionAction} from "../../../generated/models/PermissionAction";
import ApiKeyInput from "../../form/ApiKeyInput";
import {UserResponse} from "../../../generated/models/UserResponse";
import {ApiKeyResponse} from "../../../generated/models/ApiKeyResponse";
import {authAdapter} from "../../../adapters/interfaces";
import {CreateUserPermissionRequestEntityEnum} from "../../../generated/models/CreateUserPermissionRequest";
import {CreateApiKeyPermissionRequestEntityEnum} from "../../../generated/models/CreateApiKeyPermissionRequest";
import {ServiceEndpoint} from "../../../generated/models/ServiceEndpoint";
import {AddSharp, DeleteSharp, EditOffSharp, ExpandMoreSharp} from "@mui/icons-material";
import {ServiceVariable} from "../../../generated/models/ServiceVariable";
import {ServiceFile} from "../../../generated/models/ServiceFile";
import {ServiceMount} from "../../../generated/models/ServiceMount";
import ServiceSpecificationInfo from "./ServiceSpecificationInfo";
import {TenantSettingAdapter} from "../../../adapters/TenantSettingAdapter";
import ServiceSpecificationConfigFileInfo from "./ServiceSpecificationConfigFileInfo";
import DeleteDialog from "../../dialog/DeleteDialog";
import {base64DecodeUtf8Safe, base64EncodeUtf8Safe} from "../../../misc/misc";

export interface Props {
    id: string
    onClose: () => void
    onSubmitted?: (response: ServiceResponse) => void
    serviceAdapter: ServiceAdapter
    tagAdapter: TagAdapter
    tenantSettingsAdapter: TenantSettingAdapter,
    authAdapter: AuthAdapter
    login: LoginHolder | null
    tenant: TenantResponse | null
    tags: TagResponse[]
    users: UserResponse[]
    apiKeys: ApiKeyResponse[]
    service?: ServiceResponse
    currency: string
}

export default function (props: Props) {
    const GENERAL_STEP = 0
    const SPEC_GENERAL_STEP = 1
    const SPEC_CONFIG_STEP = 2
    const PERMISSIONS_STEP = 3
    const REVIEW_STEP = 4
    const theme = useTheme()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [generalError, setGeneralError] = useState<string | null>(null)
    const [nameError, setNameError] = useState<string | null>(null)
    const [aliasError, setAliasError] = useState<string | null>(null)
    const [costsPerRequestError, setCostsPerRequestError] = useState<string | null>(null)
    const [referenceCostsPerRequestError, setReferenceCostsPerRequestError] = useState<string | null>(null)
    const [tagsError, setTagsError] = useState<string | null>(null)
    const [specGeneralError, setSpecGeneralError] = useState<string | null>(null)
    const [specConfigError, setSpecConfigError] = useState<string | null>(null)
    const [specConfigFileExpanded, setSpecConfigFileExpanded] = useState<string | false>(false)
    const [specConfigFileNewName, setSpecConfigFileNewName] = useState<string>("")
    const [specConfigFileNewNameErr, setSpecConfigFileNewNameErr] = useState<string | null>(null)
    const [specConfigFiles, setSpecConfigFiles] = useState<Record<string, {
        ref: RefObject<any>,
        content: string
    }>>((props.service?.specification?.environment?.configFiles || []).reduce((result, value) => ({
        ...result,
        [value.name]: {ref: createRef<any>(), content: value.content}
    }), {}))
    const [specConfigFileDeleteDialogOpen, setSpecConfigFileDeleteDialogOpen] = useState<string | boolean>(false)
    const [reviewError, setReviewError] = useState<string | null>(null)
    const [activeStep, setActiveStep] = useState<number>(GENERAL_STEP)
    const [completedSteps, setCompletedSteps] = useState<{ [k: number]: boolean; }>({})
    const [formData, setFormData] = useState<any>({
        name: props.service?.name,
        alias: props.service?.alias,
        description: props.service?.description,
        costsPerRequest: props.service?.costsPerRequest,
        referenceCostsPerRequest: props.service?.referenceCostsPerRequest,
        specification: props.service?.specification,
    })

    const nameRef = createRef<any>()
    const aliasRef = createRef<any>()
    const descriptionRef = createRef<any>()
    const costsPerRequestRef = createRef<any>()
    const referenceCostsPerRequestRef = createRef<any>()
    const tagsRef = createRef<any>()
    const specRef = createRef<any>()
    const userPermissionUsersRef = createRef<any>()
    const userPermissionPermissionsRef = createRef<any>()
    const apiKeyPermissionApiKeysRef = createRef<any>()
    const apiKeyPermissionPermissionsRef = createRef<any>()
    const [editorMarkers, setEditorMarkers] = useState<CodeEditorMarker[]>([])

    const changeStep = (currentStep: number, newStep: number): void => {
        switch (currentStep) {
            case GENERAL_STEP:
                collectGeneralFormData()
                break
            case SPEC_GENERAL_STEP:
                collectSpecGeneralFormData()
                break
            case SPEC_CONFIG_STEP:
                collectSpecConfigFormData()
                break
            case PERMISSIONS_STEP:
                collectPermissionsFormData()
                break
        }
        setActiveStep(newStep)
    }
    const collectGeneralFormData = (): void => {
        const newCompletedSteps = completedSteps
        newCompletedSteps[GENERAL_STEP] = false
        setCompletedSteps(newCompletedSteps)

        let nameErr = null
        let aliasErr = null
        let tagsErr = null
        let costsPerRequestErr = null
        let referenceCostsPerRequestErr = null
        let generalErr = null

        const name = nameRef.current.getValue()?.trim()
        if (!name) nameErr = "Name must be specified."
        const alias = aliasRef.current.getValue()
        if (!alias) aliasErr = "Alias must be specified."
        if (!new RegExp(ALIAS_REGEX).test(alias)) aliasErr = `Alias must match pattern ${ALIAS_REGEX}.`
        const description = descriptionRef.current.getValue()?.trim()
        const costs = costsPerRequestRef.current.getValue()
        const costsParsed = costs ? parseFloat(costs) : null
        if (costsParsed !== null && isNaN(costsParsed)) costsPerRequestErr = "Invalid number."
        const refCosts = referenceCostsPerRequestRef.current.getValue()
        const refCostsParsed = refCosts ? parseFloat(refCosts) : null
        if (refCostsParsed !== null && isNaN(refCostsParsed)) referenceCostsPerRequestErr = "Invalid number."
        const tags = tagsRef.current.getValue()
        tagsErr = tagsRef.current.getError()

        if (nameErr || aliasErr || tagsErr || costsPerRequestErr || referenceCostsPerRequestErr) {
            generalErr = "Invalid input"
        }

        setNameError(nameErr)
        setAliasError(aliasErr)
        setCostsPerRequestError(costsPerRequestErr)
        setReferenceCostsPerRequestError(referenceCostsPerRequestErr)
        setTagsError(tagsErr)
        setGeneralError(generalErr)
        setFormData({
            ...formData,
            name,
            alias,
            description,
            costsPerRequest: costsParsed,
            referenceCostsPerRequest: refCostsParsed,
            tags,
        })
        if (!generalErr) {
            const newCompletedSteps = completedSteps
            newCompletedSteps[GENERAL_STEP] = true
            setCompletedSteps(newCompletedSteps)
        }
    }
    const specForCodeEditor = (spec: ServiceSpecification): string => {
        let newSpec: any = {...spec}
        if (newSpec.environment?.hasOwnProperty("configFiles")) {
            delete newSpec.environment.configFiles
        }
        return JSON.stringify(newSpec, null, 2)
    }
    const collectSpecGeneralFormData = (): void => {
        const newCompletedSteps = completedSteps
        newCompletedSteps[SPEC_GENERAL_STEP] = true
        setCompletedSteps(newCompletedSteps)

        let specErr = null

        const errorMarkers = editorMarkers.filter(m => m.severity === CodeEditorMarkerSeverity.Error)
        if (errorMarkers.length > 0) {
            if (errorMarkers.length === 1) {
                specErr = `Please fix the error at line ${errorMarkers[0].endLineNumber}.`
            } else {
                specErr = `Please fix all ${errorMarkers.length} errors at lines ${errorMarkers.map(m => m.endLineNumber).join(", ")}.`
            }
        }

        setSpecGeneralError(specErr)
        let specification = null
        if (specRef.current) {
            specification = JSON.parse(specRef.current.getEditorValue())
            specification.environment.variables = specification.environment.variables.sort((a: any, b: any) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            })
        } else {
            specification = {}
        }
        setFormData({
            ...formData,
            specification,
        })
        if (!specErr) {
            const newCompletedSteps = completedSteps
            newCompletedSteps[SPEC_GENERAL_STEP] = true
            setCompletedSteps(newCompletedSteps)
        }
    }
    const expandConfigFile = (specConfigFile: string) => (event: SyntheticEvent, isExpanded: boolean) => {
        setSpecConfigFileExpanded(isExpanded ? specConfigFile : false)
    }
    const collectSpecConfigFormData = (): void => {
        const newCompletedSteps = completedSteps
        newCompletedSteps[SPEC_CONFIG_STEP] = true
        setCompletedSteps(newCompletedSteps)

        let configErr = null
        let configFiles: ServiceFile[] = []
        let configFilesTooLong = []
        Object.keys(specConfigFiles).forEach(name => {
            const fileContent = specConfigFiles[name].ref.current.getEditorValue()
            specConfigFiles[name].content = fileContent
            if (name.length + fileContent.length > 1000000) {
                configFilesTooLong.push(name)
            }
            configFiles.push({name, content: base64EncodeUtf8Safe(fileContent)})
        })
        if (configFilesTooLong.length > 0) {
            configErr = `Content config files ${configFiles} are too long. Only 1000000 characters are allowed per file.`
            setSpecConfigError(configErr)
        }
        setFormData({
            ...formData,
            configFiles,
        })
        if (!configErr) {
            const newCompletedSteps = completedSteps
            newCompletedSteps[SPEC_CONFIG_STEP] = true
            setCompletedSteps(newCompletedSteps)
        }
    }
    const collectPermissionsFormData = (): void => {
        const newCompletedSteps = completedSteps
        newCompletedSteps[PERMISSIONS_STEP] = false
        setCompletedSteps(newCompletedSteps)

        const userPermissionUsers = userPermissionUsersRef.current.getValue()
        const userPermissionPermissions = userPermissionPermissionsRef.current.getValue()
        const apiKeyPermissionApiKeys = apiKeyPermissionApiKeysRef.current.getValue()
        const apiKeyPermissionPermissions = apiKeyPermissionPermissionsRef.current.getValue()
        setFormData({
            ...formData,
            userPermissionUsers,
            userPermissionPermissions,
            apiKeyPermissionApiKeys,
            apiKeyPermissionPermissions,
        })

        const finishedCompletedSteps = completedSteps
        finishedCompletedSteps[PERMISSIONS_STEP] = true
        setCompletedSteps(finishedCompletedSteps)
    }
    const submit = (): void => {
        setLoading(true)

        const service: CreateServiceRequest = {
            enabled: false,
            tenantId: props.tenant!.id,
            alias: formData.alias,
            name: formData.name,
            description: formData.description,
            costsPerRequest: formData.costsPerRequest,
            referenceCostsPerRequest: formData.referenceCostsPerRequest,
            specification: formData.specification,
        }
        service.specification.environment["configFiles"] = formData.configFiles

        const tagsToCreate: CreateTagRequest[] = formData.tags?.filter((t: TenantResponse) => t.name.startsWith("Add \""))?.map((t: TenantResponse) => {
            return {tenantId: props.tenant!.id, name: t.id}
        }) || []
        props.serviceAdapter.createOne(props.login, service)
                .then(createdServiceResponse => {
                    console.debug("Created service: ", createdServiceResponse)
                    return (tagsToCreate.length > 0 ? props.tagAdapter.create(props.login, tagsToCreate) : Promise.resolve([]))
                            .then(createdTagsResponse => {
                                console.debug("Created tags: ", createdTagsResponse)
                                if (formData.tags && formData.tags.length > 0) {
                                    const tagsToAdd: TagResponse[] = createdTagsResponse.concat(formData.tags.filter((t: TagResponse) => !t.name.startsWith("Add \"")))
                                    return props.serviceAdapter.addTags(props.login, CreateTagServicesRequestFromJSON({
                                        elements: tagsToAdd.map(t => ({
                                            tenantId: props.tenant!.id,
                                            tagId: t.id,
                                            serviceId: createdServiceResponse.id,
                                        }))
                                    })).then(addedTagsResponse => {
                                        console.debug(`Added ${addedTagsResponse.elements.length} tags to service`)
                                        return addedTagsResponse.elements
                                    })
                                } else return Promise.resolve([])
                            })
                            .then(() => {
                                let permissionPromises = []
                                if (formData.userPermissionUsers && formData.userPermissionUsers.length > 0) {
                                    const permissions = formData.userPermissionUsers.map((u: UserResponse) => {
                                        return {
                                            tenantId: props.tenant!.id,
                                            userId: u.id,
                                            entity: CreateUserPermissionRequestEntityEnum.Service,
                                            actions: formData.userPermissionPermissions,
                                            resourceId: createdServiceResponse.id,
                                        }
                                    })
                                    const promise = authAdapter.createUserPermissions(props.login, {elements: permissions}).then(r => r.elements)
                                    permissionPromises.push(promise)
                                } else permissionPromises.push(Promise.resolve([]))
                                if (formData.apiKeyPermissionApiKeys && formData.apiKeyPermissionApiKeys.length > 0) {
                                    const permissions = formData.apiKeyPermissionApiKeys.map((ak: ApiKeyResponse) => {
                                        return {
                                            tenantId: props.tenant!.id,
                                            apiKeyId: ak.id,
                                            entity: CreateApiKeyPermissionRequestEntityEnum.Service,
                                            actions: formData.apiKeyPermissionPermissions,
                                            resourceId: createdServiceResponse.id,
                                        }
                                    })
                                    const promise = authAdapter.createApiKeyPermissions(props.login, {elements: permissions}).then(r => r.elements)
                                    permissionPromises.push(promise)
                                } else permissionPromises.push(Promise.resolve([]))
                                return Promise.all(permissionPromises).then(responses => {
                                    const userPermissionResponse = responses[0]
                                    console.debug(`Added ${userPermissionResponse.length} user permissions to service`)
                                    const apiKeyPermissionResponse = responses[1]
                                    console.debug(`Added ${apiKeyPermissionResponse.length} api key permissions to service`)
                                })
                            })
                            .then(() => createdServiceResponse)
                })
                .then(createdServiceResponse => {
                    if (props.onSubmitted) props.onSubmitted(createdServiceResponse)
                    props.onClose()
                })
                .catch(error => {
                    if (error.message.startsWith("Invalid service specification")) {
                        setSpecGeneralError(error.message)
                    } else {
                        setReviewError(error.message)
                    }
                })
                .finally(() => setLoading(false))
    }

    const generalStep = (<Box id={`${props.id}-step-${GENERAL_STEP}`} mt="16px">
        <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
                <TextInput id={`${props.id}-name`} label="Name" name="name"
                           value={formData.name} error={nameError} maxLength={50}
                           ref={nameRef} helpMessage="The name of the service."/>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextInput id={`${props.id}-alias`} label="Alias" name="alias" pattern={ALIAS_REGEX}
                           value={formData.alias} error={aliasError}
                           ref={aliasRef} maxLength={16}
                           helpMessage="The alias for the service. Must be a valid DNS name and cannot be changed later."/>
            </Grid>
            <Grid item xs={12}>
                <TextInput id={`${props.id}-description`} label="Description" name="description"
                           value={formData.description} maxLength={100}
                           ref={descriptionRef} required={false} helpMessage="The description of the service."
                           multiline={true}/>
            </Grid>
            <Grid item xs={6}>
                <TextInput id={`${props.id}-costs-per-request`} label="Costs per request" name="costs"
                           value={formData.costsPerRequest} pattern={DECIMAL_REGEX}
                           error={costsPerRequestError} maxLength={null}
                           ref={costsPerRequestRef} required={false}
                           helpMessage="The costs per request in credits."
                           start="¢"/>
            </Grid>
            <Grid item xs={6}>
                <TextInput id={`${props.id}-ref-costs-per-request`} label="Reference costs per request" name="refcosts"
                           value={formData.referenceCostsPerRequest} pattern={DECIMAL_REGEX}
                           error={referenceCostsPerRequestError} maxLength={null}
                           ref={referenceCostsPerRequestRef} required={false}
                           helpMessage={`The reference costs per request in ${props.currency}. Used for calculating the return on invest (ROI).`}
                           start={props.currency}/>
            </Grid>
            <Grid item xs={12}>
                <TagInput id={`${props.id}-tags`}
                          value={formData.tags || undefined} error={tagsError}
                          ref={tagsRef} creatable
                          options={props.tags || []}
                />
            </Grid>
        </Grid>
        <Box sx={{display: `flex`, flexDirection: `row`, pt: 2}}>
            <Box sx={{flex: `1 1 auto`}}/>
            <Button id={`${props.id}-cancel`} color="primary" onClick={props.onClose} sx={{mr: 1}}>Cancel</Button>
            <Button id={`${props.id}-cancel`} color="primary" variant="contained"
                    onClick={() => changeStep(GENERAL_STEP, SPEC_GENERAL_STEP)}>Next</Button>
        </Box>
    </Box>)
    const specificationGeneralStep = (<Box id={`${props.id}-step-${SPEC_GENERAL_STEP}`} mt="16px">
        <ServiceSpecificationInfo id={props.id} tenantSettingsAdapter={props.tenantSettingsAdapter}/>
        <CodeEditor id={`${props.id}-editor`}
                    name="specification"
                    width="100%" height="70vh"
                    ref={specRef}
                    language="json"
                    schemas={[serviceSpecificationSchema]}
                    value={specForCodeEditor(formData.specification ? formData.specification : emptyService.specification)}
                    onChangeMarkers={m => setEditorMarkers(m)}/>
        <Box sx={{display: `flex`, flexDirection: `row`, pt: 2}}>
            <Button id={`${props.id}-back`} color="primary"
                    onClick={() => changeStep(SPEC_GENERAL_STEP, GENERAL_STEP)}>Back</Button>
            <Box sx={{flex: `1 1 auto`}}/>
            <Button id={`${props.id}-cancel`} color="primary" onClick={props.onClose} sx={{mr: 1}}>Cancel</Button>
            <Button id={`${props.id}-cancel`} color="primary" variant="contained"
                    onClick={() => changeStep(SPEC_GENERAL_STEP, SPEC_CONFIG_STEP)}>Next</Button>
        </Box>
    </Box>)
    const specificationConfigStep = (<Box id={`${props.id}-step-${SPEC_CONFIG_STEP}`} mt="16px">
        <FormControl variant="outlined" size="small" error={specConfigFileNewNameErr != null}>
            <InputLabel htmlFor="new-file-input">New file</InputLabel>
            <OutlinedInput id="new-file-input"
                           label="New file"
                           value={specConfigFileNewName}
                           onChange={(event: ChangeEvent<HTMLInputElement>) => {
                               setSpecConfigFileNewNameErr(null)
                               setSpecConfigFileNewName(event.target.value)
                           }}
                           endAdornment={<>
                               <InputAdornment position="end">
                                   <IconButton edge="end" disabled={specConfigFileNewNameErr != null}
                                               onClick={() => {
                                                   if (!new RegExp(FILE_PATH_REGEX).test(specConfigFileNewName)) {
                                                       setSpecConfigFileNewNameErr("Invalid file name.")
                                                       return
                                                   }
                                                   if (Object.keys(specConfigFiles).includes(specConfigFileNewName)) {
                                                       setSpecConfigFileNewNameErr("File already exists.")
                                                       return
                                                   }
                                                   setSpecConfigFiles({
                                                       ...specConfigFiles,
                                                       [specConfigFileNewName]: {ref: createRef<any>(), content: ""}
                                                   })
                                                   setSpecConfigFileExpanded(specConfigFileNewName)
                                                   setSpecConfigFileNewName("")
                                               }}><AddSharp/></IconButton>
                               </InputAdornment>
                               <InputAdornment position="end">
                                   <ServiceSpecificationConfigFileInfo id={props.id}/>
                               </InputAdornment>
                           </>}
                           inputProps={{pattern: FILE_PATH_REGEX}}
            />
            <FormHelperText>{specConfigFileNewNameErr}</FormHelperText>
        </FormControl>
        {Object.keys(specConfigFiles).map(name => {
            const ref = specConfigFiles[name].ref
            const initialContent = specConfigFiles[name].content
            const fileNameSanitized = name.replace("/", "-").replace(".", "-")
            const fileExtension = name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2)
            return (
                    <Accordion key={fileNameSanitized}
                               expanded={specConfigFileExpanded === name}
                               onChange={expandConfigFile(name)}
                               variant="outlined"
                               sx={{marginBottom: "5px"}}>
                        <AccordionSummary expandIcon={<ExpandMoreSharp/>}>
                            <Typography sx={{width: `33%`, flexShrink: 0}}>{name}</Typography>
                            <Typography noWrap sx={{
                                display: specConfigFileExpanded === name ? `none` : `inherit`,
                                color: `text.secondary`,
                                fontFamily: `Fira Code, monospace`,
                            }}>{(ref.current?.getEditorValue() ? ref.current?.getEditorValue() : initialContent).substring(0, 100)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CodeEditor id={`${props.id}-editor-${fileNameSanitized}`}
                                        name={fileNameSanitized}
                                        width="100%" height="50vh"
                                        ref={specConfigFiles[name].ref}
                                        language={fileExtension}
                                        schemas={[]}
                                        value={initialContent}/>
                        </AccordionDetails>
                        <AccordionActions>
                            <IconButton onClick={() => {
                                if (!ref.current?.getEditorValue()) {
                                    const updatedConfigFiles = {
                                        ...specConfigFiles
                                    }
                                    delete updatedConfigFiles[name]
                                    setSpecConfigFiles(updatedConfigFiles)
                                } else {
                                    setSpecConfigFileDeleteDialogOpen(name)
                                }
                            }}><DeleteSharp/></IconButton>
                        </AccordionActions>
                    </Accordion>
            )
        })}
        <Box sx={{display: `flex`, flexDirection: `row`, pt: 2}}>
            <Button id={`${props.id}-back`} color="primary"
                    onClick={() => changeStep(SPEC_CONFIG_STEP, SPEC_GENERAL_STEP)}>Back</Button>
            <Box sx={{flex: `1 1 auto`}}/>
            <Button id={`${props.id}-cancel`} color="primary" onClick={props.onClose} sx={{mr: 1}}>Cancel</Button>
            <Button id={`${props.id}-cancel`} color="primary" variant="contained"
                    onClick={() => changeStep(SPEC_CONFIG_STEP, PERMISSIONS_STEP)}>Next</Button>
        </Box>
        <DeleteDialog title="Remove config file" errorTitle="Deletion failed." error={null}
                      open={specConfigFileDeleteDialogOpen != false} onClose={() => {
            setSpecConfigFileDeleteDialogOpen(false)
        }} onSubmit={() => {
            const updatedConfigFiles = {
                ...specConfigFiles
            }
            delete updatedConfigFiles[specConfigFileDeleteDialogOpen.toString()]
            setSpecConfigFiles(updatedConfigFiles)
            setSpecConfigFileDeleteDialogOpen(false)
        }} fullScreen={false} isLoading={false}>
            <span>Do you really want to delete file <strong>{specConfigFileDeleteDialogOpen}</strong>?</span>
        </DeleteDialog>
    </Box>)
    const permissionsStep = (<Box id={`${props.id}-step-${PERMISSIONS_STEP}`} mt="16px">
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <UserInput id={`${props.id}-users`}
                           ref={userPermissionUsersRef}
                           label="Users"
                           name="users"
                           options={props.users}/>
            </Grid>
            <Grid item xs={4}>
                <PermissionInput id={`${props.id}-permissions`}
                                 ref={userPermissionPermissionsRef}
                                 label="Permissions"
                                 name="permissions"
                                 entity={UserPermissionResponseEntityEnum.Service}
                                 options={[PermissionAction.Read, PermissionAction.Update, PermissionAction.Delete, PermissionAction.Execute]}/>
            </Grid>
            <Grid item xs={8}>
                <ApiKeyInput id={`${props.id}-apikeys`}
                             ref={apiKeyPermissionApiKeysRef}
                             label="Api keys"
                             name="apikeys"
                             options={props.apiKeys}/>
            </Grid>
            <Grid item xs={4}>
                <PermissionInput id={`${props.id}-permissions`}
                                 ref={apiKeyPermissionPermissionsRef}
                                 label="Permissions"
                                 name="permissions"
                                 entity={UserPermissionResponseEntityEnum.Service}
                                 options={[PermissionAction.Read, PermissionAction.Update, PermissionAction.Delete, PermissionAction.Execute]}/>
            </Grid>
        </Grid>
        <Box sx={{display: `flex`, flexDirection: `row`, pt: 2}}>
            <Button id={`${props.id}-back`} color="primary"
                    onClick={() => changeStep(PERMISSIONS_STEP, SPEC_CONFIG_STEP)}>Back</Button>
            <Box sx={{flex: `1 1 auto`}}/>
            <Button id={`${props.id}-cancel`} color="primary" onClick={props.onClose} sx={{mr: 1}}>Cancel</Button>
            <Button id={`${props.id}-cancel`} color="primary" variant="contained"
                    onClick={() => changeStep(PERMISSIONS_STEP, REVIEW_STEP)}>Next</Button>
        </Box>
    </Box>)
    const reviewStep = (<Box id={`${props.id}-step-${REVIEW_STEP}`} mt="16px">
        {reviewError ? (<Alert severity="error">
            <AlertTitle>Error occurred</AlertTitle>
            {reviewError}
        </Alert>) : null}
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
                <Typography color="text.secondary">Name</Typography>
                {formData.name || "-"}
                <Typography color="text.secondary">Alias</Typography>
                {formData.alias || "-"}
                <Typography color="text.secondary">Description</Typography>
                {formData.description || "-"}
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <Typography color="text.secondary">Tags</Typography>
                {formData.tags?.map((tag: TagResponse) => (<><Chip label={tag.name}/><span> </span></>))}
                <Typography color="text.secondary">Image</Typography>
                <Box fontFamily="Fira Code, monospace">{formData.specification?.image?.name}:{formData.specification?.image?.tag}</Box>
                <Typography color="text.secondary">Replicas</Typography>
                <Box fontFamily="Fira Code, monospace">{formData.specification?.resources?.replicas}</Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <Typography color="text.secondary">Endpoints</Typography>
                <List dense sx={{paddingTop: 0, paddingBottom: 0}}>
                    {formData.specification?.endpoints?.map((endpoint: ServiceEndpoint, i: number) => {
                        return (<ListItem key={i} disableGutters sx={{paddingTop: 0, paddingBottom: 0}}>
                            <ListItemText primary={<Box
                                    fontFamily="Fira Code, monospace">{endpoint.port} {endpoint.pathPrefix || ""}{endpoint.path || ""} {endpoint.protocol} ({endpoint.type})</Box>}/>
                        </ListItem>)
                    })}
                </List>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <Typography color="text.secondary">Environment variables</Typography>
                {formData.specification?.environment?.variables ? (formData.specification?.environment?.variables)?.map((it: ServiceVariable) => (
                        <Box key={it.name}
                             fontFamily="Fira Code, monospace">{`${it.name}=${it.encrypted ? "***" : it.value}`}</Box>)) : "-"}
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <Typography color="text.secondary">Configuration</Typography>
                {formData.configFiles ? formData.configFiles.map((it: ServiceFile) => (
                        <Box key={it.name}>
                            <Typography>{it.name}</Typography>
                            <Box fontFamily="Fira Code, monospace"
                                 fontSize="10px">{base64DecodeUtf8Safe(it.content)}</Box>
                        </Box>)) : "-"}
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <Typography color="text.secondary">Volume mounts</Typography>
                {formData.specification?.environment?.mounts ? (formData.specification?.environment?.mounts)?.map((it: ServiceMount) => (
                        <Box key={it.volumeName}>
                            <Typography
                                    fontFamily="Fira Code, monospace">{it.mountPath} {it.readOnly ? (
                                    <EditOffSharp
                                            sx={{fontSize: "inherit"}}/>) : null}</Typography>
                            <Typography>Volume: <Box component="span"
                                                     fontFamily="Fira Code, monospace">{it.volumeName}</Box></Typography>
                            <Typography>Sub path: <Box component="span"
                                                       fontFamily="Fira Code, monospace">{it.volumeSubPath || "-"}</Box></Typography>
                        </Box>)) : "-"}
            </Grid>
        </Grid>
        <Box sx={{display: `flex`, flexDirection: `row`, pt: 2}}>
            <Button id={`${props.id}-back`} color="primary"
                    onClick={() => changeStep(REVIEW_STEP, PERMISSIONS_STEP)}>Back</Button>
            <Box sx={{flex: `1 1 auto`}}/>
            <Button id={`${props.id}-cancel`} color="primary" onClick={props.onClose} sx={{mr: 1}}>Cancel</Button>
            <LoadingButton id={`${props.id}-submit`} color="primary" variant="contained"
                           disabled={generalError != null || !completedSteps[GENERAL_STEP] || specGeneralError != null || !completedSteps[SPEC_GENERAL_STEP] || !completedSteps[PERMISSIONS_STEP]}
                           loading={isLoading} onClick={submit}>Create</LoadingButton>
        </Box>
    </Box>)

    return (<Grid item xs={12}>
        <Stepper nonLinear activeStep={activeStep}>
            <Step completed={!generalError && completedSteps[GENERAL_STEP]}>
                <StepLabel id={`${props.id}-step-general`}
                           sx={{cursor: "pointer"}}
                           error={generalError !== null}
                           optional={(<Typography variant="caption"
                                                  color="error">{generalError?.substring(0, 100)}</Typography>)}
                           onClick={() => changeStep(activeStep, GENERAL_STEP)}>General</StepLabel>
            </Step>
            <Step completed={!specGeneralError && completedSteps[SPEC_GENERAL_STEP]}>
                <StepLabel id={`${props.id}-step-spec-general`}
                           sx={{cursor: "pointer"}}
                           error={specGeneralError !== null}
                           optional={(<Typography variant="caption"
                                                  color="error">{specGeneralError?.substring(0, 100)}</Typography>)}
                           onClick={() => changeStep(activeStep, SPEC_GENERAL_STEP)}>Specification</StepLabel>
            </Step>
            <Step completed={!specConfigError && completedSteps[SPEC_CONFIG_STEP]}>
                <StepLabel id={`${props.id}-step-spec-config`}
                           sx={{cursor: "pointer"}}
                           error={specConfigError !== null}
                           optional={(<Typography variant="caption"
                                                  color="error">{specConfigError?.substring(0, 100)}</Typography>)}
                           onClick={() => changeStep(activeStep, SPEC_CONFIG_STEP)}>Configuration</StepLabel>
            </Step>
            <Step completed={completedSteps[PERMISSIONS_STEP]}>
                <StepLabel id={`${props.id}-step-permissions`}
                           sx={{cursor: "pointer"}}
                           onClick={() => changeStep(activeStep, PERMISSIONS_STEP)}>Permissions</StepLabel>
            </Step>
            <Step completed={!reviewError && completedSteps[REVIEW_STEP]}>
                <StepLabel id={`${props.id}-step-review`}
                           sx={{cursor: "pointer"}}
                           error={reviewError !== null}
                           optional={(<Typography variant="caption"
                                                  color="error">{reviewError?.substring(0, 100)}</Typography>)}
                           onClick={() => changeStep(activeStep, REVIEW_STEP)}>Review</StepLabel>
            </Step>
        </Stepper>
        {activeStep === GENERAL_STEP ? (generalStep) : null}
        {activeStep === SPEC_GENERAL_STEP ? (specificationGeneralStep) : null}
        {activeStep === SPEC_CONFIG_STEP ? (specificationConfigStep) : null}
        {activeStep === PERMISSIONS_STEP ? (permissionsStep) : null}
        {activeStep === REVIEW_STEP ? (reviewStep) : null}
    </Grid>)
}
