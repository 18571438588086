import {IconButton, styled} from "@mui/material";
import {LoadingButton, LoadingButtonProps} from "@mui/lab";
import {IconButtonProps} from "material-ui";

export const WarningButton = styled(LoadingButton)<LoadingButtonProps>(({theme}) => ({
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
        backgroundColor: theme.palette.warning.dark,
    },
}))

export const MenuButton = styled(IconButton)<IconButtonProps>(() => ({
    fontSize: 18,
    fontFamily: 'Metropolis',
    fontWeight: 'bold',
    padding: '0',
}))
