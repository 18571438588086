import {Box, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import Info from "../../Info";
import {TenantSettingAdapter, TenantSettingDefinition} from "../../../adapters/TenantSettingAdapter";
import {LoginContext} from "../../provider/LoginProvider";
import {TenantContext} from "../../provider/TenantProvider";
import {LABEL_REGEX} from "./ServiceModel";

const LABEL_EXTRACT_REGEX = new RegExp(LABEL_REGEX)

export interface Props {
    id: string
    tenantSettingsAdapter: TenantSettingAdapter
}

export default function (props: Props) {
    const {login} = useContext(LoginContext)
    const {tenant} = useContext(TenantContext)
    const [serviceAvailableRunSelectors, setServiceAvailableRunSelectors] = useState<Record<string, string[]>>({})
    const [serviceAvailableRunRestrictions, setServiceAvailableRunRestrictions] = useState<Record<string, string[]>>({})

    const parseAvailableRunSelectorsOrRestrictions: (value: string) => Record<string, string[]> = value => {
        const parsed: Record<string, string[]> = {}
        value.split(",").forEach(v => {
            const match = LABEL_EXTRACT_REGEX.exec(v)
            if (match) {
                const name = match[1]
                const value = match[2]
                if (Object.keys(parsed).includes(name)) {
                    parsed[name] = (parsed[name] || []).concat([value])
                } else {
                    parsed[name] = [value]
                }
            }
        })
        return parsed
    }

    useEffect(() => {
        if (login && tenant) {
            props.tenantSettingsAdapter.getValue(login, {
                tenantId: tenant.tenant.id,
                name: TenantSettingDefinition[TenantSettingDefinition.SERVICE_AVAILABLE_RUN_SELECTORS],
            })
                    .then(value => {
                        if (value != null) {
                            setServiceAvailableRunSelectors(parseAvailableRunSelectorsOrRestrictions(value))
                        }
                    })
                    .catch(error => console.error("Unable to get available run selectors", error.message))
            props.tenantSettingsAdapter.getValue(login, {
                tenantId: tenant.tenant.id,
                name: TenantSettingDefinition[TenantSettingDefinition.SERVICE_AVAILABLE_RUN_RESTRICTIONS],
            })
                    .then(value => {
                        if (value != null) {
                            setServiceAvailableRunRestrictions(parseAvailableRunSelectorsOrRestrictions(value))
                        }
                    })
                    .catch(error => console.error("Unable to get available run restrictions", error.message))
        }
    }, [login, tenant])

    return (<Info id={`${props.id}-info`} title="Service specification hints" buttonText="OK">
        <Typography variant="h3">On-demand services</Typography>
        <Typography variant="body1">
            When enabling the on-demand flag in a service (in the resources section), please make sure the service is
            actually capable of it. This means the service must start (and stop) in rather short time (less than 30 seconds),
            otherwise the first request will fail.
        </Typography>
        <Typography variant="body1">
            AIOS handles the starting and stopping of a service if there are actual requests to the service. If there are
            no requests for the service, the service will be down for as long as the first request is recognized.
        </Typography>
        {Object.keys(serviceAvailableRunSelectors).length > 0 || Object.keys(serviceAvailableRunRestrictions).length > 0 ? (
                <Typography variant="h3">Run selectors and restrictions</Typography>) : null}
        {Object.keys(serviceAvailableRunSelectors).length > 0 ? (<>
            <Typography variant="h4">Available run selectors</Typography>
            <Typography variant="body1">
                The following run selectors can be used:
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Values</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.keys(serviceAvailableRunSelectors).map(name => (<TableRow key={name}>
                                <TableCell sx={{fontFamily: "Fira Code, monospace"}}>{name}</TableCell>
                                <TableCell
                                        sx={{fontFamily: "Fira Code, monospace"}}>{serviceAvailableRunSelectors[name].join(", ")}</TableCell>
                            </TableRow>))
                        }
                    </TableBody>
                </Table>
            </Typography>
        </>) : null}

        {Object.keys(serviceAvailableRunRestrictions).length > 0 ? (<>
            <Typography variant="h4">Available run restrictions</Typography>
            <Typography variant="body1" gutterBottom>
                The following run restrictions can be used:
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Values</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.keys(serviceAvailableRunRestrictions).map(name => (<TableRow key={name}>
                                <TableCell sx={{fontFamily: "Fira Code, monospace"}}>{name}</TableCell>
                                <TableCell
                                        sx={{fontFamily: "Fira Code, monospace"}}>{serviceAvailableRunRestrictions[name].join(", ")}</TableCell>
                            </TableRow>))
                        }
                    </TableBody>
                </Table>
            </Typography>
        </>) : null}

        <Typography variant="h3">Variables</Typography>
        <Typography variant="h4">Deploy time variables</Typography>
        <Typography variant="body1">
            The following variables can be used in the
            specification and are substituted at deploy time:
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Variable</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        [
                            {name: "ID", description: "The service identifier."},
                            {name: "ALIAS", description: "The alias of the service."},
                            {name: "NAME", description: "The name of the service."},
                            {name: "REPLICAS", description: "The replicas specified for the service."},
                            {name: "DOMAIN", description: "The domain specified for the service."},
                            {name: "IMAGE_NAME", description: "The name of the image of the service."},
                            {name: "IMAGE_TAG", description: "The tag of the image of the service."},
                        ].map((e, i) => (<TableRow key={e.name}>
                            <TableCell sx={{fontFamily: "Fira Code, monospace"}}>{e.name}</TableCell>
                            <TableCell>{e.description}</TableCell>
                        </TableRow>))
                    }
                </TableBody>
            </Table>
            Variables can be used by wrapping them in <Box component="span"
                                                           fontFamily="Fira Code, monospace">{`{{MY_VARIABLE}}`}</Box>.
        </Typography>
        <Typography variant="h4">Run time variables</Typography>
        <Typography variant="body1">
            Additionally, the following variables are available during runtime and can only be used when the container
            is running or if the value needs to be mapped to an other variable name:
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Variable</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        [
                            {name: "AIOS_IP", description: "The IP address of the container."},
                            {name: "AIOS_HOSTNAME", description: "The hostname of the container."},
                            {name: "AIOS_NAMESPACE", description: "The namespace of the container."},
                            {
                                name: "AIOS_NODE_NAME",
                                description: "The node name of the virtual or physical computer the container is running on."
                            },
                        ].map((e, i) => (<TableRow key={e.name}>
                            <TableCell sx={{fontFamily: "Fira Code, monospace"}}>{e.name}</TableCell>
                            <TableCell>{e.description}</TableCell>
                        </TableRow>))
                    }
                </TableBody>
            </Table>
        </Typography>
    </Info>)
}