import {Grid} from "@mui/material";
import React from "react";
import UserInput from "../../form/UserInput";
import {PermissionAction} from "../../../generated/models/PermissionAction";
import {UserResponse} from "../../../generated/models/UserResponse";
import PermissionInput from "../../form/PermissionInput";
import {UserPermissionResponseEntityEnum} from "../../../generated/models/UserPermissionResponse";

export interface Props {
    id: string
    users: UserResponse[]
    entity: UserPermissionResponseEntityEnum
    permissionOptions: PermissionAction[]

    userError: string | null
    permissionError: string | null
}

export default function (props: Props) {
    return (<>
        <Grid item xs={12}>
            <UserInput id={`${props.id}-users`}
                       label="Users"
                       name="users"
                       options={props.users}
                       error={props.userError}/>
        </Grid>
        <Grid item xs={12}>
            <PermissionInput id={`${props.id}-permissions`}
                             label="Permissions"
                             name="permissions"
                             entity={props.entity}
                             options={props.permissionOptions}
                             error={props.permissionError}/>
        </Grid>
    </>)
}
