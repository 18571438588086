import {AutoAwesomeSharp, MoreVertSharp} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {
    Button,
    ButtonGroup,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Skeleton,
    Typography,
    useTheme
} from "@mui/material"
import React, {useContext, useEffect, useState} from "react"
import {ServiceStatus, ServiceStatusStateEnum} from "../generated/models/ServiceStatus";
import ServiceStateIcon from "./ServiceStateIcon";
import SimpleDialog from "./dialog/SimpleDialog";
import moment from "moment";
import {serviceAdapter, UUID} from "../adapters/interfaces";
import {LoginContext} from "./provider/LoginProvider";
import ServiceReplicaStateIcon from "./ServiceReplicaStateIcon";
import {ServiceReplicaStatusStateEnum} from "../generated/models/ServiceReplicaStatus";
import {ServiceResponse} from "../generated/models/ServiceResponse";

export interface Props {
    serviceId: UUID
    service?: ServiceResponse
    status?: ServiceStatus
    size?: "small" | "medium"
}

export default function (props: Props) {
    const theme = useTheme()
    const {login} = useContext(LoginContext)
    const [isLoading, setIsLoading] = useState<boolean>(!props.status)
    const [isDetailDialogOpen, setIsDetailDialogOpen] = useState<boolean>(false)
    const [status, setStatus] = useState<ServiceStatus | null>(props.status || null)

    const loadStatus: () => void = () => {
        setIsLoading(true)
        serviceAdapter.status(login, props.serviceId)
                .then(status => setStatus(status))
                .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (!status) {
            loadStatus()
        }
    }, [])

    const statusColor = stateColor(status?.state || null);
    return (<>
                <ButtonGroup color={statusColor} variant="contained" size={props.size || "small"} disabled={isLoading}>
                    <LoadingButton variant="contained" size={props.size || "small"}
                                   title="Click to refresh status"
                                   startIcon={<ServiceStateIcon state={status?.state || null}/>}
                                   loadingPosition="start"
                                   loading={isLoading}
                                   onClick={loadStatus}>
                        {status ? props.service?.enabled && props.service?.specification?.resources?.isOnDemand ? (<>{status.state}<sup><AutoAwesomeSharp
                                sx={{width: "10px", height: "10px"}}/></sup></>) : status.state : (
                                <Skeleton sx={{width: "75px"}}/>)}
                    </LoadingButton>
                    <Button size={props.size || "small"} onClick={() => setIsDetailDialogOpen(true)}>
                        <MoreVertSharp/>
                    </Button>
                    {status ? (<SimpleDialog id={status.id} color={statusColor}
                                             title={`Details of ${status.state.toLowerCase()} status`}
                                             open={isDetailDialogOpen} onClose={() => setIsDetailDialogOpen(false)}>
                        <Typography>{status.message}</Typography>
                        <List dense>
                            {status.replicas.map(replica => {
                                const timestamp = moment(status.timestamp)
                                const replicaColor = stateReplicaColor(replica.state);
                                return (<ListItem key={replica.id} alignItems="flex-start">
                                    <ListItemIcon><ServiceReplicaStateIcon state={replica.state}
                                                                           color={replicaColor}
                                                                           useCircle={true}/></ListItemIcon>
                                    <ListItemText primary={<><Typography component="p" variant="body2"
                                                                         color={replicaColor === "inherit" ? "inherit" : theme.palette[replicaColor].dark}><strong>{replica.state}</strong></Typography>{replica.message}</>}
                                                  secondary={`v${replica.serviceVersion} ${timestamp.fromNow()} (${timestamp.local().format()})`}/>
                                </ListItem>)
                            })}
                        </List>
                    </SimpleDialog>) : null}
                </ButtonGroup>
            </>
    )
}


const stateColor: (state: ServiceStatusStateEnum | null) => "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" = (state) => {
    switch (state) {
        case ServiceStatusStateEnum.Pending:
            return "info"
        case ServiceStatusStateEnum.Initializing:
            return "info"
        case ServiceStatusStateEnum.Starting:
            return "info"
        case ServiceStatusStateEnum.Notready:
            return "warning"
        case ServiceStatusStateEnum.Running:
            return "success"
        case ServiceStatusStateEnum.Degraded:
            return "warning"
        case ServiceStatusStateEnum.Failed:
            return "error"
        case ServiceStatusStateEnum.Stopping:
            return "info"
        case ServiceStatusStateEnum.Stopped:
            return "inherit"
        default:
            return "inherit"
    }
}

const stateReplicaColor: (state: ServiceReplicaStatusStateEnum | null) => "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" = (state) => {
    switch (state) {
        case ServiceReplicaStatusStateEnum.Pending:
            return "info"
        case ServiceReplicaStatusStateEnum.Initializing:
            return "info"
        case ServiceReplicaStatusStateEnum.Starting:
            return "info"
        case ServiceReplicaStatusStateEnum.Notready:
            return "warning"
        case ServiceReplicaStatusStateEnum.Running:
            return "success"
        case ServiceReplicaStatusStateEnum.Failed:
            return "error"
        case ServiceReplicaStatusStateEnum.Stopping:
            return "info"
        case ServiceReplicaStatusStateEnum.Stopped:
            return "inherit"
        case ServiceReplicaStatusStateEnum.Unknown:
            return "inherit"
        default:
            return "inherit"
    }
}
