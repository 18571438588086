import {Card, CardProps, Grid, GridProps, Paper, PaperProps, styled} from "@mui/material";

export const FlatPaper = styled(Paper)<PaperProps>(() => ({
    boxShadow: 'none',
}))

export const FlatCard = styled(Card)<CardProps>(() => ({
    boxShadow: 'none',
}))

export const DrawerTitle = styled(Grid)<GridProps>(({theme}) => (theme.mixins.toolbar /*necessary for content to be below app bar*/ && {
    fontSize: 28,
    fontFamily: 'Metropolis',
    fontWeight: 'bold',
    lineHeight: '48px',
    minHeight: '48px',
    [theme.breakpoints.up('sm')]: {
        minHeight: '48px',
    }
}))
