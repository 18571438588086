import React, {useContext, useEffect, useState} from "react";
import {Alert, AlertTitle, IconButton, Tooltip} from "@mui/material";
import AuthenticatedLayout from "../../../components/layout/AuthenticatedLayout";
import {DataGrid, GridRowId, GridSortModel} from "@mui/x-data-grid";
import {BooleanParam, NumberParam, StringParam, useQueryParam} from "use-query-params";
import {useHotkeys} from "react-hotkeys-hook";
import {AddSharp, DeleteSharp, EditSharp, PersonAddSharp} from "@mui/icons-material";
import {permitsSuper} from "../../../misc/authorization";
import {assembleSort} from "../../../misc/misc";
import {LoginContext} from "../../provider/LoginProvider";
import {TenantContext} from "../../provider/TenantProvider";
import {UiConfigContext} from "../../provider/UiConfigProvider";
import {CsvArrayParam} from "../../../misc/CsvArrayParam";
import {Page, pageable, PageableRequest, PageResponse, tenantAdapter, userAdapter} from "../../../adapters/interfaces";
import {UserResponse} from "../../../generated/models/UserResponse";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import {RoleResponse} from "../../../generated/models/RoleResponse";
import {columns, emptyUser} from "./UserModel";
import {UserRoleResponse} from "../../../generated/models/UserRoleResponse";
import UserInviteDialog from "../../../components/view/user/UserInviteDialog";
import UserCreateDialog from "../../../components/view/user/UserCreateDialog";
import UserEditDialog from "../../../components/view/user/UserEditDialog";
import {FlatPaper} from "../../Misc";
import UserDeleteDialog from "./UserDeleteDialog";
import {apiGet} from "../../../misc/api";
import {RouteComponentProps} from "@reach/router";

export interface Props extends RouteComponentProps {
    title: string
    topic: string
    sort?: string
}

export default function (props: Props) {
    const SORT_DEFAULT: string = props.sort ? encodeURIComponent(props.sort) : encodeURIComponent("+email")

    const {uiConfig} = useContext(UiConfigContext)
    const {login} = useContext(LoginContext)
    const {tenant} = useContext(TenantContext)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [pageNumber = 0, setPageNumber] = useQueryParam("page", NumberParam)
    const [pageSize = 10, setPageSize] = useQueryParam("size", NumberParam)
    const [sort = SORT_DEFAULT, setSort] = useQueryParam("sort", new CsvArrayParam())
    const [passwordRegex, setPasswordRegex] = useState<string | null>(null)
    const [passwordHelp, setPasswordHelp] = useState<string | null>(null)
    const [id, setId] = useQueryParam("id", StringParam)
    const [initialSelectionSetFromId, setInitialSelectionSetFromId] = useState<boolean>(false)
    const [inviteDialogOpen, setInviteDialogOpen] = useQueryParam("invite", BooleanParam)
    const [createDialogOpen, setCreateDialogOpen] = useQueryParam("create", BooleanParam)
    const [editDialogOpen, setEditDialogOpen] = useQueryParam("edit", BooleanParam)
    const [deleteDialogOpen, setDeleteDialogOpen] = useQueryParam("delete", BooleanParam)
    const [elementsPage, setElementsPage] = useState<Page<UserResponse> | null>(null)
    const [rolesDefsPerTenant, setRolesDefsPerTenant] = useState<Record<string, RoleResponse[]> | null>()
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
    const [selectedElement, setSelectedElement] = useState<UserResponse | null>(null)
    const [selectedElements, setSelectedElements] = useState<UserResponse[]>([])
    const [selectedElementRoles, setSelectedElementRoles] = useState<UserRoleResponse[] | null>(null)
    const [selectedElementTenants, setSelectedElementTenants] = useState<TenantResponse[] | null>(null)
    const [tenants, setTenants] = useState<Page<TenantResponse> | null>(null)

    const load = (): void => {
        setLoading(true)
        userAdapter.find(login, pageable(pageNumber, pageSize, null, sort ? decodeURIComponent(sort.toString()) : null))
                .then(response => setElementsPage(response))
                .catch(error => setError(error.message))
                .finally(() => {
                    if (!initialSelectionSetFromId) {
                        console.debug("Setting selected row from id url param")
                        setSelectionModel(id ? [id] : [])
                        setInitialSelectionSetFromId(true)
                    }
                    setLoading(false)
                })
    }
    const loadRolesPerTenant = (tenantIds: string[]): void => {
        tenantIds.forEach(tenantId => {
            if (!rolesDefsPerTenant?.hasOwnProperty(tenantId)) {
                console.debug(`Loading role definitions for tenant ${tenantId}`)
                console.debug(`Role definitions now loaded: `, rolesDefsPerTenant)
                apiGet(login, `/roles/tenants/${tenantId}`).then((response: RoleResponse[]) => {
                    let roles: Record<string, RoleResponse[]> = {}
                    roles[tenantId] = response
                    roles = Object.assign(roles, rolesDefsPerTenant)
                    setRolesDefsPerTenant(roles)
                })
            } else {
                console.debug(`Role definitions for tenant ${tenantId} already available`)
            }
        })
    }

    const openInviteDialog = () => {
        tenantAdapter.find(login, new PageableRequest(0, 1000)).then(response => {
            setTenants(response)
            setInviteDialogOpen(true)
        })
    }
    const openCreateDialog = () => {
        tenantAdapter.find(login, new PageableRequest(0, 1000, null, decodeURIComponent("+name"))).then(response => {
            setTenants(response)
            setCreateDialogOpen(true)
        })
    }
    const openEditDialog = () => {
        const userId = selectedElement?.id
        userAdapter.findRoles(login, new PageableRequest(0, 1000, `userId==${userId}`))
                .then(response => {
                    setSelectedElementRoles(response.elements)
                    const tenantIds = [...new Set(response.elements.map(role => role.tenantId))]
                    if (!tenantIds) return new PageResponse<TenantResponse>()
                    loadRolesPerTenant(tenantIds)
                    return tenantAdapter.find(login, new PageableRequest(0, 1000, null, decodeURIComponent("+name")))
                })
                .then(response => {
                    setTenants(response)
                    return userAdapter.findTenants(login, new PageableRequest(0, 1000, `userId==${userId}`))
                })
                .then(response => {
                    if (response.totalElements == 0) {
                        return Promise.resolve(new PageResponse<TenantResponse>(0, 1000, 0, []))
                    } else {
                        return tenantAdapter.find(login, new PageableRequest(0, 1000, `id=in=(${response.elements.map(e => e.tenantId).join(",")})`))
                    }
                })
                .then(response => {
                    setSelectedElementTenants(response.elements)
                    setEditDialogOpen(true)
                })
    }

    useHotkeys('i', () => {
        document.getElementById('invite')!.click()
    })
    useHotkeys('a', () => {
        if (!uiConfig?.keycloakEnabled || permitsSuper(login))
            document.getElementById('add')!.click()
    })
    useHotkeys('e', () => {
        document.getElementById('edit')!.click()
    })
    useHotkeys('d,del', () => {
        document.getElementById('delete')!.click()
    })
    useEffect(() => { // load elements depending on the page params
        console.debug("Loading elements")
        load()
    }, [pageNumber, pageSize, sort])
    useEffect(() => {
        loadRolesPerTenant(tenant?.tenant?.id ? [tenant.tenant.id] : [])
    }, [tenant])
    useEffect(() => {
        loadRolesPerTenant(selectedElementTenants?.map(t => t.id) || [])
    }, [selectedElementTenants])
    useEffect(() => { // update state of selected element
        const element = selectionModel.length == 1 ? elementsPage?.elements.find(it => it.id === selectionModel[0]) || null : null
        console.debug("Setting selected element and id", element)
        setSelectedElement(element)
        const elements = elementsPage?.elements.filter(it => selectionModel.includes(it.id)) || []
        console.debug("Setting selected elements", elements)
        setSelectedElements(elements)
        setId(element?.id)
    }, [selectionModel, elementsPage])

    const buttonBar = (<>
                {uiConfig?.keycloakEnabled ? (<Tooltip title="Invite (i)" placement="bottom">
                    <span><IconButton id="invite" disabled={error != null}
                                      onClick={openInviteDialog}><PersonAddSharp/></IconButton></span>
                </Tooltip>) : null}
                {!uiConfig?.keycloakEnabled || permitsSuper(login) ? (
                        <Tooltip title="Add (a)" placement="bottom">
                            <span><IconButton id="add" disabled={error != null}
                                              onClick={openCreateDialog}><AddSharp/></IconButton></span>
                        </Tooltip>) : null}
                <Tooltip title="Edit (e)" placement="bottom">
                    <span><IconButton id="edit" disabled={error != null || selectionModel.length != 1}
                                      onClick={openEditDialog}><EditSharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Delete (d,del)" placement="bottom">
                    <span><IconButton id="delete" disabled={error != null || selectionModel.length < 1}
                                      onClick={() => setDeleteDialogOpen(true)}><DeleteSharp/></IconButton></span>
                </Tooltip>
                <UserInviteDialog id="invite-dialog"
                                  title={`Invite ${props.topic}`}
                                  open={inviteDialogOpen === undefined || inviteDialogOpen === null ? false : inviteDialogOpen}
                                  tenants={tenants?.elements || []}
                                  roleDefsPerTenant={rolesDefsPerTenant || {}}
                                  login={login}
                                  onTenantsChange={tenants => loadRolesPerTenant(tenants.map(t => t.id))}
                                  onSubmitted={() => {
                                      setInviteDialogOpen(false)
                                      load()
                                  }}
                                  onClose={() => setInviteDialogOpen(false)}
                                  adapter={userAdapter}
                />
                <UserCreateDialog id="create-dialog"
                                  title={`Create ${props.topic}`}
                                  open={createDialogOpen === undefined || createDialogOpen === null ? false : createDialogOpen}
                                  tenants={tenants?.elements || []}
                                  roleDefsPerTenant={rolesDefsPerTenant || {}}
                                  passwordRegex={passwordRegex}
                                  passwordHelp={passwordHelp}
                                  login={login}
                                  onTenantsChange={tenants => loadRolesPerTenant(tenants.map(t => t.id))}
                                  onSubmitted={() => {
                                      setCreateDialogOpen(false)
                                      load()
                                  }}
                                  onClose={() => setCreateDialogOpen(false)}
                                  adapter={userAdapter}
                />
                <UserEditDialog id="edit-dialog" title={`Edit ${props.topic}`}
                                open={editDialogOpen === undefined || editDialogOpen === null ? false : editDialogOpen}
                                element={selectedElement || emptyUser}
                                elementTenants={selectedElementTenants || []}
                                elementRoles={selectedElementRoles || []}
                                tenants={tenants?.elements||[]}
                                tenant={tenant!.tenant}
                                roleDefs={rolesDefsPerTenant ? rolesDefsPerTenant[tenant!.tenant.id] : [] || []}
                                login={login}
                                onTenantsChange={tenants => {
                                    setSelectedElementTenants(tenants)
                                    loadRolesPerTenant(tenants.map(t => t.id))
                                }}
                                onSubmitted={() => {
                                    setEditDialogOpen(false)
                                    load()
                                }}
                                onClose={() => setEditDialogOpen(false)}
                                adapter={userAdapter}
                />
                <UserDeleteDialog id="delete-dialog"
                                  title={`Delete ${props.topic}`}
                                  open={deleteDialogOpen === undefined || deleteDialogOpen === null ? false : deleteDialogOpen}
                                  idsToDelete={selectedElements.map(e => e.id)}
                                  onClose={() => setDeleteDialogOpen(false)}
                                  onSubmitted={() => {
                                      setDeleteDialogOpen(false)
                                      load()
                                  }}
                                  login={login}
                                  adapter={userAdapter}
                />
            </>
    )

    return (
            <AuthenticatedLayout title={props.title}
                                 breadcrumbs={[{name: "Overview", link: "/app"}, {
                                     name: props.title,
                                     link: props.uri || "",
                                 }]}
                                 topRightSection={buttonBar}
                                 passwordRegexCallback={regex => setPasswordRegex(regex)}
                                 passwordHelpCallback={help => setPasswordHelp(help)}>
                {error != null ? (
                        <FlatPaper>
                            <Alert severity="error">
                                <AlertTitle>Failed to load elements</AlertTitle>
                                {error}
                            </Alert>
                        </FlatPaper>
                ) : (
                        <FlatPaper id="content" style={{height: '100%', minHeight: '650px'}}>
                            <DataGrid rows={elementsPage?.elements || []} columns={columns}
                                      checkboxSelection disableRowSelectionOnClick
                                      className="elements" getRowClassName={(() => "element")}
                                      pagination paginationMode="server"
                                      paginationModel={{page: pageNumber || 0, pageSize: pageSize || 10}}
                                      onPaginationModelChange={(model => {
                                          setPageNumber(model.page)
                                          setPageSize(model.pageSize)
                                      })}
                                      pageSizeOptions={[10, 25, 50, 100]}
                                      rowCount={elementsPage?.totalElements || 0}
                                      sortingMode="server" sortModel={sortModel}
                                      onSortModelChange={(model => {
                                          setSortModel(model)
                                          setSort(assembleSort(model))
                                      })}
                                      rowSelectionModel={selectionModel}
                                      onRowSelectionModelChange={model => setSelectionModel(model)}
                                      loading={isLoading}/>
                        </FlatPaper>
                )}
            </AuthenticatedLayout>
    )
}