import React, {SyntheticEvent, useState} from "react";
import FormDialog from "../../dialog/FormDialog";
import {LoginHolder} from "../../provider/LoginProvider";
import {TenantContractAdapter} from "../../../adapters/TenantContractAdapter";
import {TenantContractDocumentResponse} from "../../../generated/models/TenantContractDocumentResponse";
import {Grid} from "@mui/material";
import TextInput from "../../form/TextInput";
import {timestamp} from "../../../misc/timestamp";

export interface Props {
    id: string
    title: string
    element: TenantContractDocumentResponse | null
    open: boolean
    onClose?: () => void
    onSubmitted?: (response: TenantContractDocumentResponse) => void
    adapter: TenantContractAdapter
    login: LoginHolder | null
}

export default function (props: Props) {

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [nameError, setNameError] = useState<string | null>(null)

    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        if (props.onClose) props.onClose()
    }

    const onSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setError(null)

        if (!props.element) {
            setError("Element is empty")
            setLoading(false)
            return
        }

        const data = new FormData(event.currentTarget as HTMLFormElement)
        const name = data.get("name") as string
        const updatedDocument = {
            ...props.element, name
        } as TenantContractDocumentResponse;
        props.adapter.updateDocument(props.login, {
            id: updatedDocument.id,
            tenantContractId: updatedDocument.tenantContractId,
            name: updatedDocument.name,
            mimeType: updatedDocument.mimeType,
        })
                .then(() => {
                    if (props.onSubmitted) props.onSubmitted(updatedDocument)
                })
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }

    return (<FormDialog id={props.id} title={props.title} submitButton="Rename"
                        errorTitle="Failed to rename document." error={error} open={props.open}
                        isLoading={isLoading}
                        onClose={onClose} onSubmit={onSubmit}>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-name`} label="Name" name="name"
                       value={props.element?.name}
                       error={nameError}
                       autoFocus={true}
                       maxLength={255}
                       helpMessage="The name of the document."
            />
        </Grid>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-mimeType`} label="Mime type" name="mimeType"
                       value={props.element?.mimeType}
                       required={true}
                       readonly={true}
                       maxLength={null}
                       helpMessage="The type of the document."
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput id={`${props.id}-createdAt`} label="Created" name="createdAt"
                       value={timestamp(props.element?.createdAt || null).format()} required={true} readonly={true}
                       maxLength={0}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput id={`${props.id}-updatedAt`} label="Updated" name="updatedAt"
                       value={timestamp(props.element?.updatedAt || null).format()} required={true} readonly={true}
                       maxLength={0}
            />
        </Grid>
    </FormDialog>)
}
