import React, {useContext, useEffect, useState} from "react";
import AuthenticatedLayout from "../../../components/layout/AuthenticatedLayout";
import {LoginContext} from "../../provider/LoginProvider";
import {ServiceResponse, ServiceResponseFromJSON} from "../../../generated/models/ServiceResponse";
import {
    apiKeyAdapter,
    authAdapter,
    Page,
    PageableRequest,
    serviceAdapter,
    tagAdapter,
    tenantAdapter,
    tenantSettingAdapter,
    userAdapter
} from "../../../adapters/interfaces";
import {
    Alert,
    AlertTitle,
    Box,
    Chip,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    styled,
    Tab,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {
    AbcSharp,
    ArticleSharp,
    ContentCopySharp,
    DataArraySharp,
    DeleteSharp,
    DoorBackSharp,
    EditOffSharp,
    EditSharp,
    EventSharp,
    ManageSearchSharp,
    PlayArrowSharp,
    PublicSharp,
    SecuritySharp,
    StopSharp,
    TodaySharp,
    UpdateSharp
} from "@mui/icons-material";
import {BooleanParam, createEnumParam, DateTimeParam, StringParam, useQueryParam} from "use-query-params";
import Card from "../../Card";
import moment from "moment";
import {UiConfigContext} from "../../provider/UiConfigProvider";
import {TenantContext} from "../../provider/TenantProvider";
import {useHotkeys} from "react-hotkeys-hook";
import {navigate} from "gatsby";
import ServiceCreateDialog from "./ServiceCreateDialog";
import {DATETIME_FORMAT, emptyService, emptySpecification, replaceDeployTimeVariables} from "./ServiceModel";
import ServiceEditDialog from "./ServiceEditDialog";
import {RouteComponentProps} from "@reach/router";
import {ServiceEndpointType} from "../../../generated/models/ServiceEndpointType";
import {LoadingButton, TabContext, TabList, TabPanel} from "@mui/lab";
import ResourcesAllocatedBarChart from "../../chart/ResourcesAllocatedBarChart";
import {TenantSettingDefinition} from "../../../adapters/TenantSettingAdapter";
import {UpdateServiceRequestFromJSON} from "../../../generated/models/UpdateServiceRequest";
import {FlatPaper} from "../../Misc";
import {TagResponse} from "../../../generated/models/TagResponse";
import {UserResponse} from "../../../generated/models/UserResponse";
import {ApiKeyResponse} from "../../../generated/models/ApiKeyResponse";
import {TagServiceResponse} from "../../../generated/models/TagServiceResponse";
import ServiceDeleteDialog from "./ServiceDeleteDialog";
import {TITLE_SUFFIX} from "../../layout/GeneralLayout";
import {DateTimePicker} from "@mui/x-date-pickers";
import {unstyle} from "ansi-colors";
import ServiceStatusButton from "../../ServiceStatusButton";

const LogsTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        fontFamily: `Fira Code, monospace`,
        fontSize: `12px`,
        lineHeight: `1.33`,
    },
})

export interface Props extends RouteComponentProps {
    serviceId?: string
}

export default function (props: Props) {
    const {uiConfig} = useContext(UiConfigContext)
    const {login} = useContext(LoginContext)
    const {tenant, setTenant} = useContext(TenantContext)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [cloneDialogOpen, setCloneDialogOpen] = useState<boolean>(false)
    const [editDialogOpen, setEditDialogOpen] = useQueryParam("edit", BooleanParam)
    const [deleteDialogOpen, setDeleteDialogOpen] = useQueryParam("delete", BooleanParam)
    const [tabOpen = "details", setTabOpen] = useQueryParam("tab", StringParam)
    const [element, setElement] = useState<ServiceResponse | null>(null)
    const [elementTags, setElementTags] = useState<Page<TagServiceResponse> | null>(null)
    const [currency, setCurrency] = useState<string | null>(null)
    const [serviceCpuMaxCores, setServiceCpuMaxCores] = useState<number>(0)
    const [serviceMemoryMaxGb, setServiceMemoryMaxGb] = useState<number>(0)
    const [serviceStorageMaxGb, setServiceStorageMaxGb] = useState<number>(0)
    const [tags, setTags] = useState<Page<TagResponse> | null>(null)
    const [users, setUsers] = useState<Page<UserResponse> | null>(null)
    const [apiKeys, setApiKeys] = useState<Page<ApiKeyResponse> | null>(null)

    const [logsFrom = moment().subtract(10, "minutes").toDate(), setLogsFrom] = useQueryParam("logsFrom", DateTimeParam)
    const [logsFromError, setLogsFromError] = useState<string | null>(null)
    const [logsTo = moment().toDate(), setLogsTo] = useQueryParam("logsTo", DateTimeParam)
    const [logsToError, setLogsToError] = useState<string | null>(null)
    const [logsFilter = "", setLogsFilter] = useQueryParam("logsFilter", StringParam)
    const [logsFilterType = "text", setLogsFilterType] = useQueryParam("logsFilterType", createEnumParam(["text", "regex"]))
    const [logsIsLoading, setLogsLoading] = useState<boolean>(false)
    const [logsError, setLogsError] = useState<string | null>(null)
    const [logs, setLogs] = useState<string>("")

    const load = (): void => {
        if (!props.serviceId) return
        setLoading(true)
        serviceAdapter.get(login, props.serviceId)
                .then(response => {
                    setElement(ServiceResponseFromJSON(JSON.parse(replaceDeployTimeVariables(JSON.stringify(response), response?.id || "", response?.alias || "", response?.name || "", response?.specification || emptySpecification))))
                    return serviceAdapter.findTags(login, new PageableRequest(null, 1000, `serviceId==${props.serviceId}`))
                })
                .then(response => {
                    setElementTags(response)
                    return tagAdapter.find(login, new PageableRequest(0, 1000, `tenantId==${tenant!.tenant.id}`))
                })
                .then(response => setTags(response))
                .catch(error => setError(error.message))
                .finally(() => {
                    setLoading(false)
                })
    }
    const loadMaxResourcesForService = (): void => {
        if (!tenant) return
        tenantSettingAdapter.getValue(login, {
            tenantId: tenant.tenant.id,
            name: TenantSettingDefinition[TenantSettingDefinition.CONTAINER_OPS_SERVICE_CPU_MAX],
        }).then(response => setServiceCpuMaxCores(response ? parseInt(response) / 1000 : 0))
        tenantSettingAdapter.getValue(login, {
            tenantId: tenant.tenant.id,
            name: TenantSettingDefinition[TenantSettingDefinition.CONTAINER_OPS_SERVICE_MEMORY_MAX],
        }).then(response => setServiceMemoryMaxGb(response ? parseInt(response) / 1000 : 0))
        tenantSettingAdapter.getValue(login, {
            tenantId: tenant.tenant.id,
            name: TenantSettingDefinition[TenantSettingDefinition.CONTAINER_OPS_SERVICE_STORAGE_MAX],
        }).then(response => setServiceStorageMaxGb(response ? parseInt(response) / 1000 : 0))
    }
    const loadLogsForService = (): void => {
        if (!tenant || !login || !props.serviceId) return
        setLogsError(null)
        if (!logsFrom) {
            setLogsError("Please select a start time!")
            return;
        }
        if (!logsTo) {
            setLogsError("Please select an end time!")
            return;
        }
        setLogsLoading(true)
        serviceAdapter.getLogs(login, props.serviceId, logsFrom, logsTo, logsFilter || "", logsFilterType === "text" ? "text" : "regex", "asc", 1000)
                .then(response => {
                    setLogs(response?.logs?.map(log => unstyle(log.message))?.join("\n") || "")
                })
                .catch(error => setLogsError(error.message))
                .finally(() => {
                    setLogsLoading(false)
                })
    }
    const start = (): void => {
        const request = UpdateServiceRequestFromJSON(element)
        serviceAdapter.updateOne(login, {...request, enabled: true})
                .then(response => load())
                .catch(error => setError(error.message))
    }
    const stop = (): void => {
        const request = UpdateServiceRequestFromJSON(element)
        serviceAdapter.updateOne(login, {...request, enabled: false})
                .then(response => load())
                .catch(error => setError(error.message))
    }
    const openCloneDialog = () => {
        tagAdapter.find(login, new PageableRequest(0, 1000, `tenantId=="${tenant!.tenant.id}"`, decodeURIComponent("+name"))).then(response => setTags(response))
        userAdapter.find(login, new PageableRequest(0, 1000)).then(response => setUsers(response))
        apiKeyAdapter.find(login, new PageableRequest(0, 1000)).then(response => setApiKeys(response))
        setCloneDialogOpen(true)
    }
    const openEditDialog = () => {
        tagAdapter.find(login, new PageableRequest(0, 1000, `tenantId=="${tenant!.tenant.id}"`, decodeURIComponent("+name"))).then(response => setTags(response))
        setEditDialogOpen(true)
    }
    const isWrongTenant = (): boolean => {
        return element?.tenantId !== tenant?.tenant.id
    }
    const changeToServiceTenant = () => {
        if (element && tenant && element.tenantId) {
            tenantAdapter.get(login, element.tenantId).then(response => {
                setTenant({...tenant, tenant: response || tenant.tenant})
            })
        }
    }

    useHotkeys('e', () => {
        document.getElementById('edit')!.click()
    })
    useHotkeys('p', () => {
        document.getElementById('permissions')!.click()
    })
    useHotkeys('v', () => {
        document.getElementById('versions')!.click()
    })
    useHotkeys('d,del', () => {
        document.getElementById('delete')!.click()
    })
    useEffect(() => {
        if (login) {
            console.debug("Loading element")
            load()
        }
    }, [login])
    useEffect(() => {
        if (tenant && login) {
            loadMaxResourcesForService()
            tenantSettingAdapter.getValue(login, {
                tenantId: tenant.tenant.id,
                name: TenantSettingDefinition[TenantSettingDefinition.CURRENCY],
            }).then(response => setCurrency(response))
        }
    }, [tenant, login])
    useEffect(() => {
        if (props.serviceId) {
            if (element && tenant && isWrongTenant()) {
                changeToServiceTenant()
            }
            document.title = `Service ${element?.name || "loading..."}${TITLE_SUFFIX}`
        }
    }, [element])

    const buttonBar = (<>
                {!element?.enabled ? (<Tooltip title="Start" placement="bottom">
                    <span><IconButton id="start" disabled={error != null}
                                      onClick={start}><PlayArrowSharp/></IconButton></span>
                </Tooltip>) : null}
                {element?.enabled ? (<Tooltip title="Stop" placement="bottom">
                    <span><IconButton id="stop" disabled={error != null}
                                      onClick={stop}><StopSharp/></IconButton></span>
                </Tooltip>) : null}
                <Tooltip title="Clone (c)" placement="bottom">
                    <span><IconButton id="clone" disabled={error != null}
                                      onClick={openCloneDialog}><ContentCopySharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Edit (e)" placement="bottom">
                    <span><IconButton id="edit" disabled={error != null}
                                      onClick={openEditDialog}><EditSharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Permissions (p)" placement="bottom">
                    <span><IconButton id="permissions" disabled={error != null}
                                      onClick={e => {
                                          e.preventDefault()
                                          navigate(`/app/services/${props.serviceId}/permissions`)
                                      }}
                                      href={`${process.env.GATSBY_PATH_PREFIX}/app/services/${props.serviceId}/permissions`}><SecuritySharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Versions (v)" placement="bottom">
                    <span><IconButton id="versions" disabled={error != null}
                                      onClick={e => {
                                          e.preventDefault()
                                          navigate(`/app/services/${props.serviceId}/versions`)
                                      }}
                                      href={`${process.env.GATSBY_PATH_PREFIX}/app/services/${props.serviceId}/versions`}><UpdateSharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Delete (d,del)" placement="bottom">
                    <span><IconButton id="delete" disabled={error != null}
                                      onClick={() => setDeleteDialogOpen(true)}><DeleteSharp/></IconButton></span>
                </Tooltip>
                <ServiceCreateDialog id="clone-dialog"
                                     title={`Clone service ${element?.name || ""}`}
                                     open={cloneDialogOpen}
                                     login={login}
                                     tenant={tenant?.tenant || null}
                                     service={element || emptyService}
                                     onSubmitted={() => {
                                         setCloneDialogOpen(false)
                                     }}
                                     onClose={() => setCloneDialogOpen(false)}
                                     serviceAdapter={serviceAdapter}
                                     tagAdapter={tagAdapter}
                                     tenantSettingsAdapter={tenantSettingAdapter}
                                     authAdapter={authAdapter}
                                     tags={tags?.elements || []}
                                     users={users?.elements || []}
                                     apiKeys={apiKeys?.elements || []}
                                     currency={currency || ""}
                />
                <ServiceEditDialog id="edit-dialog"
                                   title={`Edit service ${element?.name || ""}`}
                                   open={editDialogOpen === undefined || editDialogOpen === null ? false : editDialogOpen}
                                   login={login}
                                   service={element || emptyService}
                                   onSubmitted={() => {
                                       setEditDialogOpen(false)
                                       load()
                                   }}
                                   onClose={() => setEditDialogOpen(false)}
                                   serviceAdapter={serviceAdapter}
                                   tagAdapter={tagAdapter}
                                   tenantSettingsAdapter={tenantSettingAdapter}
                                   tags={tags?.elements || []}
                                   tagsOnService={elementTags?.elements || []}
                                   currency={currency || ""}
                />
                <ServiceDeleteDialog id="delete-dialog"
                                     title={`Delete service ${element?.name || ""}`}
                                     open={deleteDialogOpen === undefined || deleteDialogOpen === null ? false : deleteDialogOpen}
                                     idsToDelete={element?.id ? [element?.id] : []}
                                     onClose={() => setDeleteDialogOpen(false)}
                                     onSubmitted={() => {
                                         setDeleteDialogOpen(false)
                                         navigate(`/app/services`)
                                     }}
                                     login={login}
                                     adapter={serviceAdapter}
                />
            </>
    )

    return (
            <AuthenticatedLayout title={`Service ${element?.name || "loading..."}`}
                                 size="xl" topRightSection={buttonBar}
                                 breadcrumbs={[{name: "Overview", link: "/app"}, {
                                     name: "Services",
                                     link: "/app/services",
                                 }, {
                                     name: element?.name || "Loading...",
                                     link: `/app/services/${props.serviceId}`,
                                 }]}>
                {isLoading ? (<Box component="div"
                                   display="flex"
                                   justifyContent="center"
                                   alignItems="center"
                                   sx={{width: "100%", height: "100%"}}>
                    <CircularProgress/>
                </Box>) : <Grid container spacing={2}>
                    {error ? (<FlatPaper>
                        <Alert severity="error">
                            <AlertTitle>Error occurred</AlertTitle>
                            {error}
                        </Alert>
                    </FlatPaper>) : null}
                    <Grid item xs={12}>
                        <Card>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Typography color="text.secondary">Name</Typography>
                                    {element?.name}
                                    <Typography color="text.secondary">Alias</Typography>
                                    {element?.alias}
                                    <Typography color="text.secondary">Description</Typography>
                                    {element?.description || "-"}
                                    {element?.costsPerRequest ? (<><Typography color="text.secondary">Costs per
                                        request</Typography> `¢ ${element?.costsPerRequest}`</>) : null}
                                    {element?.referenceCostsPerRequest ? (<><Typography color="text.secondary">Reference
                                        costs per
                                        request</Typography> `${currency} ${element?.referenceCostsPerRequest}`</>) : null}
                                    <Typography color="text.secondary">Version</Typography>
                                    {element?.version}
                                    <Typography color="text.secondary">Updated</Typography>
                                    <Tooltip
                                            title={moment(element?.createdAt).local().format()}><span>{moment(element?.createdAt).fromNow()}</span></Tooltip>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Typography color="text.secondary">Status</Typography>
                                    {element ? (<ServiceStatusButton serviceId={element.id} service={element} size="medium"/>) : null}
                                    <Typography color="text.secondary">Tags</Typography>
                                    {elementTags?.elements?.map(tagService => {
                                        const tag = tags?.elements?.find(tag => tagService.tagId === tag.id)
                                        if (!tag) return null
                                        return (<><Chip label={tag.name}/><span> </span></>)
                                    })}
                                    <Typography color="text.secondary">ID</Typography>
                                    <Box fontFamily="Fira Code, monospace">{element?.id}</Box>
                                    <Typography color="text.secondary">Image</Typography>
                                    <Box fontFamily="Fira Code, monospace">{element?.specification.image.name}:{element?.specification.image.tag}</Box>
                                    <Typography color="text.secondary">Replicas</Typography>
                                    <Box fontFamily="Fira Code, monospace">{element?.specification.resources.replicas}</Box>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4}>
                                    <Typography color="text.secondary">Endpoints</Typography>
                                    <List dense sx={{paddingTop: 0, paddingBottom: 0}}>
                                        {element?.specification.endpoints.map((endpoint, i) => {
                                            const globalPrefix = endpoint.isExposed && !element.specification.reachability.domain ? `/${tenant?.tenant.alias}/${element.alias}` : ``
                                            const proxyPort = 65000 - 1 - i
                                            const isFullUriPath = endpoint.path?.includes("://")
                                            const internalHost = `${element.alias}.aios-${tenant?.tenant.alias}:${proxyPort}`
                                            const internalPath = `${endpoint.pathPrefix || ""}${endpoint.path || ""}`
                                            const internalUrl = `http://${internalHost}${globalPrefix}${internalPath}`
                                            let externalUrl = `${uiConfig?.rootUrl}${globalPrefix}${internalPath}`
                                            if (element.specification.reachability.domain) {
                                                externalUrl = `//${element.specification.reachability.domain}${internalPath}`
                                            }
                                            if (isFullUriPath) {
                                                externalUrl = endpoint.path!!
                                            }
                                            return (<ListItem disableGutters sx={{paddingTop: 0, paddingBottom: 0}}
                                                              secondaryAction={<>
                                                                  {!isFullUriPath ? (<Tooltip placement="left"
                                                                                              title={<>Internal
                                                                                                  URL:<br/>{internalUrl}</>}><IconButton
                                                                          edge="end" component="a" href={internalUrl}
                                                                          target="_blank"><DoorBackSharp/></IconButton></Tooltip>) : null}
                                                                  {endpoint.isExposed || isFullUriPath ? (
                                                                          <Tooltip placement="left"
                                                                                   title={<>External
                                                                                       URL:<br/>{externalUrl}</>}><IconButton
                                                                                  edge="end" component="a"
                                                                                  href={externalUrl}
                                                                                  target="_blank"><PublicSharp
                                                                                  color="primary"/></IconButton></Tooltip>) : null}
                                                                  {endpoint.type === ServiceEndpointType.OpenApi && (endpoint.isExposed || isFullUriPath) ? (
                                                                          <Tooltip placement="left"
                                                                                   title="OpenAPI documentation"><IconButton
                                                                                  edge="end" component="a"
                                                                                  href={`${process.env.GATSBY_PATH_PREFIX}/app/services/${props.serviceId}/api`}
                                                                                  target="_blank"><ArticleSharp
                                                                                  color="primary"/></IconButton></Tooltip>) : null}
                                                                  {endpoint.type === ServiceEndpointType.Docs && (endpoint.isExposed || isFullUriPath) ? (
                                                                          <Tooltip placement="left"
                                                                                   title="Documentation"><IconButton
                                                                                  edge="end" component="a"
                                                                                  href={`${process.env.GATSBY_PATH_PREFIX}/app/services/${props.serviceId}/docs/${i}`}
                                                                                  target="_blank"><ArticleSharp
                                                                                  color="primary"/></IconButton></Tooltip>) : null}
                                                              </>}>
                                                <ListItemText
                                                        primary={<Box
                                                                fontFamily="Fira Code, monospace">{endpoint.port} {endpoint.protocol} ({endpoint.type})</Box>}
                                                        secondary={<Box
                                                                fontFamily="Fira Code, monospace">{isFullUriPath ? externalUrl : globalPrefix + internalPath}</Box>}/>
                                            </ListItem>)
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <TabContext value={tabOpen || "details"}>
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <TabList onChange={(event, newValue) => setTabOpen(newValue)}>
                                        <Tab label="Details" value="details"/>
                                        <Tab label="Metrics" value="metrics"/>
                                        <Tab label="Logs" value="logs"/>
                                    </TabList>
                                </Box>
                                <TabPanel value="details">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} lg={4}>
                                            <ResourcesAllocatedBarChart id="configuredCpu" showWrapper={false}
                                                                        header="Configured CPU"
                                                                        unit="Cores"
                                                                        data={[
                                                                            {
                                                                                name: "CPU (min)",
                                                                                value: element ? (element.specification.resources.cpuMinInMilliseconds + element.specification.proxy!.cpuMinInMilliseconds) / 1000 : 0,
                                                                                unit: " cores",
                                                                                color: "primary",
                                                                            },
                                                                            {
                                                                                name: "CPU (max)",
                                                                                value: element ? (element.specification.resources.cpuMaxInMilliseconds + element.specification.proxy!.cpuMaxInMilliseconds) / 1000 : 0,
                                                                                unit: " cores",
                                                                                color: "primary",
                                                                            },
                                                                        ]}
                                                                        dataTotal={{
                                                                            name: "Max CPU per service",
                                                                            value: serviceCpuMaxCores,
                                                                            unit: " cores",
                                                                        }}
                                                                        width="100%"
                                                                        height="200px"/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4}>
                                            <ResourcesAllocatedBarChart id="configuredMemory" showWrapper={false}
                                                                        header="Configured memory"
                                                                        unit="GB"
                                                                        data={[
                                                                            {
                                                                                name: "Memory (min)",
                                                                                value: element ? (element.specification.resources.memoryMinInMegaBytes + element.specification.proxy!.memoryMinInMegaBytes) / 1000 : 0,
                                                                                unit: " GB",
                                                                                color: "secondary",
                                                                            },
                                                                            {
                                                                                name: "Memory (max)",
                                                                                value: element ? (element.specification.resources.memoryMaxInMegaBytes + element.specification.proxy!.memoryMaxInMegaBytes) / 1000 : 0,
                                                                                unit: " GB",
                                                                                color: "secondary",
                                                                            },
                                                                        ]}
                                                                        dataTotal={{
                                                                            name: "Max memory per service",
                                                                            value: serviceMemoryMaxGb,
                                                                            unit: " GB",
                                                                        }}
                                                                        width="100%"
                                                                        height="200px"/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4}>
                                            <ResourcesAllocatedBarChart id="configuredStorage" showWrapper={false}
                                                                        header="Configured storage (per container)"
                                                                        unit="GB"
                                                                        data={[
                                                                            {
                                                                                name: "Storage (max)",
                                                                                value: element ? element?.specification.resources.storageInMegaBytes / 1000 : 0,
                                                                                unit: " GB",
                                                                                color: "pink",
                                                                            },
                                                                        ]}
                                                                        dataTotal={{
                                                                            name: "Max storage per service",
                                                                            value: serviceStorageMaxGb,
                                                                            unit: " GB",
                                                                        }}
                                                                        width="100%"
                                                                        height="200px"/>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4}>
                                            <Typography color="text.secondary">Environment variables</Typography>
                                            {element?.specification.environment.variables ? (element?.specification.environment.variables)?.map(it => (
                                                    <Box key={it.name}
                                                         fontFamily="Fira Code, monospace">{`${it.name}=${it.encrypted ? "***" : it.value}`}</Box>)) : "-"}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4}>
                                            <Typography color="text.secondary">Config files</Typography>
                                            {element?.specification.environment.configFiles ? (element?.specification.environment.configFiles)?.map(it => (
                                                    <Typography>{it.name}</Typography>)) : "-"}
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4}>
                                            <Typography color="text.secondary">Volume mounts</Typography>
                                            {element?.specification.environment.mounts ? (element?.specification.environment.mounts)?.map(it => (
                                                    <>
                                                        <Typography
                                                                fontFamily="Fira Code, monospace">{it.mountPath} {it.readOnly ? (
                                                                <EditOffSharp
                                                                        sx={{fontSize: "inherit"}}/>) : null}</Typography>
                                                        <Typography>Volume: <Box component="span"
                                                                                 fontFamily="Fira Code, monospace">{it.volumeName}</Box></Typography>
                                                        <Typography>Sub path: <Box component="span"
                                                                                   fontFamily="Fira Code, monospace">{it.volumeSubPath}</Box></Typography>
                                                    </>)) : "-"}
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value="metrics">
                                    <Typography color="text.secondary">Metrics to record</Typography>
                                    {element?.specification.telemetry.metricsToRecordAsRegex ? element?.specification.telemetry.metricsToRecordAsRegex?.map(it =>
                                            <Box fontFamily="Fira Code, monospace">{it}</Box>) : "-"}
                                </TabPanel>
                                <TabPanel value="logs">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <DateTimePicker slotProps={{
                                                textField: {
                                                    id: "form-logs-from",
                                                    name: "logs-from",
                                                    fullWidth: true,
                                                    helperText: logsFromError
                                                }
                                            }}
                                                            ampm={false}
                                                            format={DATETIME_FORMAT}
                                                            label="From"
                                                            value={moment(logsFrom)}
                                                            maxDateTime={moment(logsTo)}
                                                            onError={() => setLogsToError("Invalid value")}
                                                            components={{
                                                                OpenPickerIcon: TodaySharp
                                                            }}
                                                            onChange={(newValue) => {
                                                                if (moment(newValue, DATETIME_FORMAT).isValid()) {
                                                                    setLogsFrom(newValue?.toDate() || moment().subtract(10, "minutes").toDate())
                                                                }
                                                            }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <DateTimePicker slotProps={{
                                                textField: {
                                                    id: "form-logs-to",
                                                    name: "logs-to",
                                                    fullWidth: true,
                                                    helperText: logsToError
                                                }
                                            }}
                                                            ampm={false}
                                                            format={DATETIME_FORMAT}
                                                            label="To"
                                                            value={moment(logsTo)}
                                                            minDateTime={moment(logsFrom)}
                                                            onError={() => setLogsToError("Invalid value")}
                                                            components={{
                                                                OpenPickerIcon: EventSharp
                                                            }}
                                                            onChange={(newValue) => {
                                                                if (moment(newValue, DATETIME_FORMAT).isValid()) {
                                                                    setLogsTo(newValue?.toDate() || moment().toDate())
                                                                }
                                                            }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={10} lg={5}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel
                                                        htmlFor="form-logs-filter">{logsFilterType === "text" ? "Filter text" : "Filter regex"}</InputLabel>
                                                <OutlinedInput id="form-logs-filter"
                                                               type="text" sx={{fontFamily: "Fira Code, monospace"}}
                                                               value={logsFilter}
                                                               onChange={(event) => setLogsFilter(event.target.value)}
                                                               endAdornment={
                                                                   <InputAdornment position="end">
                                                                       <IconButton edge="end" onClick={() => {
                                                                           if (logsFilterType === "text") {
                                                                               setLogsFilterType("regex")
                                                                           } else {
                                                                               setLogsFilterType("text")
                                                                           }
                                                                       }
                                                                       }>
                                                                           {logsFilterType === "text" ? (
                                                                                   <AbcSharp/>) : (<DataArraySharp/>)}
                                                                       </IconButton>
                                                                   </InputAdornment>
                                                               }
                                                               label={logsFilterType === "text" ? "Filter text" : "Filter regex"}
                                                />
                                                <FormHelperText id="form-logs-filter-helper-text">
                                                    Show logs based on
                                                    this {logsFilterType === "text" ? "filter" : "regex"}. Max. 1000
                                                    lines are shown.
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={2} lg={1}>
                                            <LoadingButton id="form-logs-submit" color="primary" variant="contained"
                                                           type="button" size="large" fullWidth
                                                           sx={{paddingTop: "16px", paddingBottom: "16px"}}
                                                           onClick={loadLogsForService}
                                                           loading={logsIsLoading}><ManageSearchSharp/></LoadingButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {logsError != null ? (
                                                    <Grid item xs={12}>
                                                        <Alert id="form-logs-error" severity="error">
                                                            <AlertTitle>Error retrieving logs</AlertTitle>
                                                            {logsError}
                                                        </Alert>
                                                    </Grid>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LogsTextField placeholder="No log lines displayed"
                                                           multiline fullWidth
                                                           minRows={5}
                                                           maxRows={100}
                                                           InputProps={{
                                                               readOnly: true,
                                                           }}
                                                           value={logs}/>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </Card>
                    </Grid>
                </Grid>}
            </AuthenticatedLayout>
    )
}