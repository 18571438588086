import {EventSharp, TodaySharp} from "@mui/icons-material";
import {Grid} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import React, {useEffect, useState} from "react";
import SelectInput from "../../form/SelectInput";
import TextInput from "../../form/TextInput";
import {TenantContractResponse} from "../../../generated/models/TenantContractResponse";

export const DATE_FORMAT = "YYYY-MM-DD"

export interface Props {
    id: string
    licenseTypeRef: React.RefObject<any>
    licenseTypeError: string | null
    licensePackageRef: React.RefObject<any>
    licensePackageError: string | null
    element?: TenantContractResponse
}

export default function (props: Props) {

    const [validFrom, setValidFrom] = useState<Date>(props.element?.validFrom ? moment(props.element.validFrom).toDate() : moment().toDate())
    const [validFromError, setValidFromError] = useState<string | null>(null)
    const [validTo, setValidTo] = useState<Date>(props.element?.validTo ? moment(props.element.validTo).toDate() : moment().add(1, "years").subtract(1, "days").toDate())
    const [validToError, setValidToError] = useState<string | null>(null)
    const [licenseTypeError, setLicenseTypeError] = useState<string | null>(props.licenseTypeError)
    const [licensePackageError, setLicensePackageError] = useState<string | null>(props.licensePackageError)
    const [metricsRetentionError, setMetricsRetentionError] = useState<string | null>(null)
    const [logsRetentionError, setLogsRetentionError] = useState<string | null>(null)
    const [creditPriceError, setCreditPriceError] = useState<string | null>(null)
    const [supportPlanError, setSupportPlanError] = useState<string | null>(null)
    const [supportResponseAgreementError, setSupportResponseAgreementError] = useState<string | null>(null)
    const [supportResolutionAgreementError, setSupportResolutionAgreementError] = useState<string | null>(null)
    const [informationTextError, setInformationTextError] = useState<string | null>(null)

    useEffect(() => setLicenseTypeError(props.licenseTypeError), [props.licenseTypeError])
    useEffect(() => setLicensePackageError(props.licensePackageError), [props.licensePackageError])

    return (<>
        <Grid item xs={6}>
            <SelectInput ref={props.licenseTypeRef}
                         id={`${props.id}-licenseType`}
                         label="License type"
                         name="licenseType"
                         options={[
                             {id: "SaaS", name: "SaaS"},
                             {id: "Custom", name: "Custom"},
                         ]}
                         error={licenseTypeError}
                         value={props.element?.licenseType ? [{
                             id: props.element.licenseType,
                             name: props.element.licenseType
                         }] : [{id: "SaaS", name: "SaaS"}]}/>
        </Grid>
        <Grid item xs={6}>
            <SelectInput ref={props.licensePackageRef}
                         id={`${props.id}-licensePackage`}
                         label="License package"
                         name="licensePackage"
                         options={[
                             {id: "Business", name: "Business"},
                             {id: "Professional", name: "Professional"},
                             {id: "Self-hosted", name: "Self-hosted"},
                         ]}
                         error={licensePackageError}
                         value={props.element?.licensePackage ? [{
                             id: props.element.licensePackage,
                             name: props.element.licensePackage
                         }] : [{id: "Business", name: "Business"}]}/>
        </Grid>
        <Grid item xs={6}>
            <DatePicker slotProps={{
                textField: {
                    id: "form-valid-from",
                    name: "validFrom",
                    fullWidth: true,
                    size: "small",
                    helperText: validFromError
                }
            }}
                        format={DATE_FORMAT}
                        label="Valid from"
                        value={moment(validFrom)}
                        maxDate={moment(validTo)}
                        onError={() => setValidToError("Invalid value")}
                        components={{
                            OpenPickerIcon: TodaySharp
                        }}
                        onChange={(newValue) => {
                            if (moment(newValue, DATE_FORMAT).isValid()) {
                                setValidFrom(newValue?.toDate() || moment().toDate())
                            }
                        }}
            />
        </Grid>
        <Grid item xs={6}>
            <DatePicker slotProps={{
                textField: {
                    id: "form-valid-to",
                    name: "validTo",
                    fullWidth: true,
                    size: "small",
                    helperText: validToError,
                }
            }}
                        format={DATE_FORMAT}
                        label="Valid to"
                        value={moment(validTo)}
                        minDate={moment(validFrom)}
                        onError={() => setValidToError("Invalid value")}
                        components={{
                            OpenPickerIcon: EventSharp
                        }}
                        onChange={(newValue) => {
                            if (moment(newValue, DATE_FORMAT).isValid()) {
                                setValidTo(newValue?.toDate() || moment().add(1, "years").subtract(1, "days").toDate())
                            }
                        }}
            />
        </Grid>
        <Grid item xs={4}>
            <TextInput id={`${props.id}-metricsRetention`}
                       label="Metrics retention"
                       name="metricsRetentionInDays"
                       value={props.element?.metricsRetentionInHours ? (props.element.metricsRetentionInHours / 24).toString() : "90"}
                       error={metricsRetentionError} maxLength={null} pattern="^[0-9]{1,5}$"
                       helpMessage="The retention period of the collected metrics in days."/>
        </Grid>
        <Grid item xs={4}>
            <TextInput id={`${props.id}-logsRetention`}
                       label="Logs retention"
                       name="logsRetentionInDays"
                       value={props.element?.logsRetentionInHours ? (props.element.logsRetentionInHours / 24).toString() : "30"}
                       error={logsRetentionError} maxLength={null} pattern="^[0-9]{1,5}$"
                       helpMessage="The retention period of the collected logs in days."/>
        </Grid>
        <Grid item xs={4}>
            <TextInput id={`${props.id}-creditPrice`}
                       label="Credit price"
                       name="creditPrice"
                       value={props.element?.creditPrice === undefined ? "0.4" : props.element.creditPrice.toString()}
                       error={creditPriceError} maxLength={null} pattern="^[0-9]{1,6}[.]{0,1}[0-9]{0,6}$"
                       helpMessage="The default credit price in EUR when no package has any credits left."/>
        </Grid>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-supportPlan`}
                       label="Support plan"
                       name="supportPlan"
                       value={props.element?.supportPlan || "Business Time: Mo – Fr: 09:00 – 17:00, exklusive österreichische Feiertage sowie 24.12. und 31.12."}
                       error={supportPlanError} multiline={true} maxLength={200}
                       helpMessage="Describe briefly the support plan."/>
        </Grid>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-supportResponseAgreement`}
                       label="Support response agreement"
                       name="supportResponseAgreement"
                       value={props.element?.supportResponseAgreement || "Blocker, Critical, Major, Medium, Low: 1 Business Day"}
                       error={supportResponseAgreementError} multiline={true} maxLength={500}
                       helpMessage="Describe briefly the support response agreement so that support staff understand how fast a response is required."/>
        </Grid>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-supportResolutionAgreement`}
                       label="Support resolution agreement"
                       name="supportResolutionAgreement"
                       value={props.element?.supportResolutionAgreement || "Blocker: 2 Business Days\nMajor, Critical: 10 Business Days\nLow, Medium: Best Effort"}
                       error={supportResolutionAgreementError} multiline={true} maxLength={500}
                       helpMessage="Describe briefly the support resolution agreement so that support staff understand how fast a resolution must be provided."/>
        </Grid>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-informationText`}
                       label="Additional information"
                       name="informationText"
                       value={props.element?.informationText || ""}
                       error={informationTextError} required={false} multiline={true} maxLength={null}
                       helpMessage="Provide additional information regarding the customer's contract."/>
        </Grid>
    </>)
}
