import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Box,
    Button,
    Chip,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    Step,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import React, {ChangeEvent, createRef, RefObject, SyntheticEvent, useState} from "react";
import {ALIAS_REGEX, DECIMAL_REGEX, FILE_PATH_REGEX, serviceSpecificationSchema} from "./ServiceModel";
import CodeEditor, {CodeEditorMarker, CodeEditorMarkerSeverity} from "../../CodeEditor";
import {LoadingButton} from "@mui/lab";
import {ServiceResponse} from "../../../generated/models/ServiceResponse";
import {ServiceAdapter} from "../../../adapters/ServiceAdapter";
import {LoginHolder} from "../../provider/LoginProvider";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import {TagAdapter} from "../../../adapters/TagAdapter";
import TextInput from "../../form/TextInput";
import {TagResponse} from "../../../generated/models/TagResponse";
import TagInput from "../../form/TagInput";
import {CreateTagRequest} from "../../../generated/models/CreateTagRequest";
import {ServiceEndpoint} from "../../../generated/models/ServiceEndpoint";
import {AddSharp, DeleteSharp, EditOffSharp, ExpandMoreSharp} from "@mui/icons-material";
import {ServiceVariable} from "../../../generated/models/ServiceVariable";
import {ServiceFile} from "../../../generated/models/ServiceFile";
import {ServiceMount} from "../../../generated/models/ServiceMount";
import {TagServiceResponse} from "../../../generated/models/TagServiceResponse";
import {UpdateServiceRequest} from "../../../generated/models/UpdateServiceRequest";
import ServiceSpecificationInfo from "./ServiceSpecificationInfo";
import {TenantSettingAdapter} from "../../../adapters/TenantSettingAdapter";
import ServiceSpecificationConfigFileInfo from "./ServiceSpecificationConfigFileInfo";
import DeleteDialog from "../../dialog/DeleteDialog";
import {ServiceSpecification} from "../../../generated/models/ServiceSpecification";
import {UUID} from "../../../adapters/interfaces";
import {base64DecodeUtf8Safe, base64EncodeUtf8Safe} from "../../../misc/misc";

export interface Props {
    id: UUID
    onClose: () => void
    onSubmitted?: () => void
    serviceAdapter: ServiceAdapter
    tagAdapter: TagAdapter
    tenantSettingsAdapter: TenantSettingAdapter,
    login: LoginHolder | null
    service: ServiceResponse
    tagsOnService: TagServiceResponse[]
    tags: TagResponse[]
    currency: string
}

export default function (props: Props) {
    const GENERAL_STEP = 0
    const SPEC_GENERAL_STEP = 1
    const SPEC_CONFIG_STEP = 2
    const REVIEW_STEP = 3
    const [isLoading, setLoading] = useState<boolean>(false)
    const [generalError, setGeneralError] = useState<string | null>(null)
    const [nameError, setNameError] = useState<string | null>(null)
    const [costsPerRequestError, setCostsPerRequestError] = useState<string | null>(null)
    const [referenceCostsPerRequestError, setReferenceCostsPerRequestError] = useState<string | null>(null)
    const [tagsError, setTagsError] = useState<string | null>(null)
    const [specGeneralError, setSpecGeneralError] = useState<string | null>(null)
    const [specConfigError, setSpecConfigError] = useState<string | null>(null)
    const [specConfigFileExpanded, setSpecConfigFileExpanded] = useState<string | false>(false)
    const [specConfigFileNewName, setSpecConfigFileNewName] = useState<string>("")
    const [specConfigFileNewNameErr, setSpecConfigFileNewNameErr] = useState<string | null>(null)
    const [specConfigFiles, setSpecConfigFiles] = useState<Record<string, {
        ref: RefObject<any>,
        content: string
    }>>((props.service.specification?.environment?.configFiles || []).reduce((result, value) => ({
        ...result,
        [value.name]: {ref: createRef<any>(), content: base64DecodeUtf8Safe(value.content)}
    }), {}))
    const [specConfigFileDeleteDialogOpen, setSpecConfigFileDeleteDialogOpen] = useState<string | boolean>(false)
    const [reviewError, setReviewError] = useState<string | null>(null)
    const [activeStep, setActiveStep] = useState<number>(GENERAL_STEP)
    const [completedSteps, setCompletedSteps] = useState<{ [k: number]: boolean; }>({})
    const [formData, setFormData] = useState<any>({})

    const nameRef = createRef<any>()
    const descriptionRef = createRef<any>()
    const costsPerRequestRef = createRef<any>()
    const referenceCostsPerRequestRef = createRef<any>()
    const tagsRef = createRef<any>()
    const specRef = createRef<any>()
    const [editorMarkers, setEditorMarkers] = useState<CodeEditorMarker[]>([])

    const changeStep = (currentStep: number, newStep: number): void => {
        switch (currentStep) {
            case GENERAL_STEP:
                collectGeneralFormData()
                break
            case SPEC_GENERAL_STEP:
                collectSpecGeneralFormData()
                break
            case SPEC_CONFIG_STEP:
                collectSpecConfigFormData()
                break
        }
        setActiveStep(newStep)
    }
    const collectGeneralFormData = (): void => {
        const newCompletedSteps = completedSteps
        newCompletedSteps[GENERAL_STEP] = false
        setCompletedSteps(newCompletedSteps)

        let nameErr = null
        let costsPerRequestErr = null
        let referenceCostsPerRequestErr = null
        let tagsErr = null
        let generalErr = null

        const name = nameRef.current.getValue()?.trim()
        if (!name) nameErr = "Name must be specified."
        const description = descriptionRef.current.getValue()?.trim()
        const costs = costsPerRequestRef.current.getValue()
        const costsParsed = costs ? parseFloat(costs) : null
        if (costsParsed !== null && isNaN(costsParsed)) costsPerRequestErr = "Invalid number."
        const refCosts = referenceCostsPerRequestRef.current.getValue()
        const refCostsParsed = refCosts ? parseFloat(refCosts) : null
        if (refCostsParsed !== null && isNaN(refCostsParsed)) referenceCostsPerRequestErr = "Invalid number."
        const tags = tagsRef.current.getValue()
        tagsErr = tagsRef.current.getError()
        if (nameErr || tagsErr || costsPerRequestErr || referenceCostsPerRequestErr) {
            generalErr = "Invalid input"
        }

        setNameError(nameErr)
        setTagsError(tagsErr)
        setCostsPerRequestError(costsPerRequestErr)
        setReferenceCostsPerRequestError(referenceCostsPerRequestErr)
        setGeneralError(generalErr)
        setFormData({
            ...formData,
            id: props.service.id,
            version: props.service.version,
            enabled: props.service.enabled,
            name,
            alias: props.service.alias,
            description,
            costsPerRequest: costsParsed,
            referenceCostsPerRequest: refCostsParsed,
            tags,
        })
        if (!generalErr) {
            const newCompletedSteps = completedSteps
            newCompletedSteps[GENERAL_STEP] = true
            setCompletedSteps(newCompletedSteps)
        }
    }
    const specForCodeEditor = (spec: ServiceSpecification): string => {
        let newSpec: any = {...spec}
        if (newSpec.environment?.hasOwnProperty("configFiles")) {
            delete newSpec.environment.configFiles
        }
        return JSON.stringify(newSpec, null, 2)
    }
    const collectSpecGeneralFormData = (): void => {
        const newCompletedSteps = completedSteps
        newCompletedSteps[SPEC_GENERAL_STEP] = true
        setCompletedSteps(newCompletedSteps)

        let specErr = null

        const errorMarkers = editorMarkers.filter(m => m.severity === CodeEditorMarkerSeverity.Error)
        if (errorMarkers.length > 0) {
            if (errorMarkers.length === 1) {
                specErr = `Please fix the error at line ${errorMarkers[0].endLineNumber}.`
            } else {
                specErr = `Please fix all ${errorMarkers.length} errors at lines ${errorMarkers.map(m => m.endLineNumber).join(", ")}.`
            }
        }

        setSpecGeneralError(specErr)
        let specification = null
        if (specRef.current) {
            specification = JSON.parse(specRef.current.getEditorValue())
        } else {
            specification = {}
        }
        setFormData({
            ...formData,
            specification,
        })
        if (!specErr) {
            const newCompletedSteps = completedSteps
            newCompletedSteps[SPEC_GENERAL_STEP] = true
            setCompletedSteps(newCompletedSteps)
        }
    }
    const expandConfigFile = (specConfigFile: string) => (event: SyntheticEvent, isExpanded: boolean) => {
        setSpecConfigFileExpanded(isExpanded ? specConfigFile : false)
    }
    const collectSpecConfigFormData = (): void => {
        const newCompletedSteps = completedSteps
        newCompletedSteps[SPEC_CONFIG_STEP] = true
        setCompletedSteps(newCompletedSteps)

        let configErr = null
        let configFiles: ServiceFile[] = []
        let configFilesTooLong = []
        Object.keys(specConfigFiles).forEach(name => {
            const fileContent = specConfigFiles[name].ref.current.getEditorValue()
            specConfigFiles[name].content = fileContent
            if (name.length + fileContent.length > 1000000) {
                configFilesTooLong.push(name)
            }
            configFiles.push({name, content: base64EncodeUtf8Safe(fileContent)})
        })
        if (configFilesTooLong.length > 0) {
            configErr = `Content config files ${configFiles} are too long. Only 1000000 characters are allowed per file.`
            setSpecConfigError(configErr)
        }
        setFormData({
            ...formData,
            configFiles,
        })
        if (!configErr) {
            const newCompletedSteps = completedSteps
            newCompletedSteps[SPEC_CONFIG_STEP] = true
            setCompletedSteps(newCompletedSteps)
        }
    }
    const submit = (): void => {
        setLoading(true)

        const service: UpdateServiceRequest = {
            id: formData.id,
            enabled: formData.enabled,
            tenantId: props.service.tenantId,
            alias: formData.alias,
            name: formData.name,
            description: formData.description,
            costsPerRequest: formData.costsPerRequest,
            referenceCostsPerRequest: formData.referenceCostsPerRequest,
            specification: formData.specification,
        }
        service.specification.environment["configFiles"] = formData.configFiles

        const tagsToCreate: CreateTagRequest[] = formData.tags?.filter((t: TagResponse) => t.name.startsWith("Add \""))?.map((t: TenantResponse) => {
            return {tenantId: props.service.tenantId, name: t.id}
        }) || []
        props.serviceAdapter.updateOne(props.login, service)
                .then(updatedServiceResponse => {
                    console.debug("Updated service: ", updatedServiceResponse)
                    return (tagsToCreate.length > 0 ? props.tagAdapter.create(props.login, tagsToCreate) : Promise.resolve([]))
                            .then(createdTagsResponse => {
                                console.debug("Created tags: ", createdTagsResponse)
                                const currentTagIds: string[] = props.tagsOnService.map(it => it.tagId)
                                console.debug("Current tag ids on service: ", currentTagIds)
                                const editedTagIds: string[] = createdTagsResponse.map((t: TagResponse) => t.id).concat(formData.tags.filter((t: TagResponse) => !t.name.startsWith("Add \"")).map((t: TagResponse) => t.id))
                                console.debug("Edited tags ids on service: ", editedTagIds)
                                const tagsToAdd = editedTagIds.filter((it: string) => !currentTagIds.includes(it))
                                console.debug("Tags to add: ", tagsToAdd)
                                const tagsToRemove = currentTagIds.filter(it => !editedTagIds.includes(it))
                                console.debug("Tags to remove: ", tagsToRemove)
                                const adding = tagsToAdd.length == 0 ? Promise.resolve() : props.serviceAdapter.addTags(props.login, {
                                    elements: tagsToAdd.map(tagId => Object.assign({
                                        tenantId: props.service.tenantId,
                                        tagId,
                                        serviceId: props.service.id,
                                    }))
                                }).then(() => console.debug(`Added ${tagsToAdd.length} tags to service`))
                                const removing = tagsToRemove.length == 0 ? Promise.resolve() : props.serviceAdapter.removeTags(props.login, {
                                    elements: tagsToRemove.map(tagId => Object.assign({
                                        tenantId: props.service.tenantId,
                                        tagId,
                                        serviceId: props.service.id,
                                    }))
                                }).then(() => console.debug(`Removed ${tagsToRemove.length} tags from service`))
                                return adding.then(() => removing)
                            })
                            .then(() => updatedServiceResponse)
                })
                .then(() => {
                    if (props.onSubmitted) props.onSubmitted()
                    props.onClose()
                })
                .catch(error => {
                    if (error.message.startsWith("Invalid service specification")) {
                        setSpecGeneralError(error.message)
                    } else {
                        setReviewError(error.message)
                    }
                })
                .finally(() => setLoading(false))
    }

    const generalStep = (<Box id={`${props.id}-step-${GENERAL_STEP}`} mt="16px">
        <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
                <TextInput id={`${props.id}-id`} label="ID" name="id"
                           value={props.service.id} maxLength={36}
                           readonly={true} helpMessage="The ID of the service."/>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextInput id={`${props.id}-version`} label="Version" name="version"
                           value={props.service.version.toString()} maxLength={null}
                           readonly={true} helpMessage="The version of the service."/>
            </Grid>
            <Grid item xs={12} sm={8}>
                <TextInput id={`${props.id}-name`} label="Name" name="name"
                           value={props.service.name} error={nameError} maxLength={50}
                           ref={nameRef} helpMessage="The name of the service."/>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextInput id={`${props.id}-alias`} label="Alias" name="alias" pattern={ALIAS_REGEX}
                           value={props.service.alias}
                           readonly={true} maxLength={16}
                           helpMessage="The alias for the service."/>
            </Grid>
            <Grid item xs={12}>
                <TextInput id={`${props.id}-description`} label="Description" name="description"
                           value={props.service.description} maxLength={100}
                           ref={descriptionRef} required={false} helpMessage="The description of the service."
                           multiline={true}/>
            </Grid>
            <Grid item xs={6}>
                <TextInput id={`${props.id}-costs-per-request`} label="Costs per request" name="costs"
                           value={props.service.costsPerRequest?.toString() || ""} pattern={DECIMAL_REGEX}
                           error={costsPerRequestError} maxLength={null}
                           ref={costsPerRequestRef} required={false}
                           helpMessage="The costs per request in credits."
                           start="¢"/>
            </Grid>
            <Grid item xs={6}>
                <TextInput id={`${props.id}-ref-costs-per-request`} label="Reference costs per request" name="refcosts"
                           value={props.service.referenceCostsPerRequest?.toString() || ""} pattern={DECIMAL_REGEX}
                           error={referenceCostsPerRequestError} maxLength={null}
                           ref={referenceCostsPerRequestRef} required={false}
                           helpMessage={`The reference costs per request in ${props.currency}. Used for calculating the return on invest (ROI).`}
                           start={props.currency}/>
            </Grid>
            <Grid item xs={12}>
                <TagInput id={`${props.id}-tags`}
                          value={props.tags.filter(t => props.tagsOnService.findIndex(ts => ts.tagId === t.id) >= 0) || []}
                          error={tagsError}
                          ref={tagsRef} creatable
                          options={props.tags || []}
                />
            </Grid>
        </Grid>
        <Box sx={{display: `flex`, flexDirection: `row`, pt: 2}}>
            <Box sx={{flex: `1 1 auto`}}/>
            <Button id={`${props.id}-cancel`} color="primary" onClick={props.onClose} sx={{mr: 1}}>Cancel</Button>
            <Button id={`${props.id}-cancel`} color="primary" variant="contained"
                    onClick={() => changeStep(GENERAL_STEP, SPEC_GENERAL_STEP)}>Next</Button>
        </Box>
    </Box>)
    const specificationGeneralStep = (<Box id={`${props.id}-step-${SPEC_GENERAL_STEP}`} mt="16px">
        <ServiceSpecificationInfo id={props.id} tenantSettingsAdapter={props.tenantSettingsAdapter}/>
        <CodeEditor id={`${props.id}-editor`}
                    name="specification"
                    width="100%" height="75vh"
                    ref={specRef}
                    language="json"
                    schemas={[serviceSpecificationSchema]}
                    value={specForCodeEditor(formData.specification ? formData.specification : props.service.specification)}
                    onChangeMarkers={m => setEditorMarkers(m)}/>
        <Box sx={{display: `flex`, flexDirection: `row`, pt: 2}}>
            <Button id={`${props.id}-back`} color="primary"
                    onClick={() => changeStep(SPEC_GENERAL_STEP, GENERAL_STEP)}>Back</Button>
            <Box sx={{flex: `1 1 auto`}}/>
            <Button id={`${props.id}-cancel`} color="primary" onClick={props.onClose} sx={{mr: 1}}>Cancel</Button>
            <Button id={`${props.id}-cancel`} color="primary" variant="contained"
                    onClick={() => changeStep(SPEC_GENERAL_STEP, SPEC_CONFIG_STEP)}>Next</Button>
        </Box>
    </Box>)
    const specificationConfigStep = (<Box id={`${props.id}-step-${SPEC_CONFIG_STEP}`} mt="16px">
        <FormControl variant="outlined" size="small" error={specConfigFileNewNameErr != null}>
            <InputLabel htmlFor="new-file-input">New file</InputLabel>
            <OutlinedInput id="new-file-input"
                           label="New file"
                           value={specConfigFileNewName}
                           onChange={(event: ChangeEvent<HTMLInputElement>) => {
                               setSpecConfigFileNewNameErr(null)
                               setSpecConfigFileNewName(event.target.value)
                           }}
                           endAdornment={<>
                               <InputAdornment position="end">
                                   <IconButton edge="end" disabled={specConfigFileNewNameErr != null}
                                               onClick={() => {
                                                   if (!new RegExp(FILE_PATH_REGEX).test(specConfigFileNewName)) {
                                                       setSpecConfigFileNewNameErr("Invalid file name.")
                                                       return
                                                   }
                                                   if (Object.keys(specConfigFiles).includes(specConfigFileNewName)) {
                                                       setSpecConfigFileNewNameErr("File already exists.")
                                                       return
                                                   }
                                                   setSpecConfigFiles({
                                                       ...specConfigFiles,
                                                       [specConfigFileNewName]: {ref: createRef<any>(), content: ""}
                                                   })
                                                   setSpecConfigFileExpanded(specConfigFileNewName)
                                                   setSpecConfigFileNewName("")
                                               }}><AddSharp/></IconButton>
                               </InputAdornment>
                               <InputAdornment position="end">
                                   <ServiceSpecificationConfigFileInfo id={props.id}/>
                               </InputAdornment>
                           </>}
                           inputProps={{pattern: FILE_PATH_REGEX}}
            />
            <FormHelperText>{specConfigFileNewNameErr}</FormHelperText>
        </FormControl>
        {Object.keys(specConfigFiles).map(name => {
            const ref = specConfigFiles[name].ref
            const initialContent = specConfigFiles[name].content
            const fileNameSanitized = name.replace("/", "-").replace(".", "-")
            const fileExtension = name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2)
            return (
                    <Accordion key={fileNameSanitized}
                               expanded={specConfigFileExpanded === name}
                               onChange={expandConfigFile(name)}
                               variant="outlined"
                               sx={{marginBottom: "5px"}}>
                        <AccordionSummary expandIcon={<ExpandMoreSharp/>}>
                            <Typography sx={{width: `33%`, flexShrink: 0}}>{name}</Typography>
                            <Typography noWrap sx={{
                                display: specConfigFileExpanded === name ? `none` : `inherit`,
                                color: `text.secondary`,
                                fontFamily: `Fira Code, monospace`,
                            }}>{(ref.current?.getEditorValue() ? ref.current?.getEditorValue() : initialContent).substring(0, 100)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CodeEditor id={`${props.id}-editor-${fileNameSanitized}`}
                                        name={fileNameSanitized}
                                        width="100%" height="50vh"
                                        ref={specConfigFiles[name].ref}
                                        language={fileExtension}
                                        schemas={[]}
                                        value={initialContent}/>
                        </AccordionDetails>
                        <AccordionActions>
                            <IconButton onClick={() => {
                                if (!ref.current?.getEditorValue()) {
                                    const updatedConfigFiles = {
                                        ...specConfigFiles
                                    }
                                    delete updatedConfigFiles[name]
                                    setSpecConfigFiles(updatedConfigFiles)
                                } else {
                                    setSpecConfigFileDeleteDialogOpen(name)
                                }
                            }}><DeleteSharp/></IconButton>
                        </AccordionActions>
                    </Accordion>
            )
        })}
        <Box sx={{display: `flex`, flexDirection: `row`, pt: 2}}>
            <Button id={`${props.id}-back`} color="primary"
                    onClick={() => changeStep(SPEC_CONFIG_STEP, SPEC_GENERAL_STEP)}>Back</Button>
            <Box sx={{flex: `1 1 auto`}}/>
            <Button id={`${props.id}-cancel`} color="primary" onClick={props.onClose} sx={{mr: 1}}>Cancel</Button>
            <Button id={`${props.id}-cancel`} color="primary" variant="contained"
                    onClick={() => changeStep(SPEC_CONFIG_STEP, REVIEW_STEP)}>Next</Button>
        </Box>
        <DeleteDialog title="Remove config file" errorTitle="Deletion failed." error={null}
                      open={specConfigFileDeleteDialogOpen != false} onClose={() => {
            setSpecConfigFileDeleteDialogOpen(false)
        }} onSubmit={() => {
            const updatedConfigFiles = {
                ...specConfigFiles
            }
            delete updatedConfigFiles[specConfigFileDeleteDialogOpen.toString()]
            setSpecConfigFiles(updatedConfigFiles)
            setSpecConfigFileDeleteDialogOpen(false)
        }} fullScreen={false} isLoading={false}>
            <span>Do you really want to delete file <strong>{specConfigFileDeleteDialogOpen}</strong>?</span>
        </DeleteDialog>
    </Box>)
    const reviewStep = (<Box id={`${props.id}-step-${REVIEW_STEP}`} mt="16px">
        {reviewError ? (<Alert severity="error">
            <AlertTitle>Error occurred</AlertTitle>
            {reviewError}
        </Alert>) : null}
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
                <Typography color="text.secondary">Name</Typography>
                {formData.name || "-"}
                <Typography color="text.secondary">Alias</Typography>
                {formData.alias || "-"}
                <Typography color="text.secondary">Description</Typography>
                {formData.description || "-"}
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <Typography color="text.secondary">Tags</Typography>
                {formData.tags?.map((tag: TagResponse) => (<><Chip label={tag.name}/><span> </span></>))}
                <Typography color="text.secondary">Image</Typography>
                <Box fontFamily="Fira Code, monospace">{formData.specification?.image?.name}:{formData.specification?.image?.tag}</Box>
                <Typography color="text.secondary">Replicas</Typography>
                <Box fontFamily="Fira Code, monospace">{formData.specification?.resources?.replicas}</Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <Typography color="text.secondary">Endpoints</Typography>
                <List dense sx={{paddingTop: 0, paddingBottom: 0}}>
                    {formData.specification?.endpoints?.map((endpoint: ServiceEndpoint) => {
                        return (<ListItem disableGutters sx={{paddingTop: 0, paddingBottom: 0}}>
                            <ListItemText primary={<Box
                                    fontFamily="Fira Code, monospace">{endpoint.port} {endpoint.pathPrefix || ""}{endpoint.path || ""} {endpoint.protocol} ({endpoint.type})</Box>}/>
                        </ListItem>)
                    })}
                </List>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <Typography color="text.secondary">Environment variables</Typography>
                {formData.specification?.environment?.variables ? (formData.specification?.environment?.variables)?.map((it: ServiceVariable) => (
                        <Box key={it.name}
                             fontFamily="Fira Code, monospace">{`${it.name}=${it.encrypted ? "***" : it.value}`}</Box>)) : "-"}
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <Typography color="text.secondary">Configuration</Typography>
                {formData.configFiles ? (formData.configFiles)?.map((it: ServiceFile) => (
                        <Box key={it.name}>
                            <Typography>{it.name}</Typography>
                            <Box fontFamily="Fira Code, monospace"
                                 fontSize="10px">{atob(it.content)}</Box>
                        </Box>)) : "-"}
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <Typography color="text.secondary">Volume mounts</Typography>
                {formData.specification?.environment?.mounts ? (formData.specification?.environment?.mounts)?.map((it: ServiceMount) => (
                        <Box key={it.volumeName}>
                            <Typography
                                    fontFamily="Fira Code, monospace">{it.mountPath} {it.readOnly ? (
                                    <EditOffSharp
                                            sx={{fontSize: "inherit"}}/>) : null}</Typography>
                            <Typography>Volume: <Box component="span"
                                                     fontFamily="Fira Code, monospace">{it.volumeName}</Box></Typography>
                            <Typography>Sub path: <Box component="span"
                                                       fontFamily="Fira Code, monospace">{it.volumeSubPath || "-"}</Box></Typography>
                        </Box>)) : "-"}
            </Grid>
        </Grid>
        <Box sx={{display: `flex`, flexDirection: `row`, pt: 2}}>
            <Button id={`${props.id}-back`} color="primary"
                    onClick={() => changeStep(REVIEW_STEP, SPEC_CONFIG_STEP)}>Back</Button>
            <Box sx={{flex: `1 1 auto`}}/>
            <Button id={`${props.id}-cancel`} color="primary" onClick={props.onClose} sx={{mr: 1}}>Cancel</Button>
            <LoadingButton id={`${props.id}-submit`} color="primary" variant="contained"
                           disabled={generalError != null || !completedSteps[GENERAL_STEP] || specGeneralError != null || !completedSteps[SPEC_GENERAL_STEP]}
                           loading={isLoading} onClick={submit}>Edit</LoadingButton>
        </Box>
    </Box>)

    return (<Grid item xs={12}>
        <Stepper nonLinear activeStep={activeStep}>
            <Step completed={!generalError && completedSteps[GENERAL_STEP]}>
                <StepLabel id={`${props.id}-step-general`}
                           sx={{cursor: "pointer"}}
                           error={generalError !== null}
                           optional={(<Typography variant="caption"
                                                  color="error">{generalError?.substring(0, 100)}</Typography>)}
                           onClick={() => changeStep(activeStep, GENERAL_STEP)}>General</StepLabel>
            </Step>
            <Step completed={!specGeneralError && completedSteps[SPEC_GENERAL_STEP]}>
                <StepLabel id={`${props.id}-step-spec-general`}
                           sx={{cursor: "pointer"}}
                           error={specGeneralError !== null}
                           optional={(<Typography variant="caption"
                                                  color="error">{specGeneralError?.substring(0, 100)}</Typography>)}
                           onClick={() => changeStep(activeStep, SPEC_GENERAL_STEP)}>Specification</StepLabel>
            </Step>
            <Step completed={!specConfigError && completedSteps[SPEC_CONFIG_STEP]}>
                <StepLabel id={`${props.id}-step-spec-config`}
                           sx={{cursor: "pointer"}}
                           error={specConfigError !== null}
                           optional={(<Typography variant="caption"
                                                  color="error">{specConfigError?.substring(0, 100)}</Typography>)}
                           onClick={() => changeStep(activeStep, SPEC_CONFIG_STEP)}>Configuration</StepLabel>
            </Step>
            <Step completed={!reviewError && completedSteps[REVIEW_STEP]}>
                <StepLabel id={`${props.id}-step-review`}
                           sx={{cursor: "pointer"}}
                           error={reviewError !== null}
                           optional={(<Typography variant="caption"
                                                  color="error">{reviewError?.substring(0, 100)}</Typography>)}
                           onClick={() => changeStep(activeStep, REVIEW_STEP)}>Review</StepLabel>
            </Step>
        </Stepper>
        {activeStep === GENERAL_STEP ? (generalStep) : null}
        {activeStep === SPEC_GENERAL_STEP ? (specificationGeneralStep) : null}
        {activeStep === SPEC_CONFIG_STEP ? (specificationConfigStep) : null}
        {activeStep === REVIEW_STEP ? (reviewStep) : null}
    </Grid>)
}
