import React, { useContext, useEffect, useState } from "react";
import AuthenticatedLayout from "../../../components/layout/AuthenticatedLayout";
import { LoginContext } from "../../provider/LoginProvider";
import {
    UserPermissionResponse,
    UserPermissionResponseEntityEnum
} from "../../../generated/models/UserPermissionResponse";
import {
    apiKeyAdapter,
    authAdapter,
    Page,
    pageable,
    PageableRequest,
    serviceAdapter,
    userAdapter
} from "../../../adapters/interfaces";
import { Alert, AlertTitle, Chip, IconButton, Tooltip } from "@mui/material";
import { BooleanParam, useQueryParam } from "use-query-params";
import {
    DataGrid,
    getGridStringOperators, GridCellParams,
    GridColDef,
    GridValueGetterParams
} from "@mui/x-data-grid";
import { FlatPaper } from "../../Misc";
import { AddSharp, DeleteSharp, LockSharp, PeopleSharp } from "@mui/icons-material";
import { RouteComponentProps } from "@reach/router";
import { TenantContext } from "../../provider/TenantProvider";
import { ServiceResponse } from "../../../generated/models/ServiceResponse";
import {
    ApiKeyPermissionResponse,
    ApiKeyPermissionResponseEntityEnum
} from "../../../generated/models/ApiKeyPermissionResponse";
import PermissionDeleteDialog from "../permission/PermissionDeleteDialog";
import UserPermissionCreateDialog from "../permission/UserPermissionCreateDialog";
import ApiKeyPermissionCreateDialog from "../permission/ApiKeyPermissionCreateDialog";
import { UserResponse } from "../../../generated/models/UserResponse";
import { ApiKeyResponse } from "../../../generated/models/ApiKeyResponse";
import { PermissionAction } from "../../../generated/models/PermissionAction";
import { nameOfEnumKey } from "../../../misc/enum";

export interface Props extends RouteComponentProps {
    serviceId?: string
    userPermissionSort?: string
    apiKeyPermissionSort?: string
}

interface UserPermissionRowItem {
    user: UserResponse;
    permission: UserPermissionResponse;
}

interface ApiKeyPermissionRowItem {
    apiKey: ApiKeyResponse;
    permission: ApiKeyPermissionResponse;
}

export default function(props: Props) {
    const { login } = useContext(LoginContext)
    const { tenant } = useContext(TenantContext)
    const [service, setElement] = useState<ServiceResponse | null>(null)
    const [users, setUsers] = useState<Page<UserResponse> | null>(null)
    const [apiKeys, setApiKeys] = useState<Page<ApiKeyResponse> | null>(null)
    const [userPermissionError, setUserPermissionError] = useState<string | null>(null)
    const [createUserPermissionDialogOpen, setCreateUserPermissionDialogOpen] = useQueryParam("upCreate", BooleanParam)
    const [deleteUserPermissionDialogOpen, setDeleteUserPermissionDialogOpen] = useQueryParam("upDelete", BooleanParam)
    const [userPermissionSelectionModel, setUserPermissionSelectionModel] = useState<string[]>([])
    const [apiKeyPermissionError, setApiKeyPermissionError] = useState<string | null>(null)
    const [createApiKeyPermissionDialogOpen, setCreateApiKeyPermissionDialogOpen] = useQueryParam("akpCreate", BooleanParam)
    const [deleteApiKeyPermissionDialogOpen, setDeleteApiKeyPermissionDialogOpen] = useQueryParam("akpDelete", BooleanParam)
    const [apiKeyPermissionSelectionModel, setApiKeyPermissionSelectionModel] = useState<string[]>([])

    // temporarily request simply 1000 elements per page as we 
    // can safely forgo pagnation and server side sorting/filtering etc for now
    const PAGE_NUM = 0;
    const PAGE_SIZE = 1000;

    // avoid holding array references in state - doing so might prevent re-rendering as expected
    // and make state changes more predictable and robust
    const [usersWithPermissions, setUsersWithPermissions] = useState<{ relevantUsers: UserPermissionRowItem[], length: number } | null>(null)
    const [apiKeysWithPermissions, setApiKeysWithPermissions] = useState<{ relevantApiKeys: ApiKeyPermissionRowItem[], length: number } | null>(null)

    useEffect(() => {
        if (service === null && props.serviceId) {
            serviceAdapter.get(login, props.serviceId)
                .then(service => setElement(service))
                .catch(error => {
                    console.error(error)
                })
        }
    }, [props.serviceId]);

    useEffect(() => {
        // load user permissions if not present - set to null if a reload is required (e.g when a new permission is
        // added)
        if (usersWithPermissions === null) {
            getUsersWithPermissionsOnThisService()
                .then(mapping => {
                    setUsersWithPermissions({ relevantUsers: mapping, length: mapping.length })
                })
                .catch(error => setUserPermissionError(error.message || "Unknown error occurred while retrieving user permissions"))
        }
    }, [usersWithPermissions]);

    useEffect(() => {
        // same as with user permissions
        if (apiKeysWithPermissions === null) {
            getApiKeysWithPermissionsOnThisService()
                .then(row => setApiKeysWithPermissions({ relevantApiKeys: row, length: row.length }))
                .catch(error => setApiKeyPermissionError(error.message || "Unknown error occurred while retrieving API key permissions"))
        }
    }, [apiKeysWithPermissions]);


    // get all user permissions with a reference to the actual user
    const getUsersWithPermissionsOnThisService = async (): Promise<UserPermissionRowItem[]> => {
        if (!props.serviceId || !tenant?.tenant?.id) return Promise.resolve([])
        const filter = `tenantId==${tenant.tenant.id};resourceId==${props.serviceId}`
        try {
            const userPermissions = await authAdapter.findUserPermissions(login, pageable(PAGE_NUM, PAGE_SIZE, filter, null))
            if (userPermissions?.elements?.length === 0) return Promise.resolve([]);
            const allUsers = await userAdapter.find(login, new PageableRequest(PAGE_NUM, PAGE_SIZE));
            // combine permissions and user
            const userToPermissions = userPermissions.elements.map(permission => {
                // if there is no user to the permission we have greater problems than a blind [0] access..
                const user = allUsers.elements.filter(user => user.id === permission.userId)[0]
                return { user: user, permission: permission } as UserPermissionRowItem
            });
            return Promise.resolve(userToPermissions)
        } catch (e: Error | any) {
            console.error(e)
            return Promise.reject(e)
        }
    }

    // get all api key permissions with a reference to the actual api key
    const getApiKeysWithPermissionsOnThisService = async (): Promise<ApiKeyPermissionRowItem[]> => {
        if (!props.serviceId || !tenant?.tenant?.id) return Promise.resolve([])
        const filter = `tenantId==${tenant.tenant.id};resourceId==${props.serviceId}`
        try {
            const apiKeyPermissions = await authAdapter.findApiKeyPermissions(login, pageable(PAGE_NUM, PAGE_SIZE, filter, null))
            if (apiKeyPermissions?.elements?.length === 0) return Promise.resolve([])
            const allApiKeys = await apiKeyAdapter.find(login, new PageableRequest(PAGE_NUM, PAGE_SIZE))
            const apiKeyToPermissions = apiKeyPermissions.elements.map(permission => {
                // if there is no api key to the permission we have greater problems than a blind [0] access..
                const apiKey = allApiKeys.elements.filter(apiKey => apiKey.id === permission.apiKeyId)[0]
                return { apiKey: apiKey, permission: permission } as ApiKeyPermissionRowItem
            })
            return Promise.resolve(apiKeyToPermissions);
        } catch (e: Error | any) {
            console.error(e)
            return Promise.reject(e)
        }
    };

    const openCreateUserPermissionDialog = () => {
        userAdapter.find(login, new PageableRequest(0, 1000)).then(response => {
            setUsers(response)
            setCreateUserPermissionDialogOpen(true)
        })
    }
    const openCreateApiKeyPermissionDialog = () => {
        apiKeyAdapter.find(login, new PageableRequest(0, 1000)).then(response => {
            setApiKeys(response)
            setCreateApiKeyPermissionDialogOpen(true)
        })
    }

    const userPermissionColumns: GridColDef[] = [
        {
            field: "id", headerName: "ID", minWidth: 100, filterable: true,
            filterOperators: getGridStringOperators().filter(o => o.value === "equals"),
            valueGetter: (params: GridValueGetterParams) => `${params.row?.permission?.id || ""}`
        },
        {
            field: "user",
            headerName: "User",
            minWidth: 100,
            width: 200,
            valueGetter: (params: GridValueGetterParams) => `${params.row?.user?.firstname || ""} ${params.row?.user?.lastname || ""}`
        },
        {
            field: "actions",
            headerName: "Actions",
            minWidth: 150,
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params: GridCellParams) => {
                return (<>{params.row?.permission?.actions?.map((action: string) => (
                    <Chip sx={{ marginRight: "10px" }} label={nameOfEnumKey(action, PermissionAction)} />))}</>)
            }
        },
        {
            field: "createdAt",
            headerName: "Created at",
            minWidth: 150,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row.permission.createdAt}`
        },
    ]

    const apiKeyPermissionColumns: GridColDef[] = [
        {
            field: "id", headerName: "ID", minWidth: 100, filterable: true,
            filterOperators: getGridStringOperators().filter(o => o.value === "equals"),
            valueGetter: (params: GridValueGetterParams) => `${params.row?.permission?.id || ""}`
        },
        {
            field: "apiKeyId",
            headerName: "Api key",
            minWidth: 200,
            width: 300,
            valueGetter: (params: GridValueGetterParams) => `${params.row?.apiKey?.description || ""}`
        },
        {
            field: "actions",
            headerName: "Actions",
            minWidth: 150,
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params: GridCellParams) => {
                return (<>{params.row?.permission?.actions?.map((action: string) => (
                    <Chip sx={{ marginRight: "10px" }} label={nameOfEnumKey(action, PermissionAction)} />))}</>)
            }
        },
        {
            field: "createdAt",
            headerName: "Created at",
            minWidth: 150,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => `${params.row?.permission?.createdAt || ""}`
        },
    ]

    const buttonBar = (<>
        <Tooltip title="Add user permission" placement="bottom">
            <span><IconButton id="add-user-permission" disabled={userPermissionError != null}
                onClick={openCreateUserPermissionDialog}><AddSharp /><PeopleSharp
                    fontSize="small" /></IconButton></span>
        </Tooltip>
        <Tooltip title="Add api key permission" placement="bottom">
            <span><IconButton id="add-apikey-permission" disabled={apiKeyPermissionError != null}
                onClick={openCreateApiKeyPermissionDialog}><AddSharp /><LockSharp
                    fontSize="small" /></IconButton></span>
        </Tooltip>
        <Tooltip title="Delete user permission " placement="bottom">
            <span><IconButton id="delete-user-permission"
                disabled={userPermissionError != null || userPermissionSelectionModel.length < 1}
                onClick={() => setDeleteUserPermissionDialogOpen(true)}><DeleteSharp /><PeopleSharp
                    fontSize="small" /></IconButton></span>
        </Tooltip>
        <Tooltip title="Delete api key permission " placement="bottom">
            <span><IconButton id="delete-api-key-permission"
                disabled={apiKeyPermissionError != null || apiKeyPermissionSelectionModel.length < 1}
                onClick={() => setDeleteApiKeyPermissionDialogOpen(true)}><DeleteSharp /><LockSharp
                    fontSize="small" /></IconButton></span>
        </Tooltip>
        <UserPermissionCreateDialog id="create-dialog-user-permission"
            title={`Add user permissions`}
            open={createUserPermissionDialogOpen === undefined || createUserPermissionDialogOpen === null ? false : createUserPermissionDialogOpen}
            login={login}
            onSubmitted={() => {
                setCreateUserPermissionDialogOpen(false)
                setUsersWithPermissions(null)
            }}
            onClose={() => setCreateUserPermissionDialogOpen(false)}
            adapter={authAdapter}
            entity={UserPermissionResponseEntityEnum.Service}
            permissionOptions={[PermissionAction.Read, PermissionAction.Update, PermissionAction.Delete, PermissionAction.Execute]}
            resourceId={props.serviceId || ""}
            users={users?.elements || []}
            tenantId={tenant?.tenant?.id || null}
        />
        <ApiKeyPermissionCreateDialog id="create-dialog-api-key-permission"
            title={`Add api key permissions`}
            open={createApiKeyPermissionDialogOpen === undefined || createApiKeyPermissionDialogOpen === null ? false : createApiKeyPermissionDialogOpen}
            login={login}
            onSubmitted={() => {
                setCreateApiKeyPermissionDialogOpen(false)
                setApiKeysWithPermissions(null)
            }}
            onClose={() => setCreateApiKeyPermissionDialogOpen(false)}
            adapter={authAdapter}
            entity={ApiKeyPermissionResponseEntityEnum.Service}
            permissionOptions={[PermissionAction.Read, PermissionAction.Update, PermissionAction.Delete, PermissionAction.Execute]}
            resourceId={props.serviceId || ""}
            apiKeys={apiKeys?.elements || []}
            tenantId={tenant?.tenant?.id || null}
        />
        <PermissionDeleteDialog id="delete-dialog-user-permission"
            title={`Delete user permission`}
            type="user"
            open={deleteUserPermissionDialogOpen === undefined || deleteUserPermissionDialogOpen === null ? false : deleteUserPermissionDialogOpen}
            idsToDelete={userPermissionSelectionModel}
            onClose={() => setDeleteUserPermissionDialogOpen(false)}
            onSubmitted={() => {
                setDeleteUserPermissionDialogOpen(false)
                setUsersWithPermissions(null)
            }}
            login={login}
            adapter={authAdapter}
        />
        <PermissionDeleteDialog id="delete-dialog-api-key-permission"
            title={`Delete api key permission`}
            type="apikey"
            open={deleteApiKeyPermissionDialogOpen === undefined || deleteApiKeyPermissionDialogOpen === null ? false : deleteApiKeyPermissionDialogOpen}
            idsToDelete={apiKeyPermissionSelectionModel}
            onClose={() => setDeleteApiKeyPermissionDialogOpen(false)}
            onSubmitted={() => {
                setDeleteApiKeyPermissionDialogOpen(false)
                setApiKeysWithPermissions(null)
            }}
            login={login}
            adapter={authAdapter}
        />
    </>
    )

    return (
        <AuthenticatedLayout title={`Service ${service?.name} permissions`}
            topRightSection={buttonBar}
            breadcrumbs={[{ name: "Overview", link: "/app" }, {
                name: "Services",
                link: "/app/services",
            }, {
                name: service?.name || "",
                link: `/app/services/${props.serviceId}`,
            }, {
                name: "Permissions",
                link: `/app/services/${props.serviceId}/permissions`,
            }]}>
            <> 
                {userPermissionError != null ? (
                    <FlatPaper>
                        <Alert severity="error">
                            <AlertTitle>Failed to load user permissions</AlertTitle>
                            {userPermissionError}
                        </Alert>
                    </FlatPaper>
                ) : (
                    <FlatPaper id="content" style={{ height: '100%', minHeight: '150px' }}>
                        <DataGrid
                            className="elements" getRowClassName={(() => "element")}
                            columns={userPermissionColumns}
                            getRowId={row => row.permission.id}
                            rows={usersWithPermissions?.relevantUsers || []}
                            loading={usersWithPermissions === null}
                            checkboxSelection
                            onRowSelectionModelChange={selected => {
                                setUserPermissionSelectionModel(selected as string[])
                            }
                            }
                        />
                    </FlatPaper>
                )}
                {apiKeyPermissionError != null ? (
                    <FlatPaper sx={{ marginTop: "20px" }}>
                        <Alert severity="error">
                            <AlertTitle>Failed to load api key permissions</AlertTitle>
                            {apiKeyPermissionError}
                        </Alert>
                    </FlatPaper>
                ) : (
                    <FlatPaper id="content" style={{ marginTop: "20px", height: '100%', minHeight: '150px' }}>
                        <DataGrid
                            className="elements" getRowClassName={(() => "element")}
                            columns={apiKeyPermissionColumns}
                            getRowId={row => row.permission.id}
                            rows={apiKeysWithPermissions?.relevantApiKeys || []}
                            loading={apiKeysWithPermissions === null}
                            checkboxSelection
                            onRowSelectionModelChange={selected => {
                                setApiKeyPermissionSelectionModel(selected as string[])
                            }
                            }
                        />
                    </FlatPaper>
                )}
            </>
        </AuthenticatedLayout>
        )
}