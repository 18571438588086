import React, {createRef, SyntheticEvent, useState} from "react";
import FormDialog from "../../dialog/FormDialog";
import {LoginHolder} from "../../provider/LoginProvider";
import {TenantContractAdapter} from "../../../adapters/TenantContractAdapter";
import {TenantContractResponse} from "../../../generated/models/TenantContractResponse";
import {DATE_FORMAT} from "./TenantContractCreateForm";
import {SelectOption} from "../../form/SelectInput";
import moment from "moment";
import TenantContractEditForm from "./TenantContractEditForm";

export interface Props {
    id: string
    title: string
    element: TenantContractResponse | null
    open: boolean
    onClose?: () => void
    onSubmitted?: (response: TenantContractResponse) => void
    adapter: TenantContractAdapter
    login: LoginHolder | null
}

export default function (props: Props) {

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [licenseTypeError, setLicenseTypeError] = useState<string | null>(null)
    const [licensePackageError, setLicensePackageError] = useState<string | null>(null)

    const licenseTypeRef = createRef<any>()
    const licensePackageRef = createRef<any>()

    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        setLicenseTypeError(null)
        setLicensePackageError(null)
        if (props.onClose) props.onClose()
    }

    const onSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setError(null)
        setLicenseTypeError(null)
        setLicensePackageError(null)

        const data = new FormData(event.currentTarget as HTMLFormElement)
        const validFrom = moment(data.get("validFrom") as string, DATE_FORMAT).toDate()
        const validTo = moment(data.get("validTo") as string, DATE_FORMAT).toDate()
        const licenseType = (licenseTypeRef.current.getValue() as SelectOption[])[0]
        const licensePackage = (licensePackageRef.current.getValue() as SelectOption[])[0]
        if (licenseType.id === "Custom" && licensePackage.id !== "Self-hosted") {
            setLoading(false)
            setError("License type \"Custom\" can only be combined with package \"Self-hosted\"")
            return
        }
        const metricsRetentionInHours = parseInt(data.get("metricsRetentionInDays") as string) * 24
        const logsRetentionInHours = parseInt(data.get("logsRetentionInDays") as string) * 24
        const creditPrice = parseFloat(data.get("creditPrice") as string)
        const currency = props.element!.currency
        const supportPlan = data.get("supportPlan") as string
        const supportResponseAgreement = data.get("supportResponseAgreement") as string
        const supportResolutionAgreement = data.get("supportResolutionAgreement") as string
        const informationText = data.get("informationText") as string | undefined
        const informationType = props.element!.informationType
        const updatedElement = {
            id: props.element!.id,
            tenantId: props.element!.tenantId,
            validFrom, validTo,
            licenseType: licenseType.id,
            licensePackage: licensePackage.id,
            metricsRetentionInHours,
            logsRetentionInHours,
            creditPrice,
            currency,
            supportPlan,
            supportResponseAgreement,
            supportResolutionAgreement,
            informationText,
            informationType,
        } as TenantContractResponse
        props.adapter.updateOne(props.login, {...updatedElement})
                .then(() => {
                    if (props.onSubmitted) props.onSubmitted(updatedElement)
                })
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }

    return (<FormDialog id={props.id} title={props.title} submitButton="Edit"
                        errorTitle="Failed to create element." error={error} open={props.open}
                        isLoading={isLoading}
                        onClose={onClose} onSubmit={onSubmit}>
        <TenantContractEditForm id={`${props.id}-form`}
                                element={props.element}
                                licenseTypeRef={licenseTypeRef}
                                licenseTypeError={licenseTypeError}
                                licensePackageRef={licensePackageRef}
                                licensePackageError={licensePackageError}
        />
    </FormDialog>)
}
