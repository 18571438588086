import {Autocomplete, Checkbox, createFilterOptions, FilterOptionsState, TextField} from "@mui/material";
import {CheckBoxOutlineBlankSharp, CheckBoxSharp} from "@mui/icons-material";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {base64urlEncode} from "../../misc/misc";

export interface SelectOption {
    id: string
    name: string
}

export interface Props {
    id: string
    label: string
    name: string
    options: SelectOption[]
    value?: (string | SelectOption)[]
    onChange?: (selected: (string | SelectOption)[]) => void
    error?: string | null
    helpMessage?: string
    invalidMessage?: string
    pattern?: string
    multiple?: boolean
    readonly?: boolean
    disabled?: boolean
    creatable?: boolean
}

const SelectInput = forwardRef<any, Props>((props, ref) => {
    const DEFAULT_HELP_MESSAGE: string = "Select values."

    const name: string = props.name
    const multiple: boolean = props.multiple === undefined ? false : props.multiple
    const filter = createFilterOptions<string | SelectOption>()

    const [value, setValue] = useState<(string | SelectOption)[]>(props.value === undefined ? [] : props.value)
    const [error, setError] = useState<string | null>(props.error === undefined ? null : props.error)

    useImperativeHandle(ref, () => ({
        getValue: (): SelectOption[] => value.map(s => typeof s === "string" ? {
            id: s,
            name: `Add "${s}"`,
        } : s),
        getError: (): string | null => error,
    }))
    useEffect(() => setValue(props.value === undefined ? [] : props.value), [props.value])
    useEffect(() => setError(props.error === undefined ? null : props.error), [props.error])

    const filterOptions: undefined | ((options: (string | SelectOption)[], params: FilterOptionsState<string | SelectOption>) => any) = props.creatable ? (options, params) => {
        const filtered = filter(options, params)
        const {inputValue} = params
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === (typeof option === "string" ? option : option?.name))
        if (inputValue !== "" && !isExisting) {
            filtered.push({
                id: inputValue,
                name: `Add "${inputValue}"`,
            })
        }
        return filtered
    } : undefined

    return (<>
        <input id={`${props.id}-form-${name}`} type="hidden" name={name}
               defaultValue={JSON.stringify(value.map(e => (typeof e === "string" ? e : e.id)))}/>
        <Autocomplete id={`${props.id}-form-${name}-input`} multiple={multiple} fullWidth disableCloseOnSelect
                      freeSolo={props.creatable}
                      options={props.options || []} size="small"
                      getOptionLabel={(option) => {
                          const o = Array.isArray(option) && option.length > 0 ? option[0] : option
                          return (typeof o === "string" ? o : o?.name) || "?"
                      }}
                      isOptionEqualToValue={(option, value) => {
                          const v = Array.isArray(value) && value.length > 0 ? value[0] : value
                          return (typeof option === "string" ? option : option.id) == (typeof v === "string" ? v : v.id)
                      }}
                      onChange={(event, values) => {
                          let selected: (string | SelectOption)[] = []
                          if (Array.isArray(values)) {
                              selected = values
                          } else if (values) {
                              selected = [values]
                          }
                          setValue(selected)
                          console.debug(`Set value of select options to`, value)
                          let idError: string | null = null
                          selected.forEach(option => {
                              const id = typeof option === "string" ? option : option.id
                              if (props.pattern) {
                                  if (!new RegExp(props.pattern).test(id)) {
                                      idError = ((idError === null ? "" : `${idError} `) + `ID '${id}' is not valid.`)
                                  }
                              }
                          })
                          setError(idError ? `${idError} IDs must match pattern ${props.pattern}.` : null)
                          if (props.onChange) props.onChange(selected)
                      }}
                      value={value}
                      filterOptions={filterOptions}
                      renderOption={(renderProps, option, {selected}) => (
                              <li {...renderProps}>
                                  <Checkbox
                                          name={`${name}-${typeof option === "string" ? base64urlEncode(option) : option.id}`}
                                          checked={selected}
                                          icon={<CheckBoxOutlineBlankSharp fontSize="small"/>}
                                          checkedIcon={<CheckBoxSharp fontSize="small"/>}
                                          style={{marginRight: 8}}/>
                                  {typeof option === "string" ? option : option.name}
                              </li>
                      )}
                      renderInput={(params) => (
                              <TextField {...params}
                                         variant="outlined"
                                         error={props.error != null}
                                         label={props.label}
                                         helperText={error || props.helpMessage || DEFAULT_HELP_MESSAGE}/>
                      )}/>
    </>)
})
export default SelectInput
