import {ServiceResponse} from "../../../generated/models/ServiceResponse";
import {
    getGridBooleanOperators,
    getGridNumericOperators,
    getGridStringOperators,
    GridCellParams,
    GridColDef
} from "@mui/x-data-grid";
import {Box, Chip, Link, Tooltip} from "@mui/material";
import moment from "moment";
import React, {SyntheticEvent} from "react";
import {CheckSharp} from "@mui/icons-material";
import {UserAdapter} from "../../../adapters/UserAdapter";
import {LoginHolder} from "../../provider/LoginProvider";
import UserDisplayName from "../../UserDisplayName";
import {ApiKeyAdapter} from "../../../adapters/ApiKeyAdapter";
import ApiKeyDescription from "../../ApiKeyDescription";
import {nameOfEnumKey} from "../../../misc/enum";
import {PermissionAction} from "../../../generated/models/PermissionAction";
import {ServiceEndpointProtocol} from "../../../generated/models/ServiceEndpointProtocol";
import {ServiceEndpointType} from "../../../generated/models/ServiceEndpointType";
import {ServiceSpecification} from "../../../generated/models/ServiceSpecification";
import {UUID} from "../../../adapters/interfaces";
import ServiceStatusButton from "../../ServiceStatusButton";
import {navigate} from "gatsby";
import {TagAdapter} from "../../../adapters/TagAdapter";
import ServiceTagCount from "../../ServiceTagCount";
import {ServiceAdapter} from "../../../adapters/ServiceAdapter";

export const DATETIME_FORMAT = "yyyy-MM-DD HH:mm:ss"

export const ALIAS_REGEX: string = "^[a-z][a-z0-9-]{1,14}[a-z0-9]$"
export const IMAGE_NAME_REGEX: string = "^([a-z0-9]|[a-zA-Z0-9_{}])([a-z0-9-\\/\\.]|[a-zA-Z0-9_{}]){0,254}([a-z0-9]|[a-zA-Z0-9_{}])$"
export const IMAGE_TAG_REGEX: string = "^[\\w][\\w.\\-{}]{0,127}$"
export const LABEL_NAME_REGEX: string = "^[a-z][a-z0-9\\-\\/\\.{}]{0,251}[a-z0-9]$"
export const LABEL_VALUE_REGEX: string = "^[a-zA-Z0-9{]{0,1}[a-zA-Z0-9-_\\.{}]{0,61}[a-zA-Z0-9}]{0,1}$"
export const LABEL_REGEX: string = "^([a-z][a-z0-9\\-\\/\\.]{0,251}[a-z0-9])=([a-zA-Z0-9]{0,1}[a-zA-Z0-9-_\\.:]{0,61}[a-zA-Z0-9]?)$"
export const VARIABLE_NAME_REGEX: string = "^[a-zA-Z_{][a-zA-Z0-9_\\.{}]{0,2047}$"
export const VARIABLE_VALUE_REGEX: string = ".*"
export const FILE_PATH_REGEX: string = "^[a-zA-Z0-9\\/_][a-zA-Z0-9\\/_\\-\\.]+[a-zA-Z0-9_]$"
export const URI_PATH_REGEX: string = "^[a-zA-Z0-9\\/_{}]{0,250}[a-zA-Z0-9\\/_\\-\\.{}]*[a-zA-Z0-9_}]*$"
export const URI_OPTIONAL_URL_PATH_REGEX: string = "^(http[s]?:\\/\\/(.+:.+@)?[a-z{][a-z0-9-\\/\\.{}]{1,251}[a-z0-9}](:\\d+)?)?[a-zA-Z0-9\\/_{}]{0,250}[a-zA-Z0-9\\/_\\-\\.{}]*[a-zA-Z0-9_}]*(\\?.*)?$"
export const DOMAIN_REGEX: string = "^[a-z{][a-z0-9-\\/\\.{}]{1,251}[a-z0-9}]$"
export const DECIMAL_REGEX: string = "^[0-9]+\.?[0-9]+$"

export const emptySpecification: ServiceSpecification = {
    image: {
        name: "",
        tag: "",
    },
    resources: {
        replicas: 1,
        cpuMinInMilliseconds: 100,
        cpuMaxInMilliseconds: 250,
        memoryMinInMegaBytes: 500,
        memoryMaxInMegaBytes: 500,
        storageInMegaBytes: 0,
        gpu: 0,
    },
    environment: {
        variables: [],
        configFiles: [],
        mounts: [],
        labels: [],
    },
    endpoints: [
        {
            port: 8080,
            path: "/health",
            protocol: ServiceEndpointProtocol.Http,
            type: ServiceEndpointType.Health,
        }
    ],
    telemetry: {
        metricsToRecordAsRegex: [],
    },
    reachability: {
        requestTimeoutInSeconds: 5,
    },
}

export const emptyService: ServiceResponse = {
    id: "",
    version: 0,
    tenantId: "",
    enabled: true,
    alias: "",
    name: "",
    description: "",
    specification: emptySpecification,
    userId: "",
    createdAt: new Date(0),
}

export const serviceSchema = {
    "$schema": "https://json-schema.org/draft-07/schema", // this version is supported by the code editor
    "$id": "/schemas/service",
    "title": "Service",
    "description": "The service in AIOS.",
    "type": "object",
    "required": [
        "enabled",
        "alias",
        "name",
        "specification",
    ],
    "properties": {
        "enabled": {
            "description": "If enabled, the service is actually deployed.",
            "type": "boolean",
        },
        "alias": {
            "description": "The alias is used to provide unique representation. It cannot be changed after the service has been created.",
            "type": "string",
            "pattern": ALIAS_REGEX,
        },
        "name": {
            "description": "A human readable name.",
            "type": "string",
            "pattern": ".{2,50}",
        },
        "specification": {
            "$ref": "/schemas/service-specification"
        },
    }
}

export const serviceSpecificationSchema = {
    "$schema": "https://json-schema.org/draft-07/schema", // this version is supported by the code editor
    "$id": "/schemas/service-specification",
    "title": "Service Specification",
    "description": "The specification of a service.",
    "type": "object",
    "required": [
        "image",
        "resources",
        "environment",
        "endpoints",
        "reachability",
    ],
    "properties": {
        "image": {
            "$ref": "#/$defs/ServiceImage"
        },
        "resources": {
            "$ref": "#/$defs/ServiceResources"
        },
        "environment": {
            "$ref": "#/$defs/ServiceEnvironment"
        },
        "endpoints": {
            "description": "The endpoints of a container.",
            "type": "array",
            "items": {
                "$ref": "#/$defs/ServiceEndpoint"
            },
        },
        "telemetry": {
            "$ref": "#/$defs/ServiceTelemetry"
        },
        "reachability": {
            "$ref": "#/$defs/ServiceReachability"
        },
        "proxy": {
            "$ref": "#/$defs/ServiceProxy"
        },
    },
    "$defs": {
        "ServiceLabel": {
            "description": "The label definition.",
            "type": "object",
            "required": [
                "name",
                "value"
            ],
            "properties": {
                "name": {
                    "description": "The label name.",
                    "type": "string",
                    "pattern": LABEL_NAME_REGEX,
                },
                "value": {
                    "description": "The label value.",
                    "type": "string",
                    "pattern": LABEL_VALUE_REGEX,
                }
            }
        },
        "ServiceRestriction": {
            "description": "The restriction definition.",
            "type": "object",
            "required": [
                "name",
                "value"
            ],
            "properties": {
                "name": {
                    "description": "The restriction name.",
                    "type": "string",
                    "pattern": LABEL_NAME_REGEX,
                },
                "value": {
                    "description": "The restriction value.",
                    "type": "string",
                    "pattern": LABEL_VALUE_REGEX,
                },
                "effect": {
                    "description": "The restriction effect.",
                    "type": ["string", "null"],
                    "pattern": LABEL_VALUE_REGEX,
                }
            }
        },
        "ServiceInitImage": {
            "description": "The initializer image definition to execute a command before the actual image is started.",
            "type": ["object", "null"],
            "required": [
                "command",
            ],
            "properties": {
                "name": {
                    "description": "The container image name.",
                    "type": ["string", "null"],
                    "pattern": IMAGE_NAME_REGEX,
                },
                "tag": {
                    "description": "The container image tag.",
                    "type": ["string", "null"],
                    "pattern": IMAGE_TAG_REGEX,
                },
                "command": {
                    "description": "The command portion to start the image with.",
                    "type": "array",
                    "items": {
                        "type": "string",
                    }
                },
                "isPrivileged": {
                    "description": "Whether the container is executed in privileged mode.",
                    "type": ["boolean", "null"],
                },
                "user": {
                    "description": "The user (i.e. the ID) the container is running with.",
                    "type": ["integer", "null"],
                    "minimum": 0,
                    "maximum": 99999
                },
            }
        },
        "ServiceImage": {
            "description": "The image definition.",
            "type": "object",
            "required": [
                "name",
                "tag",
            ],
            "properties": {
                "name": {
                    "description": "The container image name.",
                    "type": "string",
                    "pattern": IMAGE_NAME_REGEX,
                },
                "tag": {
                    "description": "The container image tag.",
                    "type": "string",
                    "pattern": IMAGE_TAG_REGEX,
                },
                "command": {
                    "description": "The command portion to start the image with.",
                    "type": "array",
                    "items": {
                        "type": "string",
                    }
                },
                "initializer": {
                    "$ref": "#/$defs/ServiceInitImage"
                }
            }
        },
        "ServiceResources": {
            "type": "object",
            "required": [
                "replicas",
                "cpuMinInMilliseconds",
                "cpuMaxInMilliseconds",
                "memoryMinInMegaBytes",
                "memoryMaxInMegaBytes",
                "storageInMegaBytes",
                "gpu"
            ],
            "properties": {
                "isOnDemand": {
                    "description": "Whether the service should be configured as an on-demand deployment, which scales from 0 to the configured replicas.",
                    "type": ["boolean", "null"],
                },
                "coolDownPeriodInSeconds": {
                    "description": "The duration for how long an on-demand service should wait for requests before scaling down.",
                    "type": ["integer", "null"],
                    "minimum": 1,
                    "maximum": 86400
                },
                "replicas": {
                    "description": "The amount of instances to be started.",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 9999
                },
                "cpuMinInMilliseconds": {
                    "description": "The minimum CPUs to assign to the container in milli CPUs.",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 512000
                },
                "cpuMaxInMilliseconds": {
                    "description": "The maximum CPUs to assign to the container in milli CPUs.",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 512000
                },
                "memoryMinInMegaBytes": {
                    "description": "The minimum memory to assign to the container in MB (1 mega byte is 1000 kilo bytes).",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 2147483647
                },
                "memoryMaxInMegaBytes": {
                    "description": "The maximum memory to assign to the container in MB (1 mega byte is 1000 kilo bytes).",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 2147483647
                },
                "storageInMegaBytes": {
                    "description": "The maximum storage to assign to the container in MB (1 mega byte is 1000 kilo bytes).",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 2147483647
                },
                "storageClass": {
                    "description": "The storage class to be used.",
                    "type": ["string", "null"]
                },
                "useStorageForEachReplica": {
                    "description": "Whether to use an extra storage for each replica or not.",
                    "type": ["boolean", "null"]
                },
                "gpu": {
                    "description": "The amount of GPUs to be assigned to the container.",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 9999
                },
                "runSelectors": {
                    "description": "Defines the selectors on which nodes the container must run.",
                    "type": "array",
                    "items": {
                        "$ref": "#/$defs/ServiceLabel"
                    }
                },
                "runRestrictions": {
                    "description": "Defines the restrictions on which nodes the container is allowed to run.",
                    "type": "array",
                    "items": {
                        "$ref": "#/$defs/ServiceLabel"
                    }
                }
            }
        },
        "ServiceVariable": {
            "description": "The variable definition.",
            "type": "object",
            "required": [
                "name",
                "value",
                "encrypted"
            ],
            "properties": {
                "name": {
                    "description": "The variable name.",
                    "type": "string",
                    "pattern": VARIABLE_NAME_REGEX,
                },
                "value": {
                    "description": "The variable value.",
                    "type": "string",
                    "pattern": VARIABLE_VALUE_REGEX,
                },
                "encrypted": {
                    "description": "Whether the value is encrypted or not. When changing an encrypted value, the value will be encrypted when the data is saved.",
                    "type": "boolean"
                }
            }
        },
        "ServiceFile": {
            "description": "The file definition.",
            "type": "object",
            "required": [
                "name",
                "content"
            ],
            "properties": {
                "name": {
                    "description": "The file name including the path. A relative path will be mounted in the working directory in the container.",
                    "type": "string",
                    "pattern": FILE_PATH_REGEX
                },
                "content": {
                    "description": "The file content as a base64 string (not the URL encoding).",
                    "type": "string",
                    "contentEncoding": "base64"
                }
            }
        },
        "ServiceMount": {
            "description": "The mount definition.",
            "type": "object",
            "required": [
                "mountPath",
                "volumeName",
                "readOnly"
            ],
            "properties": {
                "mountPath": {
                    "description": "The path the volume is mounted at.",
                    "type": "string",
                    "pattern": FILE_PATH_REGEX
                },
                "volumeName": {
                    "description": "The volume name to mount.",
                    "type": "string",
                },
                "volumeSubPath": {
                    "description": "The sub path of the volume, which should be mounted to restrict access to other paths on the given volume.",
                    "type": ["string", "null"],
                },
                "readOnly": {
                    "description": "Define if the mount is read only or writable.",
                    "type": "boolean",
                },
            }
        },
        "ServiceEnvironment": {
            "description": "The environment definition.",
            "type": "object",
            "required": [
                "variables",
                // Config files are not used in code editor, which is why it is disabled
                // "configFiles",
                "mounts",
                "labels",
            ],
            "properties": {
                "variables": {
                    "description": "The environment variables.",
                    "type": "array",
                    "items": {
                        "$ref": "#/$defs/ServiceVariable"
                    }
                },
                // Config files are not used in code editor, which is why it is disabled
                // "configFiles": {
                //     "description": "The files to mount into a container.",
                //     "type": "array",
                //     "items": {
                //         "$ref": "#/$defs/ServiceFile"
                //     }
                // },
                "mounts": {
                    "description": "The mounts of a container.",
                    "type": "array",
                    "items": {
                        "$ref": "#/$defs/ServiceMount"
                    }
                },
                "labels": {
                    "description": "The additional labels of a container.",
                    "type": "array",
                    "items": {
                        "$ref": "#/$defs/ServiceLabel"
                    }
                },
            }
        },
        "ServiceEndpoint": {
            "description": "The endpoint definition.",
            "type": "object",
            "required": [
                "port",
                "protocol",
            ],
            "properties": {
                "port": {
                    "description": "The endpoint port.",
                    "type": "integer",
                    "minimum": 1,
                    "maximum": 65535,
                },
                "pathPrefix": {
                    "description": "The endpoint path prefix.",
                    "type": ["string", "null"],
                    "pattern": URI_PATH_REGEX,
                },
                "pathPrefixAware": {
                    "description": "If the service is aware of the path prefix this should be set to true.",
                    "type": ["boolean", "null"],
                },
                "path": {
                    "description": "The endpoint path.",
                    "type": ["string", "null"],
                    "pattern": URI_OPTIONAL_URL_PATH_REGEX,
                },
                "type": {
                    "description": "Endpoint type (ORDINARY: a non specified type, API: an API endpoint, UI: an UI endpoint, HEALTH: an endpoint for checking the service health, READY: an endpoint for checking the service readiness, STARTED: an endpoint for checking if the service has started, METRICS: an endpoint where service specific metrics are being scraped in the OpenMetrics format, OPEN_API: an endpoint providing the OpenAPI specification of the service API, DOCS: an endpoint providing the service documentation which can be plain HTML, Markdown (i.e. CommonMark or Github-flavored) and AsciiDoc)",
                    "type": "string",
                    "enum": [
                        "ORDINARY", "API", "UI", "HEALTH", "READY", "STARTED", "METRICS", "OPEN_API", "DOCS",
                    ],
                },
                "protocol": {
                    "description": "Endpoint protocol",
                    "type": "string",
                    "enum": [
                        "TCP", "HTTP",
                    ],
                },
                "isExposed": {
                    "description": "Whether the container is reachable from the outside or not.",
                    "type": ["boolean", "null"],
                },
                "isSecured": {
                    "description": "Whether the container is reachable without credentials or not.",
                    "type": ["boolean", "null"],
                },
            }
        },
        "ServiceTelemetry": {
            "description": "The telemetry definition to enable observability.",
            "type": "object",
            "required": [
                "metricsToRecordAsRegex",
            ],
            "properties": {
                "metricsToRecordAsRegex": {
                    "description": "The metrics to keep when the container is scraped by Prometheus.",
                    "type": "array",
                    "items": {
                        "type": "string",
                    }
                },
            }
        },
        "ServiceReachability": {
            "description": "The definition if and how the container is reachable from the outside.",
            "type": "object",
            "required": [
                "requestTimeoutInSeconds"
            ],
            "properties": {
                "domain": {
                    "description": "If the domain is set, then the container will be reachable with that domain. A DNS entry must be set accordingly.",
                    "type": ["string", "null"],
                    "pattern": DOMAIN_REGEX,
                },
                "domainUseTls": {
                    "description": "If set to true, then the container will be available through HTTPS.",
                    "type": "boolean",
                },
                "domainTlsCertFileName": {
                    "description": "The configuration file name where to find the contents of the certificate. The configuration file needs to be created in the configuration section. If no cert file name and no key file name is set, the system tries to issue a certificate automatically.",
                    "type": ["string", "null"],
                    "pattern": FILE_PATH_REGEX,
                },
                "domainTlsKeyFileName": {
                    "description": "The configuration file name where to find the contents of the certificate. The configuration file needs to be created in the configuration section. If no cert file name and no key file name is set, the system tries to issue a certificate automatically.",
                    "type": ["string", "null"],
                    "pattern": FILE_PATH_REGEX,
                },
                "requestTimeoutInSeconds": {
                    "description": "The request timeout in seconds.",
                    "type": "integer",
                    "format": "int32",
                },
            }
        },
        "ServiceProxy": {
            "type": ["object", "null"],
            "required": [
                "logLevel",
                "cpuMinInMilliseconds",
                "cpuMaxInMilliseconds",
                "memoryMinInMegaBytes",
                "memoryMaxInMegaBytes",
            ],
            "properties": {
                "logLevel": {
                    "description": "The log level of the service proxy.",
                    "type": "string",
                    "enum": [
                        "TRACE", "DEBUG", "INFO", "WARN", "ERROR", "CRITICAL",
                    ],
                },
                "cpuMinInMilliseconds": {
                    "description": "The minimum CPUs to assign to the container in milli CPUs.",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 512000
                },
                "cpuMaxInMilliseconds": {
                    "description": "The maximum CPUs to assign to the container in milli CPUs.",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 512000
                },
                "memoryMinInMegaBytes": {
                    "description": "The minimum memory to assign to the container in MB (1 mega byte is 1000 kilo bytes).",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 2147483647
                },
                "memoryMaxInMegaBytes": {
                    "description": "The maximum memory to assign to the container in MB (1 mega byte is 1000 kilo bytes).",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 2147483647,
                },
                "routeTimeoutInSeconds": {
                    "description": "The route timeout in seconds. Value 0 disables the timeout.",
                    "type": ["integer", "null"],
                    "minimum": 0,
                    "maximum": 2147483647,
                    "default": 30,
                },
            }
        },
    }
}

export const columns: (login: LoginHolder | null, userAdapter: UserAdapter, serviceAdapter: ServiceAdapter, tagAdapter: TagAdapter) => GridColDef[] = (login, userAdapter, serviceAdapter, tagAdapter) => {
    return [
        {
            field: "id", headerName: "ID", minWidth: 100, filterable: true,
            filterOperators: getGridStringOperators().filter(o => o.value === "equals"),
            sortable: true,
            renderCell: (params: GridCellParams) => {
                if (!params.value) return null
                return (<Tooltip title={params.value}><Box overflow="hidden"
                                                           textOverflow="ellipsis">{params.value}</Box></Tooltip>)
            },
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 120,
            flex: 1,
            filterable: true,
            filterOperators: getGridStringOperators().filter(o => o.value === "equals"),
            sortable: true,
            renderCell: (params: GridCellParams) => {
                return <Link underline="none"
                             href={`/app/services/${params.id}`}
                             onClick={(event: SyntheticEvent) => {
                                 event.preventDefault()
                                 navigate(`/app/services/${params.id}`)
                             }}
                             sx={{
                                 width: "100%",
                                 lineHeight: "52px",
                             }}>{params.value}</Link>
            }
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 200,
            width: 200,
            filterable: false,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                return <ServiceStatusButton serviceId={params.id.toString()} service={params.row}/>
            },
        },
        {
            field: "version",
            headerName: "Version",
            minWidth: 50,
            width: 75,
            filterable: false,
            sortable: false,
            type: "number"
        },
        {
            field: "enabled", headerName: "Enabled", width: 80, filterable: true,
            filterOperators: getGridBooleanOperators().filter(o => o.value === "is"),
            sortable: true,
            renderCell: (params: GridCellParams) => {
                return params.row.enabled ? (<CheckSharp/>) : ""
            }, type: "boolean",
        },
        {
            field: "tags", headerName: "Tags", width: 80, filterable: false,
            filterOperators: getGridNumericOperators().filter(o => o.value === "is"),
            sortable: false,
            renderCell: (params: GridCellParams) => {
                return (<ServiceTagCount serviceId={params.row.id} login={login}
                                         serviceAdapter={serviceAdapter}
                                         tagAdapter={tagAdapter}/>)
            },
        },
        {
            field: "alias",
            headerName: "Alias",
            minWidth: 100,
            width: 200,
            filterable: true,
            filterOperators: getGridStringOperators().filter(o => o.value === "equals"),
            sortable: true,
        },
        {
            field: "userId",
            headerName: "User",
            minWidth: 100,
            width: 120,
            filterable: false,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                return (<UserDisplayName userId={params.row.userId} login={login} adapter={userAdapter}/>)
            }
        },
        {
            field: "createdAt", headerName: "Modified", minWidth: 140, width: 220, filterable: false,
            sortable: true,
            renderCell: (params: GridCellParams) => {
                if (!params.row.createdAt) return null
                const timestamp = moment(params.row.createdAt)
                return (<Box component="span"><Tooltip title={timestamp.local().format()}><Chip
                        label={timestamp.fromNow()}/></Tooltip></Box>)
            },
        },
    ]
}

export const replaceDeployTimeVariables: (stringToReplace: string, id: UUID, alias: string, name: string, spec: ServiceSpecification) => string = (stringToReplace, id, alias, name, spec) => {
    return stringToReplace
            .replace("{{ID}}", id)
            .replace("{{ALIAS}}", alias)
            .replace("{{NAME}}", name)
            .replace("{{REPLICAS}}", spec.resources.replicas.toString())
            .replace("{{DOMAIN}}", spec.reachability.domain || "")
            .replace("{{IMAGE_NAME}}", spec.image.name)
            .replace("{{IMAGE_TAG}}", spec.image.tag)
}
