import React, {useContext, useEffect, useState} from "react";
import {Backdrop, Breakpoint, CircularProgress} from "@mui/material";
import {navigate} from "gatsby";
import {isBrowser} from "../../misc/misc";
import {isLoggedIn, setupLogin} from "../../misc/authentication";
import {UiConfigContext} from "../provider/UiConfigProvider";
import {KeycloakContext} from "../provider/KeycloakProvider";
import AppLayout, {Breadcrumb} from "./AppLayout";
import {LoginContext} from "../provider/LoginProvider";
import {OidcTokenContext} from "../provider/OidcTokenProvider";

export interface Props {
    title: string
    breadcrumbs: Breadcrumb[]
    topRightSection?: NonNullable<React.ReactNode>
    children: NonNullable<React.ReactNode>
    passwordRegexCallback?: (passwordRegex: string) => void
    passwordHelpCallback?: (passwordHelp: string) => void
    size?: Breakpoint
}

export default function AuthenticatedLayout(props: Props) {
    if (!isBrowser) return null // needed for build

    const Keycloak = require("keycloak-js/dist/keycloak.min") // ordinary imports are not working in the browser
    const {uiConfig} = useContext(UiConfigContext)
    const {login, setLogin} = useContext(LoginContext)
    const {oidcToken, setOidcToken} = useContext(OidcTokenContext)
    const {keycloak, setKeycloak} = useContext(KeycloakContext)
    const [loggedIn, setLoggedIn] = useState<boolean>(false)

    const keycloakLogin: () => void = () => {
        const keycloakInstance = Keycloak({
            url: uiConfig!.keycloakAuthServerUrl,
            realm: uiConfig!.keycloakRealm,
            clientId: uiConfig!.keycloakResource,
        })
        keycloakInstance.init({
            onLoad: 'login-required',
            adapter: 'default',
            checkLoginIframe: false,
            silentCheckSsoFallback: false,
            token: oidcToken?.token || undefined,
            refreshToken: oidcToken?.refreshToken || undefined,
            idToken: oidcToken?.idToken || undefined,
            enableLogging: true,
        }).then((isAuthenticated: boolean) => {
            if (isAuthenticated) {
                console.debug(`Authenticated with Keycloak`)
                setKeycloak({isInitialized: true, keycloak: keycloakInstance})
                setOidcToken({
                    token: keycloakInstance.token!,
                    refreshToken: keycloakInstance.refreshToken || null,
                    idToken: keycloakInstance.idToken || null,
                })
                return setupLogin(keycloakInstance.token!, false, setLogin)
                        .then(() => setLoggedIn(true))
            }
            console.debug(`Setting not initialized Keycloak instance`)
            setKeycloak({isInitialized: false, keycloak: keycloakInstance})
            return Promise.resolve()
        }).catch((error: string) => console.error(`Failed to authenticate with Keycloak`, error))
    }

    useEffect(() => {
        if (uiConfig) {
            const isKeycloakEnabled = uiConfig.keycloakEnabled
            if (login && isLoggedIn(login)) {
                console.debug(`User is logged in`)
                setLoggedIn(true)
                if (login.isInternal) {
                    console.debug(`Internal user is logged in`)
                } else {
                    console.debug(`External user is logged in`)
                    if ((!keycloak || !keycloak.isInitialized) && isKeycloakEnabled) {
                        keycloakLogin()
                    }
                }
            } else {
                console.debug(`User is not logged in`)
                if (isKeycloakEnabled) {
                    if (!keycloak || !keycloak.isInitialized) {
                        keycloakLogin()
                    }
                } else {
                    if (!location.pathname.includes('/app/login')) {
                        let redirect = encodeURI(location.href)
                        if (redirect.includes('/app/login')) {
                            redirect = '/app'
                        }
                        console.debug(`User is not logged in; forwarding to login page with redirect ${redirect}`)
                        // noinspection JSIgnoredPromiseFromCall
                        navigate(`/app/login?url=${redirect}`)
                        return
                    }
                }
            }
        }
        console.debug(`UI config not set`)
    }, [uiConfig, login])

    return uiConfig && loggedIn ? (<AppLayout title={props.title} breadcrumbs={props.breadcrumbs}
                                              topRightSection={props.topRightSection}
                                              passwordRegexCallback={props.passwordRegexCallback}
                                              passwordHelpCallback={props.passwordHelpCallback}
                                              size={props.size}>
        {props.children}
    </AppLayout>) : (<Backdrop open={true} sx={{
        backgroundColor: theme => theme.palette.background.default
    }}><CircularProgress/></Backdrop>)
}
