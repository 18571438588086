/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateTagServiceRequest,
    CreateTagServiceRequestFromJSON,
    CreateTagServiceRequestFromJSONTyped,
    CreateTagServiceRequestToJSON,
} from './CreateTagServiceRequest';

/**
 * 
 * @export
 * @interface CreateTagServicesRequest
 */
export interface CreateTagServicesRequest {
    /**
     * 
     * @type {Array<CreateTagServiceRequest>}
     * @memberof CreateTagServicesRequest
     */
    elements: Array<CreateTagServiceRequest>;
}

export function CreateTagServicesRequestFromJSON(json: any): CreateTagServicesRequest {
    return CreateTagServicesRequestFromJSONTyped(json, false);
}

export function CreateTagServicesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTagServicesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elements': ((json['elements'] as Array<any>).map(CreateTagServiceRequestFromJSON)),
    };
}

export function CreateTagServicesRequestToJSON(value?: CreateTagServicesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elements': ((value.elements as Array<any>).map(CreateTagServiceRequestToJSON)),
    };
}

