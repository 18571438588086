import React, {SyntheticEvent, useState} from "react";
import FormDialog from "../../dialog/FormDialog";
import {TagResponse} from "../../../generated/models/TagResponse";
import {LoginHolder} from "../../provider/LoginProvider";
import {TagAdapter} from "../../../adapters/TagAdapter";
import TagCreateForm from "./TagCreateForm";
import {TenantResponse} from "../../../generated/models/TenantResponse";

export interface Props {
    id: string
    title: string
    open: boolean
    onClose?: () => void
    onSubmitted?: (response: TagResponse) => void
    adapter: TagAdapter
    login: LoginHolder | null
    tenant: TenantResponse | null
}

export default function (props: Props) {

    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        setNameError(null)
        if (props.onClose) props.onClose()
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [nameError, setNameError] = useState<string | null>(null)

    const onSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setError(null)
        setNameError(null)

        const data = new FormData(event.currentTarget as HTMLFormElement)
        const name = (data.get("name") as string).trim()
        props.adapter.createOne(props.login, {tenantId: props.tenant!.id, name})
                .then((response: TagResponse) => {
                    if (props.onSubmitted) props.onSubmitted(response)
                })
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }

    return (<FormDialog id={props.id} title={props.title} submitButton="Create"
                        errorTitle="Failed to create element." error={error} open={props.open}
                        isLoading={isLoading}
                        onClose={onClose} onSubmit={onSubmit}>
        <TagCreateForm id={`${props.id}-form`}
                       nameError={nameError}
        />
    </FormDialog>)
}
