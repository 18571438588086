import {CreateUserPermissionRequestEntityEnum} from "../generated/models/CreateUserPermissionRequest";
import {CreateApiKeyPermissionRequestEntityEnum} from "../generated/models/CreateApiKeyPermissionRequest";
import {CreateCreditTransactionRequestTypeEnum} from "../generated/models/CreateCreditTransactionRequest";


export const nameOfEnumKey: (value: string, enumType: { [s: string]: any } | ArrayLike<any>) => string = (value, enumType) => {
    return Object.keys(enumType)[Object.values(enumType).indexOf(value)]
}

export const createUserPermissionEntityEnumFromValue: (value: string) => CreateUserPermissionRequestEntityEnum = (value: string) => {
    const enumName: string | undefined = Object.entries(CreateUserPermissionRequestEntityEnum).find(([_, v]) => v === value)?.[0]
    if (!enumName) throw new Error(`Unknown enum value ${value}`)
    // @ts-ignore
    return CreateUserPermissionRequestEntityEnum[enumName]
}

export const createApiKeyPermissionEntityEnumFromValue: (value: string) => CreateApiKeyPermissionRequestEntityEnum = (value: string) => {
    const enumName: string | undefined = Object.entries(CreateApiKeyPermissionRequestEntityEnum).find(([_, v]) => v === value)?.[0]
    if (!enumName) throw new Error(`Unknown enum value ${value}`)
    // @ts-ignore
    return CreateApiKeyPermissionRequestEntityEnum[enumName]
}

export const createCreditTransactionEnumFromValue: (value: string) => CreateCreditTransactionRequestTypeEnum = (value: string) => {
    const enumName: string | undefined = Object.entries(CreateCreditTransactionRequestTypeEnum).find(([_, v]) => v === value)?.[0]
    if (!enumName) throw new Error(`Unknown enum value ${value}`)
    // @ts-ignore
    return CreateCreditTransactionRequestTypeEnum[enumName]
}
