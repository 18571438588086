import React from "react";
import {Router} from "@reach/router"
import NotFoundView from "../components/view/NotFoundView";
import OverviewView from "../components/view/OverviewView";
import LoginView from "../components/view/LoginView";
import UserView from "../components/view/user/UsersView";
import TenantsView from "../components/view/tenant/TenantsView";
import SettingsView from "../components/view/SettingsView";
import TenantSettingsView from "../components/view/tenant/TenantSettingsView";
import AccountView from "../components/view/AccountView";
import ApiKeysView from "../components/view/apikey/ApiKeysView";
import PasswordResetView from "../components/view/PasswordResetView";
import PasswordForgottenView from "../components/view/PasswordForgottenView";
import ServicesView from "../components/view/service/ServicesView";
import ServiceView from "../components/view/service/ServiceView";
import ServiceVersionsView from "../components/view/service/ServiceVersionsView";
import ServiceApiView from "../components/view/service/ServiceApiView";
import ServicePermissionsView from "../components/view/service/ServicePermissionsView";
import TagsView from "../components/view/tag/TagsView";
import ServiceDocsView from "../components/view/service/ServiceDocsView";
import DocsApiView from "../components/view/DocsApiView";
import DocsView from "../components/view/DocsView";
import TenantContractsView from "../components/view/tenant/TenantContractsView";
import BillingView from "../components/view/billing/BillingView";

export default function App() {
    return (<Router basepath="/app">
        <NotFoundView default/>
        <OverviewView path="/" title="Overview"/>
        <LoginView path="/login"/>
        <DocsView path="/docs" title="Documentation"/>
        <DocsApiView path="/docs/api" title="HTTP API"/>
        <PasswordResetView path="/password-reset" title="Password reset"/>
        <PasswordForgottenView path="/password-forgotten" title="Password forgotten"/>
        <AccountView path="/account" title="Account"/>
        <ApiKeysView path="/account/api-keys" title="API keys" topic="API key"/>
        <UserView path="/users" title="Users" topic="user"/>
        <TenantsView path="/tenants" title="Tenants" topic="tenant"/>
        <TenantSettingsView path="/tenant/settings" title="Tenant settings"/>
        <TenantContractsView path="/tenant/contracts" title="Tenant contracts"/>
        <SettingsView path="/settings" title="Settings"/>
        <ServicesView path="/services" title="Services" topic="service"/>
        <ServiceView path="/services/:serviceId"/>
        <ServiceVersionsView path="/services/:serviceId/versions"/>
        <ServicePermissionsView path="/services/:serviceId/permissions"/>
        <ServiceApiView path="/services/:serviceId/api"/>
        <ServiceDocsView path="/services/:serviceId/docs/:serviceDocsEndpointIndex"/>
        <TagsView path="/tags" title="Tags" topic="tag"/>
        <BillingView path="/billing" title="Billing" topic="billing"/>
    </Router>)
}

// noinspection JSUnusedGlobalSymbols
export const Head = () => (<meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width"/>)
