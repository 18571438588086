/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTagServiceRequest
 */
export interface CreateTagServiceRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTagServiceRequest
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTagServiceRequest
     */
    tagId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTagServiceRequest
     */
    serviceId: string;
}

export function CreateTagServiceRequestFromJSON(json: any): CreateTagServiceRequest {
    return CreateTagServiceRequestFromJSONTyped(json, false);
}

export function CreateTagServiceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTagServiceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantId': json['tenantId'],
        'tagId': json['tagId'],
        'serviceId': json['serviceId'],
    };
}

export function CreateTagServiceRequestToJSON(value?: CreateTagServiceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantId': value.tenantId,
        'tagId': value.tagId,
        'serviceId': value.serviceId,
    };
}

