import {Box, BoxProps, styled, useTheme} from "@mui/material"
import React from "react"
import {ServiceStatusStateEnum} from "../generated/models/ServiceStatus";
import {
    MoreHorizSharp,
    PauseSharp,
    PlayArrowSharp,
    PriorityHighSharp,
    QuestionMarkSharp,
    RemoveDoneSharp,
    RemoveSharp,
    ReplaySharp,
    RotateLeftSharp,
    StopSharp
} from "@mui/icons-material";
import {styleRotateAnimationFast, styleRotateAnimationNormal, styleRotateAnimationSlow} from "./Styles";

export interface Props {
    state: ServiceStatusStateEnum | null
    color?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning"
    iconColor?: string
    useCircle?: boolean
}

export default function (props: Props) {
    const theme = useTheme()
    const color = props.color && props.color !== "inherit" ? theme.palette[props.color] : undefined
    const iconColor = props.iconColor || (color ? color!.contrastText : undefined)

    const IconWrapper = styled(Box)<BoxProps>(() => ({
        backgroundColor: "currentColor",
        borderRadius: "50%",
        width: "1.5rem",
        height: "1.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }))

    let icon = <QuestionMarkSharp fontSize="small" htmlColor={iconColor || theme.palette.background.default}/>

    switch (props.state) {
        case ServiceStatusStateEnum.Pending:
            icon = <PauseSharp sx={{...styleRotateAnimationNormal}} fontSize="small" htmlColor={iconColor}/>
            break
        case ServiceStatusStateEnum.Initializing:
            icon = <MoreHorizSharp sx={{...styleRotateAnimationFast}} fontSize="small" htmlColor={iconColor}/>
            break
        case ServiceStatusStateEnum.Starting:
            icon = <PlayArrowSharp sx={{...styleRotateAnimationFast}} fontSize="small" htmlColor={iconColor}/>
            break
        case ServiceStatusStateEnum.Notready:
            icon = <RemoveDoneSharp fontSize="small" htmlColor={iconColor}/>
            break
        case ServiceStatusStateEnum.Running:
            icon = <ReplaySharp sx={{...styleRotateAnimationSlow}} fontSize="small" htmlColor={iconColor}/>
            break
        case ServiceStatusStateEnum.Degraded:
            icon = <RotateLeftSharp sx={{...styleRotateAnimationSlow}} fontSize="small" htmlColor={iconColor}/>
            break
        case ServiceStatusStateEnum.Failed:
            icon = <PriorityHighSharp fontSize="small" htmlColor={iconColor}/>
            break
        case ServiceStatusStateEnum.Stopping:
            icon = <StopSharp sx={{...styleRotateAnimationFast}} fontSize="small" htmlColor={iconColor}/>
            break
        case ServiceStatusStateEnum.Stopped:
            icon = <RemoveSharp fontSize="small" htmlColor={iconColor}/>
            break
    }

    return props.useCircle ? (
            <IconWrapper color={color ? color.main : undefined} component="span">{icon}</IconWrapper>) : icon

}
