import {Autocomplete, Checkbox, TextField} from "@mui/material";
import {CheckBoxOutlineBlankSharp, CheckBoxSharp} from "@mui/icons-material";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {ApiKeyResponse} from "../../generated/models/ApiKeyResponse";

export interface Props {
    id: string
    label?: string
    name?: string
    options: ApiKeyResponse[]
    value?: ApiKeyResponse[]
    onChange?: (tenants: ApiKeyResponse[]) => void
    error?: string | null
    helpMessage?: string
    invalidMessage?: string
    multiple?: boolean
    readonly?: boolean
    disabled?: boolean
}

const ApiKeyInput = forwardRef<any, Props>((props, ref) => {
    const DEFAULT_LABEL: string = "Api keys"
    const DEFAULT_NAME: string = "apikeys"
    const DEFAULT_HELP_MESSAGE: string = "Select an api key."

    const name: string = props.name || DEFAULT_NAME
    const multiple: boolean = props.multiple === undefined ? true : props.multiple

    useImperativeHandle(ref, () => ({
        getValue: (): ApiKeyResponse[] => value
    }))
    const [value, setValue] = useState<ApiKeyResponse[]>(props.value === undefined ? [] : props.value)
    const [error, setError] = useState<string | null>(props.error === undefined ? null : props.error)

    useEffect(() => setValue(props.value === undefined ? [] : props.value), [props.value])
    useEffect(() => setError(props.error === undefined ? null : props.error), [props.error])

    return (<>
        <input id={`${props.id}-form-${name}`} type="hidden" name={name} defaultValue={value.map(e => e.id).join()}/>
        <Autocomplete id={`${props.id}-form-${name}-input`} multiple={multiple} fullWidth disableCloseOnSelect
                      options={props.options || []} size="small"
                      getOptionLabel={(option: ApiKeyResponse | undefined) => option?.description || "?"}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, values) => {
                          let apiKeys: ApiKeyResponse[] = []
                          if (Array.isArray(values)) {
                              apiKeys = values
                          } else if (values) {
                              apiKeys = [values]
                          }
                          setValue(apiKeys)
                          console.debug(`Set value of api keys to`, value)
                          if (props.onChange) props.onChange(apiKeys)
                      }}
                      value={value}
                      renderOption={(renderProps, option, {selected}) => (
                              <li {...renderProps}>
                                  <Checkbox id={`${props.id}-option-${option.id}`}
                                            name={`${name}-${option.id}`} checked={selected}
                                            icon={<CheckBoxOutlineBlankSharp fontSize="small"/>}
                                            checkedIcon={<CheckBoxSharp fontSize="small"/>}
                                            style={{marginRight: 8}}/>
                                  {option.description}
                              </li>
                      )}
                      renderInput={(params) => (
                              <TextField {...params}
                                         variant="outlined"
                                         error={props.error != null}
                                         label={props.label || DEFAULT_LABEL}
                                         helperText={error || props.helpMessage || DEFAULT_HELP_MESSAGE}/>
                      )}/>
    </>)
})
export default ApiKeyInput
