import {Box, Skeleton, Tooltip} from "@mui/material";
import React, {useEffect, useState} from "react";
import {LoginHolder} from "./provider/LoginProvider";
import {UserAdapter} from "../adapters/UserAdapter";
import {displayNameOf} from "../misc/misc";

export interface Props {
    userId: string
    login: LoginHolder | null
    adapter: UserAdapter
}

/**
 * Loads and displays user information for human representation based on a user id.
 */
export default function (props: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [name, setName] = useState<string | null>(null)

    useEffect(() => {
        setIsLoading(true)
        props.adapter.getName(props.login, props.userId)
                .then(response => {
                    if (response !== null) {
                        setName(displayNameOf(response.email, response.firstname || null, response.lastname || null))
                    }
                })
                .finally(() => setIsLoading(false))
    }, [])

    return isLoading ? (<Skeleton sx={{width: "100%"}}/>) : name === null ? (
            <Tooltip title={props.userId}><Box overflow="hidden" component="span"
                                               textOverflow="ellipsis">{props.userId}</Box></Tooltip>) : (
            <Tooltip title={name}><Box overflow="hidden" component="span"
                                       textOverflow="ellipsis">{name}</Box></Tooltip>)
}
