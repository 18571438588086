// Generated with https://echarts.apache.org/en/theme-builder.html
import {blue, lightTheme, pink, violet} from "../../../theme";

export const light = {
    "color": [violet.main, blue.main, pink.main],
    "backgroundColor": lightTheme.palette.background.paper,
    "textStyle": {},
    "title": {
        "textStyle": {
            "color": lightTheme.palette.text.primary
        }, "subtextStyle": {
            "color": lightTheme.palette.text.secondary
        }
    },
    "line": {
        "itemStyle": {
            "borderWidth": "2"
        }, "lineStyle": {
            "width": "2"
        }, "symbolSize": "6", "symbol": "emptyRect", "smooth": true
    },
    "radar": {
        "itemStyle": {
            "borderWidth": "2"
        }, "lineStyle": {
            "width": "2"
        }, "symbolSize": "6", "symbol": "emptyRect", "smooth": true
    },
    "bar": {
        "itemStyle": {
            "barBorderWidth": 0, "barBorderColor": "#ccc"
        }
    },
    "pie": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        }
    },
    "scatter": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        }
    },
    "boxplot": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        }
    },
    "parallel": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        }
    },
    "sankey": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        }
    },
    "funnel": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        }
    },
    "gauge": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        }
    },
    "candlestick": {
        "itemStyle": {
            "color": violet.light,
            "color0": "transparent",
            "borderColor": violet.main,
            "borderColor0": blue.main,
            "borderWidth": "2"
        }
    },
    "graph": {
        "itemStyle": {
            "borderWidth": 0, "borderColor": "#ccc"
        },
        "lineStyle": {
            "width": 1, "color": "#aaa"
        },
        "symbolSize": "6",
        "symbol": "emptyRect",
        "smooth": true,
        "color": [violet.main, blue.main, pink.main],
        "label": {
            "color": "#eee"
        }
    },
    "map": {
        "itemStyle": {
            "areaColor": "#f3f3f3", "borderColor": "#666", "borderWidth": 0.5
        }, "label": {
            "color": "#000"
        }, "emphasis": {
            "itemStyle": {
                "areaColor": "#ddd", "borderColor": "#666", "borderWidth": 1
            }, "label": {
                "color": "#666"
            }
        }
    },
    "geo": {
        "itemStyle": {
            "areaColor": "#f3f3f3", "borderColor": "#666", "borderWidth": 0.5
        }, "label": {
            "color": "#000"
        }, "emphasis": {
            "itemStyle": {
                "areaColor": "#ddd", "borderColor": "#666", "borderWidth": 1
            }, "label": {
                "color": "#666"
            }
        }
    },
    "categoryAxis": {
        "axisLine": {
            "show": true, "lineStyle": {
                "color": "#cccccc"
            }
        }, "axisTick": {
            "show": false, "lineStyle": {
                "color": "#333"
            }
        }, "axisLabel": {
            "show": true, "color": "#999999"
        }, "splitLine": {
            "show": true, "lineStyle": {
                "color": ["#eeeeee"]
            }
        }, "splitArea": {
            "show": false, "areaStyle": {
                "color": ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
            }
        }
    },
    "valueAxis": {
        "axisLine": {
            "show": true, "lineStyle": {
                "color": "#cccccc"
            }
        }, "axisTick": {
            "show": false, "lineStyle": {
                "color": "#333"
            }
        }, "axisLabel": {
            "show": true, "color": "#999999"
        }, "splitLine": {
            "show": true, "lineStyle": {
                "color": ["#eeeeee"]
            }
        }, "splitArea": {
            "show": false, "areaStyle": {
                "color": ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
            }
        }
    },
    "logAxis": {
        "axisLine": {
            "show": true, "lineStyle": {
                "color": "#cccccc"
            }
        }, "axisTick": {
            "show": false, "lineStyle": {
                "color": "#333"
            }
        }, "axisLabel": {
            "show": true, "color": "#999999"
        }, "splitLine": {
            "show": true, "lineStyle": {
                "color": ["#eeeeee"]
            }
        }, "splitArea": {
            "show": false, "areaStyle": {
                "color": ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
            }
        }
    },
    "timeAxis": {
        "axisLine": {
            "show": true, "lineStyle": {
                "color": "#cccccc"
            }
        }, "axisTick": {
            "show": false, "lineStyle": {
                "color": "#333"
            }
        }, "axisLabel": {
            "show": true, "color": "#999999"
        }, "splitLine": {
            "show": true, "lineStyle": {
                "color": ["#eeeeee"]
            }
        }, "splitArea": {
            "show": false, "areaStyle": {
                "color": ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"]
            }
        }
    },
    "toolbox": {
        "iconStyle": {
            "borderColor": "#999"
        }, "emphasis": {
            "iconStyle": {
                "borderColor": "#666"
            }
        }
    },
    "legend": {
        "textStyle": {
            "color": "#999999"
        }
    },
    "tooltip": {
        "axisPointer": {
            "lineStyle": {
                "color": "#ccc", "width": 1
            }, "crossStyle": {
                "color": "#ccc", "width": 1
            }
        }
    },
    "timeline": {
        "lineStyle": {
            "color": "#999999", "width": "1"
        }, "itemStyle": {
            "color": "#999999", "borderWidth": "0"
        }, "controlStyle": {
            "color": "#999999", "borderColor": "#999999", "borderWidth": 0.5
        }, "checkpointStyle": {
            "color": "#999999", "borderColor": "transparent"
        }, "label": {
            "color": "#999999"
        }, "emphasis": {
            "itemStyle": {
                "color": "#999999"
            }, "controlStyle": {
                "color": "#999999", "borderColor": "#999999", "borderWidth": 0.5
            }, "label": {
                "color": "#999999"
            }
        }
    },
    "visualMap": {
        "color": [violet.dark, violet.main, violet.light]
    },
    "dataZoom": {
        "backgroundColor": "rgba(0,0,0,0)",
        "dataBackgroundColor": "rgba(255,255,255,0.3)",
        "fillerColor": "rgba(167,183,204,0.4)",
        "handleColor": "#a7b7cc",
        "handleSize": "100%",
        "textStyle": {
            "color": "#333"
        }
    },
    "markPoint": {
        "label": {
            "color": "#eee"
        }, "emphasis": {
            "label": {
                "color": "#eee"
            }
        }
    }
}
