import React, {SyntheticEvent, useContext, useEffect, useState} from "react";
import {
    Alert,
    AlertTitle,
    Avatar,
    Badge,
    Box,
    Breadcrumbs,
    Breakpoint,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Drawer,
    Grid,
    IconButton,
    Link,
    ListItemAvatar,
    ListItemButton,
    ListSubheader,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    useMediaQuery
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import {navigate} from "gatsby";
import {displayName, isBrowser} from "../../misc/misc";
import {
    AccountCircleSharp,
    AppsSharp,
    ArticleSharp,
    BrightnessHighSharp,
    BrightnessLowSharp,
    BusinessSharp,
    CachedSharp,
    DashboardSharp,
    HistoryEduSharp,
    LockSharp,
    MoreVertSharp,
    NotificationsNoneSharp,
    NotificationsSharp,
    OpenInNewSharp,
    PeopleSharp,
    ReceiptLongSharp,
    SellSharp,
    SettingsSharp,
    ViewCarouselSharp,
    VisibilitySharp,
    VpnKeySharp
} from "@mui/icons-material";
import Logo from "../Logo";
import {ThemeTypeContext} from "../provider/ThemeTypeProvider";
import {PermissionAction, PermissionEntity, permitsWithoutIds} from "../../misc/authorization";
import NotificationGroupList from "../Notifications";
import PushMessages from "../PushMessages";
import {UiConfigContext} from "../provider/UiConfigProvider";
import {LoadingButton} from "@mui/lab";
import {KeycloakContext} from "../provider/KeycloakProvider";
import {MenuButton} from "../Buttons";
import {DrawerTitle} from "../Misc";
import {LoginContext} from "../provider/LoginProvider";
import {OidcTokenContext} from "../provider/OidcTokenProvider";
import {TenantContext} from "../provider/TenantProvider";
import PasswordInput from "../form/PasswordInput";
import {getPasswordHelp, getPasswordPolicy} from "../../adapters/GlobalSettingAdapter";
import {TenantResponse} from "../../generated/models/TenantResponse";
import {authAdapter, notificationAdapter, Page, PageableRequest, tenantAdapter} from "../../adapters/interfaces";
import {Count} from "../../generated/models/Count";
import {NotificationResponse} from "../../generated/models/NotificationResponse";
import {apiPost, apiPut} from "../../misc/api";
import {emptyNotificationGroup, groupNotifications, NotificationGroup} from "../../adapters/NotificationAdapter";
import {stratos} from "../../theme";
import {TITLE_SUFFIX} from "./GeneralLayout";
import Amqp from "../../misc/amqp";

const DRAWER_WIDTH = 240
const SERVICE_PATH_REGEX = "services\\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}"

export interface Breadcrumb {
    link: string
    name: string
}

export interface Props {
    title: string
    breadcrumbs: Breadcrumb[]
    topRightSection?: NonNullable<React.ReactNode>
    children: NonNullable<React.ReactNode>
    passwordRegexCallback?: (passwordRegex: string) => void
    passwordHelpCallback?: (passwordHelp: string) => void
    size?: Breakpoint
}

export default function AppLayout(props: Props) {
    if (!isBrowser) return null // needed for build

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
    const {themeType, setThemeType} = useContext(ThemeTypeContext)
    const {uiConfig} = useContext(UiConfigContext)
    const {login, setLogin} = useContext(LoginContext)
    const {tenant, setTenant} = useContext(TenantContext)
    const {setOidcToken} = useContext(OidcTokenContext)
    const {keycloak} = useContext(KeycloakContext)
    const [mobileOpen, setMobileOpen] = useState(false)
    const [userMenuAnchor, setUserMenuAnchor] = useState<HTMLElement | null>(null)
    const [notificationMenuAnchor, setNotificationMenuAnchor] = useState<HTMLElement | null>(null)
    const [tenantMenuAnchor, setTenantMenuAnchor] = useState<HTMLElement | null>(null)
    const [tenantsPage, setTenantsPage] = useState<Page<TenantResponse> | null>(null)
    const [tenantDialogOpen, setTenantDialogOpen] = useState<boolean>(false)
    const [passwordRegex, setPasswordRegex] = useState<string | null>(null)
    const [passwordHelp, setPasswordHelp] = useState<string | null>(null)
    const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState<boolean>(false)
    const [changePasswordFormError, setChangePasswordFormError] = useState<string | null>(null)
    const [changePasswordFormCurrentPasswordError, setChangePasswordFormCurrentPasswordError] = useState<string | null>(null)
    const [changePasswordFormPasswordError, setChangePasswordFormPasswordError] = useState<string | null>(null)
    const [isChangePasswordDialogLoading, setChangePasswordDialogLoading] = useState<boolean>(false)
    const [unseenNotificationsLoaded, setUnseenNotificationsLoaded] = useState<boolean>(false)
    const [unseenNotifications, setUnseenNotifications] = useState<number>(0)
    const [hasMoreNotifications, setHasMoreNotifications] = useState<boolean>(false)
    const [currentNotificationPageNumber, setCurrentNotificationPageNumber] = useState<number>(0)
    const [notificationUpdatedSeen, setNotificationUpdatedSeen] = useState<string | null>(null)
    const [notificationUpdatedUnseen, setNotificationUpdatedUnseen] = useState<string | null>(null)
    const [notifications, setNotifications] = useState<NotificationResponse[]>([])
    const [notificationGroup, setNotificationGroup] = useState<NotificationGroup>(emptyNotificationGroup)
    const [pushNotificationQueue, setPushNotificationQueue] = useState<NotificationResponse[]>([])
    const [recentlyQueuedPushNotification, setRecentlyQueuedPushNotification] = useState<NotificationResponse | null>(null)
    const [amqp, setAmqp] = useState<Amqp | null>(uiConfig && uiConfig.amqpEnabled && login ? Amqp.getInstance(uiConfig.amqpUrl, uiConfig.amqpVirtualHost, login.id, login.token) : null)

    const toggleDrawer = () => {
        setMobileOpen(!mobileOpen)
    }
    const toggleTheme = () => {
        setThemeType(themeType === "light" ? "dark" : "light")
    }
    const handleLogout = () => {
        if (!isBrowser) return // needed for build
        authAdapter.logout(login).finally(() => {
            setLogin(null)
            setOidcToken(null)
            if (keycloak) {
                keycloak.keycloak.logout()
            } else { // noinspection JSIgnoredPromiseFromCall
                navigate("/app/login")
            }
        })
    }
    const openTenantDialog = () => {
        tenantAdapter.find(login, new PageableRequest(0, 1000, null, "+alias")).then(response => {
            setTenantsPage(response)
            setTenantDialogOpen(true)
        })
    }
    const closeTenantDialog = (reload: boolean) => {
        setTenantDialogOpen(false)
        setTenantsPage(null)
        if (reload) {
            if (RegExp(SERVICE_PATH_REGEX).test(location.href || "")) {
                navigate("/app/services")
            } else {
                location.reload()
            }
        }
    }
    const handleChangePassword = (event: SyntheticEvent): void => {
        event.preventDefault()
        setChangePasswordDialogLoading(true)
        setChangePasswordFormError(null)
        setChangePasswordFormCurrentPasswordError(null)
        setChangePasswordFormPasswordError(null)
        const target = event.target as typeof event.target & {
            currentPassword: { value: string }
            password: { value: string }
        }
        const currentPassword = target.currentPassword.value
        const password = target.password.value
        if (!login) {
            setChangePasswordFormError("Failed to get user token from logged in session. Please logout and login to try again.")
            return
        }
        apiPost(null, "/login", {email: login.email, password: currentPassword}, {}, false)
                .then(() => apiPut(login, `/users/${login.id}/password`, {id: login.id, password})
                        .then(() => {
                            console.debug("Successfully changed password", login.id)
                            setChangePasswordDialogOpen(false)
                        })
                        .catch(error => {
                            setChangePasswordFormError(error.message)
                        }))
                .catch(() => {
                    setChangePasswordDialogOpen(true)
                    setChangePasswordFormError("Changing password failed")
                    setChangePasswordFormCurrentPasswordError("Current password is not correct")
                })
                .finally(() => setChangePasswordDialogLoading(false))
    }
    const handleNotificationUpdate = (update: NotificationResponse): void => {
        setNotifications(notifications!.map(n => n.id === update.id ? update : n))
    }
    const handleMarkAllAsSeen = () => {
        const notificationsToUpdate = notifications.filter(notification => !notification.seen)
        const ids = notificationsToUpdate.map(notification => notification.id)
        notificationAdapter.updateSeen(login, ids)
                .then(() => {
                    const updatedNotifications: NotificationResponse[] = notificationsToUpdate
                            .map(notification => Object.assign({}, notification, {seen: true}))
                    console.debug(`Marked ${updatedNotifications.length} notifications as seen`)
                    setNotifications(updatedNotifications)
                    setUnseenNotifications(unseenNotifications - updatedNotifications.length)
                })
    }
    const enqueuePushNotification = (notification: NotificationResponse): void => {
        console.debug(`Got new notification`)
        if (!notification.seen) setNotificationUpdatedUnseen(notification.id)
        const newPushNotificationQueue = [...pushNotificationQueue]
        newPushNotificationQueue.push(notification)
        setPushNotificationQueue(newPushNotificationQueue)
        setRecentlyQueuedPushNotification(notification)
    }

    useEffect(() => {
        if (!passwordRegex) {
            getPasswordPolicy(login).then(value => {
                setPasswordRegex(value)
                if (props.passwordRegexCallback)
                    props.passwordRegexCallback(value)
            })
        }
        if (!passwordHelp) {
            getPasswordHelp(login).then(value => {
                setPasswordHelp(value)
                if (props.passwordHelpCallback)
                    props.passwordHelpCallback(value)
            })
        }
        if (!unseenNotificationsLoaded) {
            setUnseenNotificationsLoaded(true)
            notificationAdapter.countUnseen(login).then((response: Count) => {
                setUnseenNotifications(response.count)
            })
        }
    })
    useEffect(() => {
        if (Boolean(notificationMenuAnchor)) {
            notificationAdapter.find(login, new PageableRequest()).then((response: Page<NotificationResponse>) => {
                setNotifications(response.elements)
                setHasMoreNotifications(response.totalPages > 1)
                setCurrentNotificationPageNumber(response.number)
            })
        }
    }, [notificationMenuAnchor])
    useEffect(() => {
        setNotificationGroup(groupNotifications(notifications))
    }, [notifications])
    useEffect(() => {
        if (notificationUpdatedSeen) {
            setUnseenNotifications(unseenNotifications - 1)
            setNotificationUpdatedSeen(null)
        }
    }, [notificationUpdatedSeen])
    useEffect(() => {
        if (notificationUpdatedUnseen) {
            setUnseenNotifications(unseenNotifications + 1)
            setNotificationUpdatedUnseen(null)
        }
    }, [notificationUpdatedUnseen])
    useEffect(() => {
        if (recentlyQueuedPushNotification) {
            const newNotifications = [...notifications]
            newNotifications.push(recentlyQueuedPushNotification)
            setNotifications(newNotifications)
        }
    }, [recentlyQueuedPushNotification])
    useEffect(() => {
        if (amqp && amqp.isConnected()) {
            const userId = login!.id
            if (!amqp.isNotificationsSubscribed(userId)) {
                amqp.subscribeToNotifications(userId, enqueuePushNotification)
                        .then(() => console.debug(`Subscribed to notifications`))
                        .catch(err => console.error(`Failed to subscribe to notifications:`, err))
            }
        } else if (amqp && !amqp.isConnected()) {
            amqp.connect().then(() => {
                const userId = login!.id
                if (!amqp.isNotificationsSubscribed(userId)) {
                    amqp.subscribeToNotifications(userId, enqueuePushNotification)
                            .then(() => console.debug(`Subscribed to notifications`))
                            .catch(err => console.error(`Failed to subscribe to notifications:`, err))
                }
            })
        }
    }, [amqp])
    useEffect(() => {
        if (uiConfig && uiConfig.amqpEnabled && login && !amqp) {
            const instance = Amqp.getInstance(uiConfig.amqpUrl, uiConfig.amqpVirtualHost, login.id, login.token)
            instance.connect().then(() => {
                setAmqp(instance)
            })
        }
    }, [uiConfig, login])
    useEffect(() => {
        document.title = `${props.title}${TITLE_SUFFIX}`
    }, [])

    const drawer = (
            <div>
                <DrawerTitle id="menu-logo" container justifyContent="center" alignItems="center">
                    <Logo height={28}/>
                </DrawerTitle>
                <Divider/>
                <List>
                    <ListItemButton component="a" id="menu-item-overview" href="/app"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault()
                                        navigate("/app")
                                    }}>
                        <ListItemIcon><DashboardSharp/></ListItemIcon>
                        <ListItemText primary="Overview"/>
                    </ListItemButton>
                    {login && tenant && permitsWithoutIds([PermissionEntity.SERVICE], [PermissionAction.READ], login, tenant?.tenant.id) ? (
                            <ListItemButton component="a" id="menu-item-services"
                                            href="/app/services"
                                            onClick={(e: React.MouseEvent) => {
                                                e.preventDefault()
                                                navigate("/app/services")
                                            }}>
                                <ListItemIcon><AppsSharp/></ListItemIcon>
                                <ListItemText primary="Services"/>
                            </ListItemButton>
                    ) : null}
                    {login && tenant && permitsWithoutIds([PermissionEntity.TAG], [PermissionAction.READ], login, tenant?.tenant.id) ? (
                            <ListItemButton component="a" id="menu-item-tags"
                                            href="/app/tags"
                                            onClick={(e: React.MouseEvent) => {
                                                e.preventDefault()
                                                navigate("/app/tags")
                                            }}>
                                <ListItemIcon><SellSharp/></ListItemIcon>
                                <ListItemText primary="Tags"/>
                            </ListItemButton>
                    ) : null}
                    {uiConfig && uiConfig.billingEnabled && login && tenant && permitsWithoutIds([PermissionEntity.CREDITTRANSACTION], [PermissionAction.READ], login, tenant?.tenant.id) ? (
                            <ListItemButton component="a" id="menu-item-billing"
                                            href="/app/billing"
                                            onClick={(e: React.MouseEvent) => {
                                                e.preventDefault()
                                                navigate("/app/billing")
                                            }}>
                                <ListItemIcon><ReceiptLongSharp/></ListItemIcon>
                                <ListItemText primary="Billing"/>
                            </ListItemButton>
                    ) : null}
                    {login && tenant && permitsWithoutIds([PermissionEntity.USER], [PermissionAction.READ], login, tenant?.tenant.id) ? (
                            <ListItemButton component="a" id="menu-item-users"
                                            href="/app/users"
                                            onClick={(e: React.MouseEvent) => {
                                                e.preventDefault()
                                                navigate("/app/users")
                                            }}>
                                <ListItemIcon><PeopleSharp/></ListItemIcon>
                                <ListItemText primary="Users"/>
                            </ListItemButton>
                    ) : null}
                    {login && tenant && permitsWithoutIds([PermissionEntity.TENANT], [PermissionAction.READ], login, tenant?.tenant.id) ? (
                            <ListItemButton component="a" id="menu-item-tenants"
                                            href="/app/tenants"
                                            onClick={(e: React.MouseEvent) => {
                                                e.preventDefault()
                                                navigate("/app/tenants")
                                            }}>
                                <ListItemIcon><BusinessSharp/></ListItemIcon>
                                <ListItemText primary="Tenants"/>
                            </ListItemButton>
                    ) : null}
                    {login && tenant && permitsWithoutIds([PermissionEntity.SETTING], [PermissionAction.READ], login, tenant?.tenant.id) ? (
                            <ListItemButton component="a" id="menu-item-settings"
                                            href="/app/settings"
                                            onClick={(e: React.MouseEvent) => {
                                                e.preventDefault()
                                                navigate("/app/settings")
                                            }}>
                                <ListItemIcon><SettingsSharp/></ListItemIcon>
                                <ListItemText primary="Settings"/>
                            </ListItemButton>
                    ) : null}
                    {login && tenant && permitsWithoutIds([PermissionEntity.TENANT], [PermissionAction.CREATE, PermissionAction.UPDATE], login, tenant?.tenant.id) && uiConfig?.grafanaEnabled && uiConfig?.grafanaUrl ? (
                            <ListItemButton component="a" id="menu-item-grafana"
                                            href={uiConfig?.grafanaUrl} target="_blank">
                                <ListItemIcon><OpenInNewSharp/></ListItemIcon>
                                <ListItemText primary="Grafana"/>
                            </ListItemButton>
                    ) : null}
                </List>
                <Grid container sx={{bottom: 0, position: 'absolute'}}>
                    <Grid item xs={12}>
                        <Toolbar variant="dense" sx={{paddingLeft: '16px', paddingRight: '16px'}}>
                            <Typography id="current-tenant" sx={{flexGrow: 1}}
                                        noWrap>{tenant?.tenant.name || 'No tenant!'}</Typography>
                            <IconButton id="tenant-more-menu"
                                        edge="end"
                                        aria-controls="user-menu"
                                        aria-haspopup="true"
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => setTenantMenuAnchor(e.currentTarget)}
                                        color="inherit">
                                <MoreVertSharp/>
                            </IconButton>
                            <Menu id="tenant-menu" keepMounted elevation={1}
                                  anchorEl={tenantMenuAnchor}
                                  anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                                  transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                  open={Boolean(tenantMenuAnchor)}
                                  onClose={() => setTenantMenuAnchor(null)}>
                                <ListSubheader>{tenant?.tenant.name || 'No tenant!'}</ListSubheader>
                                <MenuItem id="change-tenant-dialog-open-button" onClick={() => {
                                    openTenantDialog()
                                    setTenantMenuAnchor(null)
                                }}>
                                    <ListItemIcon sx={{minWidth: '35px'}}>
                                        <ViewCarouselSharp/>
                                    </ListItemIcon> Change tenant
                                </MenuItem>
                                {permitsWithoutIds([PermissionEntity.TENANTCONTRACT], [PermissionAction.READ], login, tenant?.tenant.id) ? (
                                        <MenuItem component="a"
                                                  href="/app/tenant/contracts"
                                                  onClick={(e: React.MouseEvent) => {
                                                      e.preventDefault()
                                                      navigate("/app/tenant/contracts")
                                                  }}>
                                            <ListItemIcon sx={{minWidth: '35px'}}>
                                                <HistoryEduSharp/>
                                            </ListItemIcon> Tenant contracts
                                        </MenuItem>
                                ) : null}
                                {permitsWithoutIds([PermissionEntity.TENANT], [PermissionAction.CREATE, PermissionAction.UPDATE], login, tenant?.tenant.id) ? (
                                        <MenuItem component="a"
                                                  href="/app/tenant/settings"
                                                  onClick={(e: React.MouseEvent) => {
                                                      e.preventDefault()
                                                      navigate("/app/tenant/settings")
                                                  }}>
                                            <ListItemIcon sx={{minWidth: '35px'}}>
                                                <SettingsSharp/>
                                            </ListItemIcon> Tenant settings
                                        </MenuItem>
                                ) : null}
                                <MenuItem component="a"
                                          href="/app/docs"
                                          onClick={(e: React.MouseEvent) => {
                                              e.preventDefault()
                                              navigate("/app/docs")
                                          }}>
                                    <ListItemIcon sx={{minWidth: '35px'}}>
                                        <ArticleSharp/>
                                    </ListItemIcon> Documentation
                                </MenuItem>
                                {uiConfig?.version ? (<MenuItem component="div" sx={{
                                    fontSize: "12px",
                                    fontFamily: "Fira Code, monospace",
                                    color: theme.palette.mode === "dark" ? theme.palette.grey["600"] : theme.palette.grey["400"]
                                }}>v{uiConfig.version}</MenuItem>) : null}
                            </Menu>
                            <Dialog fullScreen={fullScreen} open={tenantDialogOpen}
                                    onClose={() => closeTenantDialog(false)}
                                    transitionDuration={0}>
                                <DialogTitle>Change tenant</DialogTitle>
                                <DialogContent>
                                    {tenantsPage ? (<List dense>
                                        {tenantsPage.elements.map(t => (
                                                <ListItemButton key={t.id} id={`change-tenant-dialog-${t.id}`}
                                                                disabled={t.id === tenant?.tenant.id}
                                                                onClick={() => {
                                                                    setTenant({
                                                                        tenant: t,
                                                                        currency: tenant?.currency || "EUR",
                                                                    })
                                                                    closeTenantDialog(true)
                                                                }}>
                                                    <ListItemAvatar><Avatar>{t.name.charAt(0)}</Avatar></ListItemAvatar>
                                                    <ListItemText primary={t.name} secondary={t.alias}/>
                                                </ListItemButton>
                                        ))}
                                    </List>) : 'No tenants.'}
                                </DialogContent>
                                <DialogActions>
                                    <Button id="tenant-dialog-cancel" color="primary"
                                            onClick={() => closeTenantDialog(false)}>Cancel</Button>
                                </DialogActions>
                            </Dialog>
                        </Toolbar>
                    </Grid>
                </Grid>
            </div>
    )

    return (
            <div style={{display: 'flex'}}>
                <AppBar position="fixed" sx={{
                    [theme.breakpoints.up('md')]: {
                        width: `calc(100% - ${DRAWER_WIDTH}px)`,
                        marginLeft: DRAWER_WIDTH,
                    },
                    backgroundColor: theme.palette.background.paper,
                    borderBottom: `1px solid ${theme.palette.divider}`
                }}>
                    <Toolbar variant="dense">
                        <MenuButton color="inherit" aria-label="open drawer" onClick={toggleDrawer}
                                    sx={{
                                        marginRight: theme.spacing(2),
                                        [theme.breakpoints.up('md')]: {display: 'none'}
                                    }}><MenuIcon/> AIOS</MenuButton>
                        <Typography id="page-title" variant="h6" noWrap
                                    sx={{flexGrow: 1}}>{props.title}</Typography>
                        <IconButton id="notification-menu-button" edge="end" size="large"
                                    aria-controls="notification-menu"
                                    aria-haspopup="true"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => setNotificationMenuAnchor(e.currentTarget)}
                                    color="inherit">
                            {unseenNotifications < 1 ? (<NotificationsNoneSharp/>) : (
                                    <Badge badgeContent={unseenNotifications} color="primary">
                                        <NotificationsSharp/>
                                    </Badge>
                            )}
                        </IconButton>
                        <IconButton id="user-menu-button" edge="end" size="large"
                                    aria-controls="user-menu"
                                    aria-haspopup="true"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => setUserMenuAnchor(e.currentTarget)}
                                    color="inherit">
                            <AccountCircleSharp/>
                        </IconButton>
                        <Menu id="notification-menu" keepMounted elevation={1}
                              anchorEl={notificationMenuAnchor}
                              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                              transformOrigin={{horizontal: 'right', vertical: 'top'}}
                              open={Boolean(notificationMenuAnchor)}
                              onClose={() => setNotificationMenuAnchor(null)}>
                            <Grid container direction="row" sx={{width: '360px'}}>
                                <Grid item xs={11}>
                                    <Typography color="textSecondary" style={{paddingLeft: '16px', paddingTop: '8px'}}>
                                        Notifications
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip title="Mark all as read" placement="bottom">
                                        <>
                                            <IconButton id="notification-read-all-button" size="small"
                                                        onClick={handleMarkAllAsSeen}
                                                        disabled={unseenNotifications < 1}>
                                                <VisibilitySharp fontSize="small"/>
                                            </IconButton>
                                        </>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <NotificationGroupList notifications={notificationGroup}
                                                           currentNotificationPage={currentNotificationPageNumber}
                                                           hasMore={hasMoreNotifications}
                                                           markedAsSeenHook={notification => {
                                                               handleNotificationUpdate(notification)
                                                               setNotificationUpdatedSeen(notification.id)
                                                           }}
                                                           markedAsUnseenHook={notification => {
                                                               handleNotificationUpdate(notification)
                                                               setNotificationUpdatedUnseen(notification.id)
                                                           }}
                                                           loadedMoreHook={notificationPage => {
                                                               setNotifications(notifications.concat(notificationPage.elements))
                                                               setHasMoreNotifications(notificationPage.totalPages > (notificationPage.number + 1))
                                                               setCurrentNotificationPageNumber(notificationPage.number)
                                                           }}
                                    />
                                </Grid>
                            </Grid>
                        </Menu>
                        <Menu id="user-menu" keepMounted elevation={1}
                              anchorEl={userMenuAnchor}
                              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                              transformOrigin={{horizontal: 'right', vertical: 'top'}}
                              open={Boolean(userMenuAnchor)}
                              onClose={() => setUserMenuAnchor(null)}>
                            <ListSubheader>{displayName(login)}</ListSubheader>
                            <MenuItem id="user-menu-account-item" onClick={() => {
                                // noinspection JSIgnoredPromiseFromCall
                                navigate("/app/account")
                                setUserMenuAnchor(null)
                            }}>
                                <ListItemIcon sx={{minWidth: '35px'}}><AccountCircleSharp/></ListItemIcon>
                                Account
                            </MenuItem>
                            <MenuItem id="user-menu-apikeys-item" onClick={() => {
                                // noinspection JSIgnoredPromiseFromCall
                                navigate("/app/account/api-keys")
                                setUserMenuAnchor(null)
                            }}>
                                <ListItemIcon sx={{minWidth: '35px'}}><VpnKeySharp/></ListItemIcon> API keys
                            </MenuItem>
                            <MenuItem id="user-menu-change-password-item" onClick={() => {
                                if (login && login.isInternal) {
                                    setChangePasswordFormError(null)
                                    setChangePasswordFormCurrentPasswordError(null)
                                    setChangePasswordFormPasswordError(null)
                                    setChangePasswordDialogOpen(true)
                                    setUserMenuAnchor(null)
                                } else {
                                    if (keycloak) keycloak.keycloak.accountManagement()
                                    else console.warn(`Keycloak instance not set`)
                                }
                            }}>
                                <ListItemIcon sx={{minWidth: '35px'}}><CachedSharp/></ListItemIcon> Change
                                password
                            </MenuItem>
                            <MenuItem id="user-menu-theme-item" onClick={() => {
                                toggleTheme()
                            }}>
                                <ListItemIcon sx={{minWidth: '35px'}}>
                                    {themeType === 'light' ? (<BrightnessLowSharp/>) : (<BrightnessHighSharp/>)}
                                </ListItemIcon> {themeType === 'light' ? 'Dark' : 'Light'} theme
                            </MenuItem>
                            <MenuItem id="user-menu-logout-item" onClick={() => {
                                handleLogout()
                                setUserMenuAnchor(null)
                            }}>
                                <ListItemIcon sx={{minWidth: '35px'}}><LockSharp/></ListItemIcon> Logout
                            </MenuItem>
                        </Menu>
                        <Dialog fullScreen={fullScreen} id="change-password-dialog" open={changePasswordDialogOpen}
                                onClose={() => setChangePasswordDialogOpen(false)}
                                transitionDuration={0}>
                            <form id="change-password-dialog-form" method="post" onSubmit={handleChangePassword}>
                                <DialogTitle>Change password</DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={2} sx={{marginTop: '-8px'}}>
                                        {changePasswordFormError != null ? (
                                                <Grid item xs={12}>
                                                    <DialogContentText component="div">
                                                        <Alert id="change-password-dialog-form-error" severity="error">
                                                            <AlertTitle>Changing password failed</AlertTitle>
                                                            {changePasswordFormError}
                                                        </Alert>
                                                    </DialogContentText>
                                                </Grid>
                                        ) : null}
                                        <Grid item xs={12}>
                                            <PasswordInput id={`change-password-dialog-form-current-password`}
                                                           label="Current password"
                                                           name="currentPassword"
                                                           error={changePasswordFormCurrentPasswordError}
                                                           helpMessage={`The current password.`}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PasswordInput id={`change-password-dialog-form-password`}
                                                           label="New password"
                                                           pattern={passwordRegex || undefined}
                                                           error={changePasswordFormCurrentPasswordError}
                                                           helpMessage={`The password for the user (${passwordHelp}).`}/>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button id="change-password-dialog-cancel" color="primary"
                                            onClick={() => setChangePasswordDialogOpen(false)}>Cancel</Button>
                                    <LoadingButton id="change-password-dialog-submit" color="primary"
                                                   variant="contained"
                                                   type="submit"
                                                   loading={isChangePasswordDialogLoading}>Change</LoadingButton>
                                </DialogActions>
                            </form>
                        </Dialog>
                    </Toolbar>
                </AppBar>
                <Box component="nav" sx={{
                    [theme.breakpoints.up('md')]: {
                        width: DRAWER_WIDTH,
                        flexShrink: 0,
                    }
                }} aria-label="navigation">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Box component="div" sx={{
                        [theme.breakpoints.down('md')]: {display: 'block'},
                        display: 'none'
                    }}>
                        <Drawer variant="temporary"
                                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                open={mobileOpen}
                                onClose={toggleDrawer}
                                sx={{'& .MuiDrawer-paper': {width: DRAWER_WIDTH}}}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}>
                            {drawer}
                        </Drawer>
                    </Box>
                    <Box component="div" sx={{
                        [theme.breakpoints.up('md')]: {display: 'block'},
                        display: 'none'
                    }}>
                        <Drawer sx={{'& .MuiDrawer-paper': {width: DRAWER_WIDTH}}} variant="permanent"
                                open>{drawer}</Drawer>
                    </Box>
                </Box>
                <Box component="main" sx={{
                    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : stratos.light,
                    flexGrow: 1,
                    padding: theme.spacing(3),
                    height: '100vh',
                    overflow: 'auto',
                }}
                >
                    <Box component="div" sx={theme.mixins.toolbar}/>
                    <Container maxWidth={props.size || "lg"}>
                        <Grid container direction="row">
                            <Grid item xs={12} sm={8}>
                                <Breadcrumbs aria-label="breadcrumb" sx={{lineHeight: '48px'}}>
                                    {
                                        props.breadcrumbs.map((breadcrumb, i) => {
                                            if (i + 1 == props.breadcrumbs.length) {
                                                return (<Typography key={`breadcrumb-${i}`}
                                                                    color="textPrimary">{breadcrumb.name}</Typography>)
                                            } else {
                                                return (<Link key={`breadcrumb-${i}`} color="inherit"
                                                              onClick={e => {
                                                                  e.preventDefault()
                                                                  navigate(breadcrumb.link)
                                                              }}
                                                              href={breadcrumb.link}>{breadcrumb.name}</Link>)
                                            }
                                        })
                                    }
                                </Breadcrumbs>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                {props.topRightSection ? (
                                        <Grid container
                                              justifyContent="flex-end">{props.topRightSection}</Grid>) : null}
                            </Grid>
                            <Grid item xs={12}>{props.children}</Grid>
                            <PushMessages open={pushNotificationQueue.length != 0}
                                          notification={pushNotificationQueue.length == 0 ? null : pushNotificationQueue[0]}
                                          closedHook={() => {
                                              setPushNotificationQueue(pushNotificationQueue.length > 1 ? pushNotificationQueue.slice(1) : [])
                                          }}/>
                        </Grid>
                    </Container>
                </Box>
            </div>
    )
}
