import React, {SyntheticEvent, useContext, useEffect, useState} from "react";
import {
    Alert,
    Box,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Tooltip
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import GeneralLayout from "../../components/layout/GeneralLayout";
import {StringParam, useQueryParam} from "use-query-params";
import {HelpOutlineSharp} from "@mui/icons-material";
import {apiGet, apiPost} from "../../misc/api";
import {LoadingButton} from "@mui/lab";
import {LoginContext} from "../provider/LoginProvider";
import {getPasswordHelp, getPasswordPolicy} from "../../adapters/GlobalSettingAdapter";
import {RouteComponentProps} from "@reach/router";

export interface Props extends RouteComponentProps {
    title: string
}

export default function (props: Props) {
    const theme = useTheme()
    const {login} = useContext(LoginContext)
    const [tokenError, setTokenError] = useState<string | null>(null)
    const [token] = useQueryParam("token", StringParam)
    const [success, setSuccess] = useState<string | null>(null)
    const [passwordRegex, setPasswordRegex] = useState<string | null>(null)
    const [passwordHelp, setPasswordHelp] = useState<string | null>(null)
    const [resetPasswordFormError, setResetPasswordFormError] = useState<string | null>(null)
    const [resetPasswordFormPasswordError, setResetPasswordFormPasswordError] = useState<string | null>(null)
    const [resetPasswordFormPasswordConfirmationError, setResetPasswordFormPasswordConfirmationError] = useState<string | null>(null)
    const [isLoading, setLoading] = useState<boolean>(false)

    const handleInvalidPassword = (event: SyntheticEvent): void => {
        const target = event.target as typeof event.target & {
            pattern: string
        }
        setResetPasswordFormPasswordError(`Value must match pattern ${target.pattern}`)
    }
    const handleSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setResetPasswordFormError(null)
        setResetPasswordFormPasswordError(null)
        setResetPasswordFormPasswordConfirmationError(null)
        const target = event.target as typeof event.target & {
            newPassword: { value: string }
            confirmPassword: { value: string }
        }
        const password = target.newPassword.value
        const confirmPassword = target.confirmPassword.value
        if (password !== confirmPassword) {
            setResetPasswordFormPasswordConfirmationError("Passwords do not match")
            return
        }
        apiPost(login, `/password-reset?token=${token}`, JSON.stringify({password}), {}, false)
                .then(() => setSuccess("Password successfully reset"))
                .catch(error => setResetPasswordFormError(error.message))
                .finally(() => setLoading(false))
    }

    useEffect(() => {
        apiGet(login, `/password-reset?token=${token}`, {}, false)
                .catch(error => setTokenError(error.message))
        getPasswordPolicy(login).then(value => setPasswordRegex(value))
        getPasswordHelp(login).then(value => setPasswordHelp(value))
    }, [token])

    return (<GeneralLayout title={props.title}>
        <Box m={2}>Reset your password</Box>
        {tokenError != null ? (
                <div style={{width: '100%'}}>
                    <Alert severity="error">
                        {tokenError}
                    </Alert>
                    <Grid container style={{marginTop: '20px'}}>
                        <Grid item xs>
                            <Link href={`${process.env.GATSBY_PATH_PREFIX}/app/login`} variant="body2">
                                Back to login
                            </Link>
                        </Grid>
                    </Grid>
                </div>
        ) : (
                <div style={{width: '100%'}}>
                    {success != null ? (
                            <Alert severity="success" sx={{marginBottom: '20px'}}>
                                {success}
                            </Alert>
                    ) : null}
                    {resetPasswordFormError != null ? (
                            <Alert severity="error" sx={{marginBottom: '20px'}}>
                                {resetPasswordFormError}
                            </Alert>
                    ) : null}
                    <form id="password-reset-form" method="post" onSubmit={handleSubmit}>
                        <FormControl id="reset-password-dialog-form-new-password" variant="outlined"
                                     error={resetPasswordFormPasswordError != null} fullWidth
                                     required>
                            <InputLabel htmlFor="new-password">New password</InputLabel>
                            <OutlinedInput id="new-password" name="newPassword" label="New password"
                                           type="password" autoFocus endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip
                                            title={`The password for the user (${passwordHelp}).`}
                                            placement="left">
                                        <IconButton tabIndex={-1} edge="end"><HelpOutlineSharp/></IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            } inputProps={{pattern: passwordRegex}}
                                           onInvalid={handleInvalidPassword}/>
                            <Grid container>
                                <Grid item xs={12}>
                                    <FormHelperText>{resetPasswordFormPasswordError ? resetPasswordFormPasswordError : passwordHelp}</FormHelperText>
                                </Grid>
                            </Grid>
                        </FormControl>
                        <FormControl id="reset-password-dialog-form-password-confirmation" variant="outlined"
                                     error={resetPasswordFormPasswordConfirmationError != null} fullWidth
                                     required>
                            <InputLabel htmlFor="confirm-password">Confirm password</InputLabel>
                            <OutlinedInput id="confirm-password" name="confirmPassword" label="Confirm password"
                                           type="password" endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip
                                            title="The password confirmation for the new password."
                                            placement="left">
                                        <IconButton tabIndex={-1} edge="end"><HelpOutlineSharp/></IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            }/>
                            <Grid container>
                                <Grid item xs={12}>
                                    <FormHelperText>{resetPasswordFormPasswordConfirmationError ? resetPasswordFormPasswordConfirmationError : "Confirm the password"}</FormHelperText>
                                </Grid>
                            </Grid>
                        </FormControl>
                        <LoadingButton id="password-reset"
                                       type="submit"
                                       fullWidth
                                       variant="contained"
                                       color="primary"
                                       loading={isLoading}
                                       sx={{margin: theme.spacing(3, 0, 2)}}>Reset password</LoadingButton>
                        <Grid container>
                            <Grid item xs>
                                <Link href={`${process.env.GATSBY_PATH_PREFIX}/app/login`} variant="body2">
                                    Back to login
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
        )}
    </GeneralLayout>)
}
