import React, {useContext, useEffect} from "react";
import "swagger-ui-react/swagger-ui.css";
import {UiConfigContext} from "../provider/UiConfigProvider";
import SwaggerUI from "swagger-ui-react";
import {RouteComponentProps} from "@reach/router";
import {TITLE_SUFFIX} from "../layout/GeneralLayout";

export interface Props extends RouteComponentProps {
    title: string
}

export default function (props: Props) {
    const {uiConfig} = useContext(UiConfigContext)

    useEffect(() => {
        document.title = `${props.title}${TITLE_SUFFIX}`
    }, [])

    return (<div>
        <SwaggerUI url={uiConfig?.openApiSpecUrl} persistAuthorization={true}/>
    </div>)
}
