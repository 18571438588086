import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {loader} from "@monaco-editor/react";
import {Box, CircularProgress, Theme} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {mint, pink, violet} from "../theme";

export enum CodeEditorMarkerSeverity { // taken from monaco because import is not working with server side rendering
    Hint = 1,
    Info = 2,
    Warning = 4,
    Error = 8
}

export interface CodeEditorMarker { // taken from monaco because import is not working with server side rendering
    owner: string
    resource: any
    severity: CodeEditorMarkerSeverity
    code?: string | {
        value: string
        target: any
    }
    message: string
    source?: string
    startLineNumber: number
    startColumn: number
    endLineNumber: number
    endColumn: number
    relatedInformation?: any[]
    tags?: any[]
}

export interface Props {
    id: string
    name?: string
    width: string
    height: string
    language: "json" | "yaml" | "xml" | "html" | "javascript" | "css" | "shell" | "plaintext" | string
    value: string
    schemas?: any[]
    /**
     * The id of the form so that on submit the form data contains the value of the editor.
     */
    formId?: string
    onReady?: () => void
    onChangeMarkers?: (markers: CodeEditorMarker[]) => void
}

const CodeEditor = forwardRef<any, Props>((props, ref) => {
    const theme = useTheme()
    const editorRef = useRef<any | null>(null)
    useImperativeHandle(ref, () => ({
        getEditorValue: (): string => editorRef.current?.getValue() || ""
    }))

    const [isLoading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        setLoading(true)
        let model: any | null = null
        if (process.env.GATSBY_MONACO_EDITOR_PATH) {
            console.debug("Using bundled monaco editor")
            // noinspection TypeScriptValidateJSTypes
            loader.config({paths: {vs: process.env.GATSBY_MONACO_EDITOR_PATH}})
        } else {
            console.debug("Using monaco editor from CDN")
        }
        loader.init().then(monaco => {
            const modelUri = monaco.Uri.parse(`aios://${props.id}/${new Date()}`); // a made up unique URI
            const wrapper = document.getElementById(props.id)
            if (!wrapper) return Promise.reject(`Wrapper with id ${props.id} not found`)

            model = monaco.editor.createModel(props.value, props.language, modelUri)
            model.updateOptions({tabSize: 2})
            if (props.language === "json" && props.schemas) {
                monaco.languages.json.jsonDefaults.setDiagnosticsOptions(jsonSchemaDiagnosticOptions(props.schemas))
            }
            // base themes can be found here: https://github.com/Microsoft/vscode/blob/1.68.1/src/vs/editor/standalone/common/themes.ts
            monaco.editor.defineTheme("AIOS", theme.palette.mode === "dark" ? darkTheme(theme) : lightTheme(theme))
            editorRef.current = monaco.editor.create(wrapper, {
                model: model,
                ...editorOptions,
            })
            if (props.formId) {
                const form = document.getElementById(props.formId)
                if (form) {
                    form.addEventListener("formdata", e => {
                        // @ts-ignore
                        e.formData.append(props.name || "editor", editorRef.current?.getValue() || "")
                    })
                } else {
                    console.warn(`Code editor cannot submit field because the form with id ${props.formId} does not exist!`)
                }
            }
            monaco.editor.onDidChangeMarkers(uris => {
                console.info("URIs: ", uris)
                if (!uris) {
                    return
                }
                const markers = monaco.editor.getModelMarkers({resource: uris[0]})
                console.info("Found markers: ", markers)
                if (props.onChangeMarkers && markers) {
                    props.onChangeMarkers(markers.map(m => m as unknown as CodeEditorMarker))
                }
            })
            if (props.onReady) props.onReady()
            setLoading(false)
        }).catch(error => console.error("Error initializing code editor", error))
        return () => {
            // cleanup model
            if (model) {
                console.debug("Disposing model")
                model.dispose()
            }
            if (props.onChangeMarkers) props.onChangeMarkers([])
        }
    }, [])

    return (<>
        {isLoading ? (<Box component="span" display="flex" justifyContent="center"
                           alignItems="center"><CircularProgress/></Box>) : null}
        <Box id={props.id}
             component="div"
             sx={{width: props.width, height: props.height}}/>
    </>)
})
export default CodeEditor

export const editorOptions: any = {
    theme: "AIOS",
    fontFamily: "Fira Code",
    fontLigatures: true,
    minimap: {enabled: false},
    cursorBlinking: "smooth",
    scrollBeyondLastLine: false,
    fixedOverflowWidgets: true,
}

export const jsonSchemaDiagnosticOptions: (schemas: any[]) => any = schemas => {
    return {
        validate: true,
        enableSchemaRequest: false,
        schemaValidation: "error",
        schemaRequest: "error",
        schemas: schemas?.map((s, i) => {
            if (i === 0) return {uri: s["$id"], fileMatch: ["*"], schema: s}
            else return {uri: s["$id"], schema: s}
        }),
    }
}

export const lightTheme: (theme: Theme) => any = theme => {
    return {
        base: "vs",
        inherit: false,
        rules: [
            {token: "", foreground: theme.palette.grey["600"], background: theme.palette.grey["200"]},
            {token: "invalid", foreground: theme.palette.error.light},

            {token: "variable", foreground: mint.dark},
            {token: "variable.predefined", foreground: mint.light},
            {token: "variable.parameter", foreground: mint.light},
            {token: "constant", foreground: mint.dark},
            {token: "comment", foreground: theme.palette.grey["500"]},
            {token: "number", foreground: theme.palette.primary.main},
            {token: "number.hex", foreground: theme.palette.primary.light},
            {token: "regexp", foreground: theme.palette.secondary.dark},
            {token: "annotation", foreground: theme.palette.secondary.main},
            {token: "type", foreground: mint.dark},

            {token: "delimiter", foreground: theme.palette.grey["400"]},
            {token: "delimiter.html", foreground: theme.palette.grey["700"]},
            {token: "delimiter.xml", foreground: theme.palette.grey["700"]},

            {token: "tag", foreground: violet.light},
            {token: "tag.id.pug", foreground: violet.main},
            {token: "tag.class.pug", foreground: violet.main},
            {token: "meta.scss", foreground: theme.palette.secondary.dark},
            {token: "meta.tag", foreground: theme.palette.secondary.main},
            {token: "metatag", foreground: theme.palette.secondary.main},
            {token: "metatag.content.html", foreground: theme.palette.primary.light},
            {token: "metatag.html", foreground: theme.palette.primary.main},
            {token: "metatag.xml", foreground: theme.palette.primary.main},
            {token: "metatag.php", fontStyle: "bold"},

            {token: "key", foreground: mint.dark},
            {token: "string.key.json", foreground: mint.dark},
            {token: "string.value.json", foreground: theme.palette.secondary.main},

            {token: "attribute.name", foreground: mint.light},
            {token: "attribute.value", foreground: theme.palette.secondary.main},
            {token: "attribute.value.number.css", foreground: theme.palette.secondary.dark},
            {token: "attribute.value.unit.css", foreground: theme.palette.secondary.dark},
            {token: "attribute.value.hex.css", foreground: theme.palette.secondary.dark},

            {token: "string", foreground: theme.palette.secondary.dark},
            {token: "string.sql", foreground: theme.palette.secondary.dark},

            {token: "keyword", foreground: mint.dark},
            {token: "keyword.flow", foreground: mint.dark},
            {token: "keyword.json", foreground: pink.dark},
            {token: "keyword.flow.scss", foreground: mint.dark},

            {token: "operator.scss", foreground: violet.main},
            {token: "operator.sql", foreground: violet.main},
            {token: "operator.swift", foreground: violet.main},
            {token: "predefined.sql", foreground: theme.palette.secondary.main},
        ],
        colors: {
            "editor.background": theme.palette.background.paper,
            "editor.foreground": "#000000",
            "editor.inactive.selection": theme.palette.grey["100"],
            "editor.indent.guides": theme.palette.grey["200"],
            "editor.active.indent.guides": theme.palette.grey["400"],
            "editor.selection.highlight": "#0dc7ff1f",
        }
    }
}

export const darkTheme: (theme: Theme) => any = theme => {
    return {
        base: "vs-dark",
        inherit: false,
        rules: [
            {token: "", foreground: theme.palette.grey["500"], background: theme.palette.grey["900"]},
            {token: "invalid", foreground: theme.palette.error.main},

            {token: "variable", foreground: mint.main},
            {token: "variable.predefined", foreground: mint.light},
            {token: "variable.parameter", foreground: mint.light},
            {token: "constant", foreground: mint.main},
            {token: "comment", foreground: theme.palette.grey["500"]},
            {token: "number", foreground: theme.palette.primary.main},
            {token: "number.hex", foreground: theme.palette.primary.light},
            {token: "regexp", foreground: theme.palette.secondary.light},
            {token: "annotation", foreground: theme.palette.secondary.main},
            {token: "type", foreground: mint.main},

            {token: "delimiter", foreground: theme.palette.grey["400"]},
            {token: "delimiter.html", foreground: theme.palette.grey["700"]},
            {token: "delimiter.xml", foreground: theme.palette.grey["700"]},

            {token: "tag", foreground: violet.light},
            {token: "tag.id.pug", foreground: violet.main},
            {token: "tag.class.pug", foreground: violet.main},
            {token: "meta.scss", foreground: theme.palette.secondary.light},
            {token: "meta.tag", foreground: theme.palette.secondary.main},
            {token: "metatag", foreground: theme.palette.secondary.main},
            {token: "metatag.content.html", foreground: theme.palette.primary.light},
            {token: "metatag.html", foreground: theme.palette.primary.main},
            {token: "metatag.xml", foreground: theme.palette.primary.main},
            {token: "metatag.php", fontStyle: "bold"},

            {token: "key", foreground: mint.light},
            {token: "string.key.json", foreground: mint.main},
            {token: "string.value.json", foreground: theme.palette.secondary.main},

            {token: "attribute.name", foreground: mint.light},
            {token: "attribute.value", foreground: theme.palette.secondary.main},
            {token: "attribute.value.number.css", foreground: theme.palette.secondary.light},
            {token: "attribute.value.unit.css", foreground: theme.palette.secondary.light},
            {token: "attribute.value.hex.css", foreground: theme.palette.secondary.light},

            {token: "string", foreground: theme.palette.secondary.light},
            {token: "string.sql", foreground: theme.palette.secondary.light},

            {token: "keyword", foreground: mint.main},
            {token: "keyword.flow", foreground: mint.main},
            {token: "keyword.json", foreground: theme.palette.primary.light},
            {token: "keyword.flow.scss", foreground: mint.main},

            {token: "operator.scss", foreground: violet.main},
            {token: "operator.sql", foreground: violet.main},
            {token: "operator.swift", foreground: violet.main},
            {token: "predefined.sql", foreground: theme.palette.secondary.main},
        ],
        colors: {
            "editor.background": theme.palette.background.paper,
            "editor.foreground": "#000000",
            "editor.inactive.selection": theme.palette.grey["100"],
            "editor.indent.guides": theme.palette.grey["200"],
            "editor.active.indent.guides": theme.palette.grey["400"],
            "editor.selection.highlight": "#0dc7ff4f",
        }
    }
}
