import React from "react";
import {CardContent, CardProps, SxProps, Theme} from "@mui/material";
import {FlatCard} from "../Misc";
import {useTheme} from "@mui/material/styles";
import {ZRFontWeight} from "echarts/types/src/util/types";
import {ChartDataColor, mapColorZr} from "./raw/Charts";
import HorizontalBarChart from "./raw/HorizontalBarChart";
import {BarSeriesOption} from "echarts/charts";


export interface Data {
    name: string
    value: number
    color?: ChartDataColor
    unit: string
}

export interface Props {
    id: string
    header?: string
    subHeader?: string
    data: Data[]
    dataTotal: Data
    unit: string
    width?: string
    height?: string
    showWrapper?: boolean
    wrapperStyle?: SxProps<Theme>
}

export default function (props: Props) {
    const theme = useTheme()
    const chart = (<HorizontalBarChart id={props.id}
                                       title={{
                                           show: !!props.header,
                                           text: props.header,
                                           textStyle: {
                                               color: theme.palette.text.secondary,
                                               textBorderColor: theme.palette.background.paper,
                                               textBorderWidth: 2,
                                               lineHeight: 14,
                                               fontSize: "14px",
                                               fontWeight: theme.typography.h5.fontWeight! as ZRFontWeight,
                                           },
                                           subtext: props.subHeader,
                                           subtextStyle: {
                                               textBorderColor: theme.palette.background.paper,
                                               textBorderWidth: 2,
                                           },
                                           padding: 0,
                                       }}
                                       xAxisName={props.unit}
                                       grid={{
                                           show: false,
                                           left: 50,
                                           top: 20,
                                           bottom: 20,
                                           right: 50,
                                           containLabel: true,
                                       }}
                                       series={props.data.map(d => {
                                           return {
                                               name: d.name,
                                               data: [d.value],
                                               color: mapColorZr(d.color, theme),
                                               emphasis: {disabled: true},
                                               label: {
                                                   show: !!d.value,
                                                   position: "insideLeft",
                                                   fontWeight: "bold",
                                                   formatter: `{a}: {c}${d.unit} (${Math.round(d.value / props.dataTotal.value * 100)} %)`,
                                               },
                                           } as BarSeriesOption
                                       }).concat({
                                           name: props.dataTotal.name,
                                           data: [props.dataTotal.value],
                                           color: mapColorZr(props.dataTotal.color, theme) || "#ddd",
                                           emphasis: {disabled: true},
                                           label: {
                                               show: !!props.dataTotal.value,
                                               position: "insideLeft",
                                               fontWeight: "bold",
                                               formatter: `{a}: {c}${props.dataTotal.unit} (100 %)`
                                           },
                                           markLine: {
                                               symbol: ["none", "pin"],
                                               label: {formatter: "100 %"},
                                               data: [{xAxis: props.dataTotal.value}],
                                               emphasis: {disabled: true},
                                           },
                                       })}
                                       width={props.width || "100%"}
                                       height={props.height || "200px"}/>)
    return props.showWrapper === undefined || props.showWrapper ? (<FlatCard variant="outlined" sx={props.wrapperStyle}>
        <CardContent>
            {chart}
        </CardContent>
    </FlatCard>) : chart
}