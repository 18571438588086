import React, {useContext} from "react";
import {Button, CardActions, CardContent, Grid, Typography} from "@mui/material";
import AuthenticatedLayout from "../../components/layout/AuthenticatedLayout";
import {FileDownloadSharp, OpenInNewSharp} from "@mui/icons-material";
import {FlatCard} from "../Misc";
import {RouteComponentProps} from "@reach/router";
import {UiConfigContext} from "../provider/UiConfigProvider";

export interface Props extends RouteComponentProps {
    title: string
}

export default function (props: Props) {
    const {uiConfig} = useContext(UiConfigContext)

    return (<AuthenticatedLayout title={props.title}
                                 breadcrumbs={[{name: "Overview", link: "/app"}, {
                                     name: props.title,
                                     link: props.uri || ""
                                 }]}>
        <Grid container spacing={3} sx={{marginTop: "0"}}>
            <Grid item xs={12} sm={6}>
                <FlatCard id="docs-service-creation" variant="outlined">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Service creation
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            What has to be done to create a service in AIOS. A step-by-step guid to the create the most
                            simple service.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" color="primary" startIcon={<OpenInNewSharp/>}
                                href={`${uiConfig?.rootUrl}/docs/html/service-creation.html`}
                                target="_blank">Open</Button>
                        <Button color="primary" startIcon={<FileDownloadSharp/>}
                                href={`${uiConfig?.rootUrl}/docs/pdf/service-creation.pdf`}
                                target="_blank">Download</Button>
                    </CardActions>
                </FlatCard>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FlatCard id="docs-api" variant="outlined">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            HTTP API
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            The HTTP API documentation provided and rendered in the Swagger UI.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" color="primary" startIcon={<OpenInNewSharp/>} href="/app/docs/api"
                                target="_blank">Open</Button>
                        <Button color="primary" startIcon={<FileDownloadSharp/>}
                                href={`${uiConfig?.rootUrl}/docs/pdf/api-http.pdf`}
                                target="_blank">Download</Button>
                    </CardActions>
                </FlatCard>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FlatCard id="docs-installation" variant="outlined">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Installation
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Installation guide and considerations on how to operate AIOS on self provided
                            infrastructure.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" color="primary" startIcon={<OpenInNewSharp/>}
                                href={`${uiConfig?.rootUrl}/docs/html/deployment.html`} target="_blank">Open</Button>
                        <Button color="primary" startIcon={<FileDownloadSharp/>}
                                href={`${uiConfig?.rootUrl}/docs/pdf/deployment.pdf`} target="_blank">Download</Button>
                    </CardActions>
                </FlatCard>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FlatCard id="docs-migration" variant="outlined">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Migration
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Manual steps to perform when upgrading to specific versions.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" color="primary" startIcon={<OpenInNewSharp/>}
                                href={`${uiConfig?.rootUrl}/docs/html/migration.html`} target="_blank">Open</Button>
                        <Button color="primary" startIcon={<FileDownloadSharp/>}
                                href={`${uiConfig?.rootUrl}/docs/pdf/migration.pdf`}
                                target="_blank">Download</Button>
                    </CardActions>
                </FlatCard>
            </Grid>
        </Grid>
    </AuthenticatedLayout>)
}
