import React from "react";
import {ServiceResponse} from "../../../generated/models/ServiceResponse";
import {serviceSchema, serviceSpecificationSchema} from "./ServiceModel";
import SimpleDialog from "../../dialog/SimpleDialog";
import DiffEditor from "../../DiffEditor";
import {CircularProgress} from "@mui/material";

export interface Props {
    id: string
    title: string
    open: boolean
    onClose?: () => void
    original: ServiceResponse | null
    modified: ServiceResponse | null
}

export default function (props: Props) {
    const onClose: () => void = () => {
        if (props.onClose) props.onClose()
    }

    return (<SimpleDialog id={props.id} title={props.title} open={props.open} onClose={onClose} fullScreen={true}>
        {props.original === null || props.modified === null ? (<CircularProgress/>) : (
                <DiffEditor id={`${props.id}-diff-editor`}
                            name="service"
                            width="100%" height="80vh"
                            language="json"
                            schemas={[serviceSchema, serviceSpecificationSchema]}
                            labelOriginal={`Version ${props.original?.version}`}
                            valueOriginal={JSON.stringify(props.original, null, 2)}
                            labelModified={`Version ${props.modified?.version}`}
                            valueModified={JSON.stringify(props.modified, null, 2)}
                />)}
    </SimpleDialog>)
}
