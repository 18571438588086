import {HelpOutlineSharp} from "@mui/icons-material";
import {
    Breakpoint,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React, {useState} from "react";

export interface Props {
    id: string
    title?: string
    buttonText?: string
    open?: boolean
    fullScreen?: boolean
    maxSize?: Breakpoint
    children: NonNullable<React.ReactNode>
}

export default function (props: Props) {
    const theme = useTheme()
    const [open, setOpen] = useState<boolean>(props.open || false)

    const button = (<IconButton tabIndex={-1} onClick={() => setOpen(true)}><HelpOutlineSharp/></IconButton>)
    return (<>
        {props.title ? (<Tooltip title={props.title} placement="bottom">
            {button}
        </Tooltip>) : button}
        <Dialog id={`${props.id}-dialog`} open={open} onClose={() => setOpen(false)}
                fullScreen={props.fullScreen === undefined ? useMediaQuery(theme.breakpoints.down("sm")) : props.fullScreen}
                maxWidth={props.maxSize || false}
                aria-labelledby={`${props.id}-title`} transitionDuration={0}>
            {props.title ? (<DialogTitle id={`${props.id}-title`}>{props.title}</DialogTitle>) : null}
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button id={`${props.id}-ok`} color="primary" variant="contained"
                        onClick={() => setOpen(false)}>{props.buttonText ? props.buttonText : "OK"}</Button>
            </DialogActions>
        </Dialog>
    </>)
}
