import React, {useContext, useEffect, useState} from "react";
import {LoginContext} from "../../provider/LoginProvider";
import {ServiceResponse} from "../../../generated/models/ServiceResponse";
import {serviceAdapter} from "../../../adapters/interfaces";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import {TenantContext} from "../../provider/TenantProvider";
import {UiConfigContext} from "../../provider/UiConfigProvider";
import {RouteComponentProps} from "@reach/router";
import {ServiceEndpointType} from "../../../generated/models/ServiceEndpointType";
import {TITLE_SUFFIX} from "../../layout/GeneralLayout";
import {isLoggedIn} from "../../../misc/authentication";
import {navigate} from "gatsby";

export interface Props extends RouteComponentProps {
    serviceId?: string
}

export default function (props: Props) {
    const {uiConfig} = useContext(UiConfigContext)
    const {login} = useContext(LoginContext)
    const {tenant} = useContext(TenantContext)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [element, setElement] = useState<ServiceResponse | null>(null)
    const [openApiUrl, setOpenApiUrl] = useState<string | null>(null)
    const [serviceApiUrls, setServiceApiUrls] = useState<string[]>([])

    const load = (): void => {
        if (!props.serviceId) return
        setLoading(true)
        serviceAdapter.get(login, props.serviceId)
                .then(response => {
                    setElement(response)
                })
                .catch(error => setError(error.message))
                .finally(() => {
                    setLoading(false)
                })
    }

    useEffect(() => {
        document.title = `HTTP API${TITLE_SUFFIX}`

        if (login && isLoggedIn(login)) {
            console.debug("Loading element")
            load()
        } else {
            if (!location.pathname.includes('/app/login')) {
                let redirect = encodeURI(location.href)
                console.debug(`User is not logged in; forwarding to login page with redirect ${redirect}`)
                // noinspection JSIgnoredPromiseFromCall
                navigate(`/app/login?url=${redirect}`)
                return
            }
        }
    }, [])
    useEffect(() => {
        if (element && tenant && uiConfig) {
            document.title = `HTTP API - ${element.name}${TITLE_SUFFIX}`

            const openApiEndpoint = element.specification.endpoints.find(it => it.type === ServiceEndpointType.OpenApi)
            if (openApiEndpoint) {
                if (openApiEndpoint.path?.includes("://")) {
                    setOpenApiUrl(openApiEndpoint.path!!)
                } else {
                    const globalPrefix = openApiEndpoint.isExposed ? `/${tenant?.tenant.alias}/${element.alias}` : ``
                    const internalPath = `${openApiEndpoint.pathPrefix || ""}${openApiEndpoint.path || ""}`
                    setOpenApiUrl(`${uiConfig?.rootUrl}${globalPrefix}${internalPath}`)
                }
            }

            const apiEndpoints = element.specification.endpoints.filter(it => it.type === ServiceEndpointType.Api)
            setServiceApiUrls(apiEndpoints.map(endpoint => {
                const globalPrefix = endpoint.isExposed ? `/${tenant?.tenant.alias}/${element.alias}` : ``
                const internalPath = `${endpoint.pathPrefix || ""}${endpoint.path || ""}`
                if (element.specification.reachability.domain) {
                    const protocol = element.specification.reachability.domainUseTls ? "https" : "http"
                    return `${protocol}://${element.specification.reachability.domain}${internalPath}`
                }
                return `${uiConfig?.rootUrl}${globalPrefix}${internalPath}`
            }))
        }
    }, [element, tenant, uiConfig])

    return (<div>
        {error ? error : (<SwaggerUI url={openApiUrl || undefined} persistAuthorization={true} plugins={[{
            statePlugins: {
                spec: {
                    wrapActions: {
                        updateJsonSpec: (oriAction: any, system: any) => (spec: any) => {
                            spec.servers = serviceApiUrls.map(url => ({url}))
                            return oriAction(spec)
                        }
                    }
                }
            }
        }]}/>)}
    </div>)
}