import {Badge, Skeleton, Tooltip} from "@mui/material";
import React, {useEffect, useState} from "react";
import {LoginHolder} from "./provider/LoginProvider";
import {TagAdapter} from "../adapters/TagAdapter";
import {Page, pageable} from "../adapters/interfaces";
import {ServiceAdapter} from "../adapters/ServiceAdapter";
import {TagResponse} from "../generated/models/TagResponse";
import {SellSharp} from "@mui/icons-material";

export interface Props {
    serviceId: string
    login: LoginHolder | null
    serviceAdapter: ServiceAdapter
    tagAdapter: TagAdapter
}

/**
 * Loads and displays user information for human representation based on a user id.
 */
export default function (props: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [count, setCounts] = useState<number | null>(null)
    const [tags, setTags] = useState<Page<TagResponse> | null>(null)

    useEffect(() => {
        setIsLoading(true)
        props.serviceAdapter.findTags(props.login, pageable(0, 1000, `serviceId=="${props.serviceId}"`, null))
                .then(response => {
                    setCounts(response.totalElements || 0)
                    if (response.totalElements > 0) {
                        return props.tagAdapter.find(props.login, pageable(0, 5, `id=in=(${response.elements.map(tagService => tagService.tagId).join(",")})`, decodeURIComponent("+name")))
                    }
                    return Promise.resolve(null)
                })
                .then(response => {
                    setTags(response)
                })
                .finally(() => setIsLoading(false))
    }, [])

    if (isLoading) return (<Skeleton sx={{width: "100%"}}/>)
    if (tags === null) {
        if (count === 0) return (<Tooltip title="No tags">
            <Badge badgeContent={count} color="default"><SellSharp color="disabled"/></Badge>
        </Tooltip>)
        else return (<Tooltip title={count == 1 ? `${count} tag` : `${count} tags`}>
            <Badge badgeContent={count} color="primary"><SellSharp/></Badge>
        </Tooltip>)
    } else {
        return (<Tooltip title={tags.elements.map(tag => tag.name).join(", ")}>
            <Badge badgeContent={count} color="primary"><SellSharp/></Badge>
        </Tooltip>)
    }
}
